import * as React from 'react'

import { useMutation } from '@apollo/client'
import {
  Button,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Currency,
  Dialog,
  ErrorDisplay,
} from 'shared/components'
import { CurrencyField, SelectField } from 'shared/forms'
import { isValidAssetAmount, setFormError } from 'shared/services'

import {
  BULK_PURCHASES_CONCILIATION_QUERY,
  SUPPLIER_LABELS,
  UPDATE_BULK_PURCHASE_MUTATION,
} from '../queries/bulk_purchases'
import { getProfitColor } from '../services/conciliation'
import { translateGuitaError } from '../services/error_messages'

import type {
  SupplierBulkPurchase,
  UpdateBulkPurchaseData,
  UpdateBulkPurchaseVars,
} from '../queries/bulk_purchases'
import type { FormikProps } from 'formik'

type FormValues = {
  supplier: string
  supplierPrice: number
}

const initialValues: FormValues = ({
  supplier: '',
  supplierPrice: 0,
})

const validationSchema: Yup.SchemaOf<FormValues> = (
  Yup.object().shape({
    supplier: Yup.string()
      .required('Este campo es obligatorio'),
    supplierPrice: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .test(
        'validFormat',
        'Introduce un número con máximo 6 decimales',
        (value) => isValidAssetAmount(value),
      ),
  })
)

export const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  setFieldValue,
  submitForm,
  values,
  bulkPurchase,
}: FormikProps<FormValues> & { bulkPurchase: SupplierBulkPurchase }) => {
  const priceDiference =
    values.supplierPrice ? bulkPurchase.purchasePrice - values.supplierPrice : 0

  return (
    <Form>
      <Stack spacing={3}>
        <Typography>
          Orden {bulkPurchase.id} / Usuario {bulkPurchase.userId} / Monto
          {' '}
          <Currency
            currency='CLP'
            digits={0}
            value={bulkPurchase.inAmount}
          />
        </Typography>
        <SelectField
          name='supplier'
          label='Proveedor'
          onChange={() => setFieldValue('supplierPrice', 0)}
        >
          {Object.entries(SUPPLIER_LABELS).map(([supplierType, supplierLabel]) => (
            <MenuItem
              key={supplierType}
              value={supplierType}
            >
              {supplierLabel}
            </MenuItem>
          ))}
        </SelectField>
        <CurrencyField
          name='supplierPrice'
          label='Precio de compra USDT'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>CLP</small>&nbsp;$
              </InputAdornment>
            ),
          }}
          digits={2}
          positive
        />
        <Typography
          mt={2}
          textAlign='right'
        >
          Precio de venta:
          {' '}
          <Currency
            currency='CLP/USDT'
            digits={2}
            value={bulkPurchase.purchasePrice}
          />
        </Typography>
        <Typography
          textAlign='right'
          color={getProfitColor(priceDiference)}
        >
          Diferencia:
          {' '}
          <Currency
            currency='CLP/USDT'
            digits={2}
            value={priceDiference}
          />
        </Typography>
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={12}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            color='error'
          >
            Confirmar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type BulkPurchaseUpdaterProps = {
  bulkPurchase: SupplierBulkPurchase
  dialogOpen: boolean
  closeDialog: () => void
}

const BulkPurchaseUpdater = ({
  bulkPurchase,
  dialogOpen,
  closeDialog,
}: BulkPurchaseUpdaterProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [updateBulkPurchase] =
    useMutation<UpdateBulkPurchaseData, UpdateBulkPurchaseVars>(
      UPDATE_BULK_PURCHASE_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          BULK_PURCHASES_CONCILIATION_QUERY,
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateBulkPurchase({
      variables: {
        bulkPurchaseId: bulkPurchase.id,
        ...values,
      },
    })

    if (response.data?.updateBulkPurchase === 'OK!') {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => closeDialog()}
      title='Modificar orden de compra'
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            bulkPurchase={bulkPurchase}
          />
        )}
      </Formik>
    </Dialog>
  )
}

export default BulkPurchaseUpdater
