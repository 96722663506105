import { gql } from '@apollo/client'

import { USER_NOTIFICATION_FIELDS } from 'shared/queries'

import type { PaginableQuery, PaginableVars } from './pagination_helpers'
import type { UserNotification as BaseUserNotification } from 'shared/queries'

export type UserNotification = BaseUserNotification & {
  userId: string
}

export type UserNotificationsVars = PaginableVars & {
  filter: string
  state: string
}

export type UserNotificationsData = {
  userNotifications: PaginableQuery<UserNotification>
}

export const USER_NOTIFICATIONS_QUERY = gql`
  ${USER_NOTIFICATION_FIELDS}
  query UserNotifications(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $filter: String
    $state: String
  ) {
    userNotifications(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
      state: $state
    ) {
      edges {
        cursor
        node {
          ...UserNotificationFields
          userId
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
`

export type ResolveUserNotificationVars = {
  userId: string
  userNotificationId: string
}

export type ResolveUserNotificationData = {
  resolveUserNotification: string
}

export const RESOLVE_USER_NOTIFICATION_MUTATION = gql`
  mutation ResolveUserNotification(
    $userId: ID!
    $userNotificationId: ID!
  ) {
    resolveUserNotification(
      input: {
        userId: $userId
        userNotificationId: $userNotificationId
      }
    )
  }
`
