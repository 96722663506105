import { gql } from '@apollo/client'

import type { PaginableQuery, PaginableVars } from './pagination_helpers'

export type ValueType = 'string' | 'number' | 'boolean' | 'json' | 'date'

export const VALUE_TYPE_LABELS: { [key in ValueType]: string } = {
  'string': 'Texto',
  'number': 'Número',
  'boolean': 'Booleano',
  'json': 'JSON',
  'date': 'Fecha y hora',
}

export type DynamicConfig = {
  id: string
  name: string
  value: string
  valueType: ValueType
  createdAt: string
  updatedAt: string
}

const DYNAMIC_CONFIG_DATA_FIELDS = gql`
  fragment DynamicConfigFields on DynamicConfig {
    id
    name
    value
    valueType
    createdAt
    updatedAt
  }
`

export type DynamicConfigsVars = PaginableVars

export type DynamicConfigsData = {
  dynamicConfigs: PaginableQuery<DynamicConfig>
}

export const DYNAMIC_CONFIGS_QUERY = gql`
  ${DYNAMIC_CONFIG_DATA_FIELDS}
  query DynamicConfigs(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    dynamicConfigs(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      edges {
        cursor
        node {
          ...DynamicConfigFields
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
`

export type UserDynamicConfig = {
  id: string
  user: {
    id: string
    email: string
  }
  value: string
  createdAt: string
  updatedAt: string
}

const USER_DYNAMIC_CONFIG_DATA_FIELDS = gql`
  fragment UserDynamicConfigFields on UserDynamicConfig {
    id
    user {
      id
      email
    }
    value
    createdAt
    updatedAt
  }
`

export type DynamicConfigVars = {
  configId: string
}

export type DynamicConfigData = {
  dynamicConfig: DynamicConfig & {
    userDynamicConfigs: UserDynamicConfig[]
  }
}

export const DYNAMIC_CONFIG_QUERY = gql`
  ${DYNAMIC_CONFIG_DATA_FIELDS}
  ${USER_DYNAMIC_CONFIG_DATA_FIELDS}
  query DynamicConfigQuery(
    $configId: ID!
  ) {
    dynamicConfig(
      configId: $configId
    ) {
      ...DynamicConfigFields
      userDynamicConfigs {
        ...UserDynamicConfigFields
      }
    }
  }
`

export type CreateDynamicConfigVars = {
  name: string
  value: string
  valueType: ValueType
}

export type CreateDynamicConfigData = {
  createDynamicConfig: string
}

export const CREATE_DYNAMIC_CONFIG_MUTATION = gql`
  mutation CreateDynamicConfig(
    $name: String!
    $value: String!
    $valueType: String!
  ) {
    createDynamicConfig(
      input: {
        name: $name
        value: $value
        valueType: $valueType
      }
    )
  }
`

export type UpdateDynamicConfigVars = {
  configId: string
  value: string
}

export type UpdateDynamicConfigData = {
  updateDynamicConfig: string
}

export const UPDATE_DYNAMIC_CONFIG_MUTATION = gql`
  mutation UpdateDynamicConfig(
    $configId: ID!
    $value: String!
  ) {
    updateDynamicConfig(
      input: {
        configId: $configId
        value: $value
      }
    )
  }
`

export type DeleteDynamicConfigVars = {
  configId: string
}

export type DeleteDynamicConfigData = {
  deleteDynamicConfig: string
}

export const DELETE_DYNAMIC_CONFIG_MUTATION = gql`
  mutation DeleteDynamicConfig(
    $configId: ID!
  ) {
    deleteDynamicConfig(
      input: {
        configId: $configId
      }
    )
  }
`

export type CreateUserDynamicConfigVars = {
  configId: string
  userId: string
  value: string
}

export type CreateUserDynamicConfigData = {
  createUserDynamicConfig: string
}

export const CREATE_USER_DYNAMIC_CONFIG_MUTATION = gql`
  mutation CreateUserDynamicConfig(
    $configId: ID!
    $userId: ID!
    $value: String!
  ) {
    createUserDynamicConfig(
      input: {
        configId: $configId
        userId: $userId
        value: $value
      }
    )
  }
`

export type UpdateUserDynamicConfigVars = {
  configId: string
  userId: string
  value: string
}

export type UpdateUserDynamicConfigData = {
  updateUserDynamicConfig: string
}

export const UPDATE_USER_DYNAMIC_CONFIG_MUTATION = gql`
  mutation UpdateUserDynamicConfig(
    $configId: ID!
    $userId: ID!
    $value: String!
  ) {
    updateUserDynamicConfig(
      input: {
        configId: $configId
        userId: $userId
        value: $value
      }
    )
  }
`

export type DeleteUserDynamicConfigVars = {
  configId: string
  userId: string
}

export type DeleteUserDynamicConfigData = {
  deleteUserDynamicConfig: string
}

export const DELETE_USER_DYNAMIC_CONFIG_MUTATION = gql`
  mutation DeleteUserDynamicConfig(
    $configId: ID!
    $userId: ID!
  ) {
    deleteUserDynamicConfig(
      input: {
        configId: $configId
        userId: $userId
      }
    )
  }
`
