import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'

import { ButtonContainer, ButtonsContainer, ErrorDisplay } from 'shared/components'
import { setFormError } from 'shared/services'

import {
  FundOperationFields,
  getFundOperationInitialValues as getInitialValues,
  getFundOperationMutationVariables as getMutationVariables,
  fundOperationValidationSchema as validationSchema,
} from './fund_operation_form'
import { CREATE_FUND_WITHDRAWAL_MUTATION } from '../../../queries/fund_operations'
import { FUND_QUERY } from '../../../queries/funds'
import { translateGuitaError } from '../../../services/error_messages'

import type { FundOperationFormValues as FormValues } from './fund_operation_form'
import type {
  CreateFundWithdrawalData,
  CreateFundWithdrawalVars,
} from '../../../queries/fund_operations'
import type { FundDetails } from '../../../queries/funds'
import type { FormikProps } from 'formik'

type InnerFormProps = FormikProps<FormValues> & {
  fund: FundDetails
  cancelCreate: () => void
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  fund,
  cancelCreate,
}: InnerFormProps) => (
  <Form>
    <FundOperationFields
      fund={fund}
      positive
    />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={cancelCreate}
          variant='outlined'
          color='secondary'
        >
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          Crear
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type FundWithdrawalCreatorProps = {
  cancelCreate: () => void
  closeDialog: () => void
  fund: FundDetails
}

export const FundWithdrawalCreator = ({
  cancelCreate,
  closeDialog,
  fund,
}: FundWithdrawalCreatorProps) => {
  const fundId = fund.id
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [createFundWithdrawal] =
    useMutation<CreateFundWithdrawalData, CreateFundWithdrawalVars>(
      CREATE_FUND_WITHDRAWAL_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          { query: FUND_QUERY, variables: { fundId } },
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    const variables = getMutationVariables(fund, values)
    const response = await createFundWithdrawal({ variables })

    if (response.data?.createFundWithdrawal === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(fund.currentAssets)}
        validationSchema={validationSchema(true)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            fund={fund}
            cancelCreate={cancelCreate}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}
