import { gql } from '@apollo/client'

import {
  USER_DOCUMENT_FIELDS,
  USER_DOCUMENT_VIEW_FIELDS,
} from 'shared/queries'

import type {
  UserDocumentType as BaseUserDocumentType,
  UserDocumentView as BaseUserDocumentView,
  UserDocument,
} from 'shared/queries'

export type UserDocumentType = BaseUserDocumentType | 'ADMINISTRATIVE' | 'UBO'

export const USER_DOCUMENT_TYPE_LABELS: { [key in UserDocumentType]: string } = {
  'LEGAL': 'Documento legal (público)',
  'OPERATIONAL': 'Documento operacional (público)',
  'ADMINISTRATIVE': 'Documento administrativo (privado)',
  'UBO': 'Declaracion UAF de beneficiarios finales (privado)',
}

export type UserDocumentView = Omit<BaseUserDocumentView, 'documentType'> & {
  documentType: UserDocumentType
}

export type UserDocumentsVars = {
  userId: string
}

export type UserDocumentsData = {
  userDocuments: UserDocumentView[]
}

export const USER_DOCUMENTS_QUERY = gql`
  ${USER_DOCUMENT_VIEW_FIELDS}
  query UserDocuments(
    $userId: ID!
  ) {
    userDocuments(
      userId: $userId
    ) {
      ...UserDocumentViewFields
    }
  }
`

export type CreateUserDocumentVars = {
  contentType: string
  description: string
  documentType: string
  timestamp: string
  userId: string
  notifyUser: boolean
}

export type CreateUserDocumentData = {
  createUserDocument: UserDocument
}

export const CREATE_USER_DOCUMENT_MUTATION = gql`
  ${USER_DOCUMENT_FIELDS}
  mutation CreateUserDocument(
    $contentType: String!
    $description: String!
    $documentType: String!
    $notifyUser: Boolean!
    $timestamp: ISO8601DateTime!
    $userId: ID!
  ) {
    createUserDocument(
      input: {
        contentType: $contentType
        description: $description
        documentType: $documentType
        notifyUser: $notifyUser
        timestamp: $timestamp
        userId: $userId
      }
    ) {
      ...UserDocumentFields
    }
  }
`

export type UpdateUserDocumentVars = {
  userId: string
  userDocumentId: string
}

export type UpdateUserDocumentData = {
  updateUserDocument: string
}

export const UPDATE_USER_DOCUMENT_MUTATION = gql`
  mutation UpdateUserDocument(
    $userId: ID!
    $userDocumentId: ID!
  ) {
    updateUserDocument(
      input: {
        userId: $userId
        userDocumentId: $userDocumentId
      }
    )
  }
`

export type DeleteUserDocumentVars = {
  userId: string
  userDocumentId: string
}

export type DeleteUserDocumentData = {
  deleteUserDocument: string
}

export const DELETE_USER_DOCUMENT_MUTATION = gql`
  mutation DeleteUserDocument(
    $userId: ID!
    $userDocumentId: ID!
  ) {
    deleteUserDocument(
      input: {
        userId: $userId
        userDocumentId: $userDocumentId
      }
    )
  }
`
