import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'

import { ButtonContainer, ButtonsContainer, ErrorDisplay } from 'shared/components'
import {
  FundsOriginFields,
  getFundsOriginInitialValues as getInitialValues,
  fundsOriginValidationSchema as validationSchema,
} from 'shared/forms'
import { setFormError } from 'shared/services'

import { UPDATE_FUNDS_ORIGIN_MUTATION } from '../../../../queries/funds_origins'
import { USER_QUERY } from '../../../../queries/users'
import { translateGuitaError } from '../../../../services/error_messages'

import type {
  UpdateFundsOriginData,
  UpdateFundsOriginVars,
} from '../../../../queries/funds_origins'
import type { FundsOriginFormValues as FormValues } from 'shared/forms'
import type { FundsOrigin } from 'shared/queries'

type InnerFormProps = FormikProps<FormValues> & {
  isBusiness: boolean
  isPresent: boolean
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  isBusiness,
  isPresent,
}: InnerFormProps) => (
  <Form>
    <FundsOriginFields isBusiness={isBusiness} />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          {isPresent ? 'Modificar' : 'Crear'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type FundsOriginEditFormProps = {
  closeDialog: () => void
  userId: string
  isBusiness: boolean
  fundsOrigin?: FundsOrigin
}

const FundsOriginEditForm = ({
  closeDialog,
  userId,
  isBusiness,
  fundsOrigin,
}: FundsOriginEditFormProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [updateFundsOrigin] =
    useMutation<UpdateFundsOriginData, UpdateFundsOriginVars>(UPDATE_FUNDS_ORIGIN_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_QUERY, variables: { userId } },
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateFundsOrigin({
      variables: {
        userId,
        ...values,
      },
    })

    if (response.data?.updateFundsOrigin === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={getInitialValues(fundsOrigin)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <InnerForm
          {...props}
          isBusiness={isBusiness}
          isPresent={!!fundsOrigin}
        />
      )}
    </Formik>
  )
}

export default FundsOriginEditForm
