import * as React from 'react'

import { Box } from '@mui/material'

const AntiFomoText = () => (
  <Box
    display='flex'
    alignItems='center'
    justifyContent='center'
    height='100%'
  >
    No hay operaciones.
  </Box>
)

export default AntiFomoText
