import * as React from 'react'

import {
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'

import { AppContainer, Table, TableLoading } from 'shared/components'

import TableEmpty from '../components/table_empty'

import type { PaginableQuery } from '../queries/pagination_helpers'

type PaginatedTableProps<TNode> = {
  data: PaginableQuery<TNode> | undefined
  loading: boolean
  fetchMore: (options: { variables?: { first?: number; after?: string } }) => Promise<unknown>
  rowMapper: (node: TNode, index: number) => React.ReactNode
  headRow: React.ReactNode[]
  initialRowsPerPage?: number
  rowsPerPageOptions?: number[]
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const PaginatedTable = <TNode, >({
  data,
  loading,
  fetchMore,
  rowMapper,
  headRow,
  initialRowsPerPage = 25,
  rowsPerPageOptions = [25, 50, 100],
}: PaginatedTableProps<TNode>) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage)
  const [page, setPage] = React.useState(0)

  const pageInfo = data?.pageInfo
  const totalCount = data?.totalCount || -1
  const totalCachedCount = data?.edges.length || 0
  const edges = (data?.edges || [])
    .map((edge) => edge.node)
    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const expectedLength = Math.min(totalCount, (newPage + 1) * rowsPerPage)

    if (pageInfo?.hasNextPage && expectedLength > totalCachedCount) {
      await fetchMore({
        variables: {
          first: expectedLength - totalCachedCount,
          after: pageInfo.endCursor,
        },
      })
    }

    setPage(newPage)
  }

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10)

    if (pageInfo?.hasNextPage && newRowsPerPage > totalCachedCount) {
      await fetchMore({
        variables: {
          first: newRowsPerPage,
        },
      })
    }

    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  return (
    <TableContainer component={AppContainer}>
      <Table>
        <TableHead>
          <TableRow>
            {headRow}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableLoading
              rows={3}
              columns={headRow.length}
            />
          ) : (
            edges.length === 0 ? (
              <TableEmpty columns={headRow.length} />
            ) : (
              edges.map(rowMapper)
            )
          )}
          <TableRow>
            <TablePagination
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage='Filas por página:'
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`
              }}
              getItemAriaLabel={(type) => {
                switch (type) {
                case 'first': return 'Primera página'
                case 'previous': return 'Página anterior'
                case 'next': return 'Página siguiente'
                case 'last': return 'Última página'
                }
              }}
            />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PaginatedTable
