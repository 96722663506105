import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'

import { ButtonContainer, ButtonsContainer, ErrorDisplay } from 'shared/components'
import {
  OccupationProfileFields,
  getOccupationProfileInitialValues as getInitialValues,
  occupationProfileValidationSchema as validationSchema,
} from 'shared/forms'
import { setFormError } from 'shared/services'

import { UPDATE_OCCUPATION_PROFILE_MUTATION } from '../../../../queries/occupation_profiles'
import { USER_QUERY } from '../../../../queries/users'
import { translateGuitaError } from '../../../../services/error_messages'

import type {
  UpdateOccupationProfileData,
  UpdateOccupationProfileVars,
} from '../../../../queries/occupation_profiles'
import type { OccupationProfile } from 'shared/queries'

type FormValues = Omit<UpdateOccupationProfileVars, 'userId'>

type InnerFormProps = FormikProps<FormValues> & {
  isPresent: boolean
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  isPresent,
}: InnerFormProps) => (
  <Form>
    <OccupationProfileFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          {isPresent ? 'Modificar' : 'Crear'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type OccupationProfileEditFormProps = {
  closeDialog: () => void
  userId: string
  occupationProfile?: OccupationProfile
}

const OccupationProfileEditForm = ({
  closeDialog,
  userId,
  occupationProfile,
}: OccupationProfileEditFormProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [updateOccupationProfile] =
    useMutation<UpdateOccupationProfileData, UpdateOccupationProfileVars>(UPDATE_OCCUPATION_PROFILE_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_QUERY, variables: { userId } },
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateOccupationProfile({
      variables: {
        userId,
        ...values,
      },
    })

    if (response.data?.updateOccupationProfile === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={getInitialValues(occupationProfile)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <InnerForm
          {...props}
          isPresent={!!occupationProfile}
        />
      )}
    </Formik>
  )
}

export default OccupationProfileEditForm
