import * as React from 'react'

import { ListItem, ListItemButton, ListItemText } from '@mui/material'

import { LivenessProofPreview } from 'shared/components'

import ListDisplay from './list_display'

import type { LivenessDocumentType, LivenessProofView } from 'shared/queries'

const withType = (type: LivenessDocumentType) => (livenessProof: LivenessProofView) => (
  livenessProof.documentType === type
)

type LivenessProofsDisplayProps = {
  livenessProofs: LivenessProofView[]
}

const LivenessProofsDisplay = ({
  livenessProofs,
}: LivenessProofsDisplayProps) => {
  const livenessProof = livenessProofs.find(withType('LIVENESS'))
  const faceImageStorageUrl = livenessProofs.find(withType('FACE_IMAGE'))?.storageUrl

  return livenessProof && (
    <ListDisplay
      title='Reconocimiento facial'
      item={livenessProof}
    >
      {(item) => (
        <React.Fragment>
          <ListItem>
            {(item.storageUrl) ? (
              <LivenessProofPreview src={item.storageUrl} />
            ) : (
              <ListItemText
                primary='No se ha subido un video.'
                secondary='Es posible que se haya producido un error mientras se cargaba en la plataforma.'
              />
            )}
          </ListItem>
          {faceImageStorageUrl && (
            <ListItemButton
              component='a'
              target='_blank'
              href={faceImageStorageUrl}
            >
              <ListItemText
                primary={<React.Fragment>Descargar imagen estática</React.Fragment>}
                primaryTypographyProps={{ textAlign: 'center' }}
              />
            </ListItemButton>
          )}
        </React.Fragment>
      )}
    </ListDisplay>
  )
}

export default LivenessProofsDisplay
