import { gql } from '@apollo/client'

export type CreateDepositVars = {
  userId: string
  marketAssetId: string
  blockchain: string
  changedAmount: string
  timestamp: string
  address: string
  notifyUser: boolean
}

export type CreateDepositData = {
  createDeposit: string
}

export const CREATE_DEPOSIT_MUTATION = gql`
mutation CreateDeposit(
  $userId: ID!
  $marketAssetId: ID!
  $blockchain: String!
  $changedAmount: String!
  $timestamp: String!
  $address: String!
  $notifyUser: Boolean!
) {
  createDeposit(
    input: {
      userId: $userId
      marketAssetId: $marketAssetId
      blockchain: $blockchain
      changedAmount: $changedAmount
      timestamp: $timestamp
      address: $address
      notifyUser: $notifyUser
    }
  )
}
`
