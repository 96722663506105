import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Add } from '@mui/icons-material'
import { Button, Fab } from '@mui/material'
import { Field, Form, Formik, FormikProps } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay } from 'shared/components'
import { UserTypeFields } from 'shared/forms'
import { setFormError } from 'shared/services'

import { DOMAIN_QUERY } from '../queries/domains'
import { CREATE_USER_MUTATION, USERS_QUERY } from '../queries/users'
import { translateGuitaError } from '../services/error_messages'

import type { DomainData, DomainVars, OnboardingType } from '../queries/domains'
import type { CreateUserData, CreateUserVars } from '../queries/users'

type FormValues = Omit<CreateUserVars, 'isBusiness'> & {
  userType?: string
}

const initialValues: FormValues = ({
  userType: 'person',
  confirmEmail: false,
  email: '',
  password: '',
  passwordConfirmation: '',
  nickName: '',
  originName: '',
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    userType: Yup.string()
      .oneOf(['person', 'business'], 'Debes seleccionar una opción')
      .required('Debes seleccionar una opción'),
    confirmEmail: Yup.boolean()
      .required(),
    email: Yup.string()
      .email('Tu email es inválido')
      .max(64, 'Tu email es muy largo')
      .required('Este campo es obligatorio'),
    password: Yup.string()
      .min(6, 'Tu contraseña es muy corta')
      .max(128, 'Tu contraseña es muy larga'),
    passwordConfirmation: Yup.string()
      .test('passwords-match', 'Las contraseñas no coinciden', (value, context) => (
        context.parent.password === value
      )),
    nickName: Yup.string()
      .max(64, 'Tu nombre es muy largo')
      .required('Este campo es obligatorio'),
    originName: Yup.string(),
  })

type CreateUserFormProps = FormikProps<FormValues> & {
  closeDialog: () => void
  onboardingType?: OnboardingType
}

const CreateUserForm = ({
  closeDialog,
  isSubmitting,
  isValid,
  status,
  submitForm,
  onboardingType,
}: CreateUserFormProps) => (
  <Form autoComplete='off'>
    {(onboardingType === 'ADVANCED') && <UserTypeFields />}
    <Field
      required
      name='nickName'
      type='text'
      label='Nombre'
      placeholder='¿Cómo nos referimos al usuario?'
      inputProps={{ autoComplete: 'one-time-code' }}
      component={TextField}
      margin='normal'
      fullWidth
    />
    <Field
      required
      name='email'
      type='email'
      label='Email'
      placeholder='satoshi@guita.cl'
      inputProps={{ autoComplete: 'one-time-code' }}
      component={TextField}
      margin='normal'
      fullWidth
    />
    <Field
      name='password'
      type='password'
      label='Contraseña (opcional)'
      inputProps={{ autoComplete: 'one-time-code' }}
      component={TextField}
      margin='normal'
      fullWidth
    />
    <Field
      name='passwordConfirmation'
      type='password'
      label='Confirma la contraseña (opcional)'
      inputProps={{ autoComplete: 'one-time-code' }}
      component={TextField}
      margin='normal'
      fullWidth
    />
    <Field
      name='originName'
      type='text'
      label='Origen/Campaña de registro (opcional)'
      inputProps={{ autoComplete: 'one-time-code' }}
      placeholder=''
      component={TextField}
      margin='normal'
      fullWidth
    />
    <Field
      required
      component={CheckboxWithLabel}
      type='checkbox'
      name='confirmEmail'
      Label={{
        sx: { mt: 1 },
        label: 'Saltar confirmación de email',
      }}
    />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={closeDialog}
          variant='outlined'
          color='secondary'
        >
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          {isSubmitting ? 'Creando...' : 'Crear usuario'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

const UserCreator = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const { loading, data } = useQuery<DomainData, DomainVars>(DOMAIN_QUERY)

  const [createUser] =
    useMutation<CreateUserData, CreateUserVars>(CREATE_USER_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        USERS_QUERY,
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const variables = { isBusiness: values.userType === 'business', ...values }
    const response = await createUser({ variables })

    if (response.data?.createUser) {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  if (loading) {
    return
  }

  return (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Crear usuario'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Crear usuario'
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <CreateUserForm
              closeDialog={closeDialog}
              onboardingType={data?.domain.onboardingType}
              {...props}
            />
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  )
}

export default UserCreator
