import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Add } from '@mui/icons-material'
import { Button, Fab, MenuItem, Stack } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik, FormikProps } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay } from 'shared/components'
import { setFormError } from 'shared/services'

import DynamicConfigValueField from './dynamic_config_value_field'
import {
  CREATE_USER_DYNAMIC_CONFIG_MUTATION,
  DYNAMIC_CONFIG_QUERY,
  VALUE_TYPE_LABELS,
} from '../../../queries/dynamic_configs'
import { translateGuitaError } from '../../../services/error_messages'
import UserSelectField from '../../user_select_field'

import type {
  CreateUserDynamicConfigData,
  CreateUserDynamicConfigVars,
  DynamicConfig,
  ValueType,
} from '../../../queries/dynamic_configs'
import type { User } from '../../../queries/users'

type FormValues = {
  user: User | null
  name: string
  value: string
  valueType: ValueType
}

const initialValues = (dynamicConfig: DynamicConfig): FormValues => ({
  user: null,
  name: dynamicConfig.name,
  value: dynamicConfig.value,
  valueType: dynamicConfig.valueType,
})

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  user: Yup.object()
    .shape({
      id: Yup.string().required(),
      email: Yup.string().required(),
    })
    .required('Este campo es obligatorio'),
  name: Yup.string()
    .required('Este campo es obligatorio'),
  value: Yup.string()
    .required('Este campo es obligatorio'),
  valueType: Yup.mixed()
    .oneOf(Object.keys(VALUE_TYPE_LABELS))
    .required('Este campo es obligatorio'),
})

type CreateUserDynamicConfigProps = FormikProps<FormValues> & {
  closeDialog: () => void
  dynamicConfig: DynamicConfig
}

const CreateUserDynamicConfigForm = ({
  closeDialog,
  dynamicConfig,
  isSubmitting,
  isValid,
  status,
  submitForm,
}: CreateUserDynamicConfigProps) => (
  <Form>
    <Stack spacing={3}>
      <UserSelectField
        label='Usuario'
        name='user'
      />
      <Field
        required
        disabled
        name='name'
        type='string'
        label='Nombre'
        component={TextField}
        margin='normal'
        fullWidth
      />
      <Field
        required
        disabled
        name='valueType'
        type='text'
        label='Tipo'
        component={Select}
      >
        {Object.entries(VALUE_TYPE_LABELS).map(([valueType, valueLabel]) => (
          <MenuItem
            key={valueType}
            value={valueType}
          >
            {valueLabel}
          </MenuItem>
        ))}
      </Field>
      <DynamicConfigValueField valueType={dynamicConfig.valueType} />
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={closeDialog}
          variant='outlined'
          color='secondary'
        >
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          Crear
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type UserDynamicConfigCreatorProps = {
  dynamicConfig: DynamicConfig
}

export const UserDynamicConfigCreator = ({
  dynamicConfig,
}: UserDynamicConfigCreatorProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [createUserDynamicConfig] =
    useMutation<CreateUserDynamicConfigData, CreateUserDynamicConfigVars>(CREATE_USER_DYNAMIC_CONFIG_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: DYNAMIC_CONFIG_QUERY, variables: { configId: dynamicConfig.id } },
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    if (!values.user?.id) {
      return
    }

    const response = await createUserDynamicConfig({
      variables: {
        userId: values.user.id,
        configId: dynamicConfig.id,
        value: values.value,
      },
    })

    if (response.data?.createUserDynamicConfig) {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <Fab
        color='primary'
        variant='extended'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add sx={{ mr: 1 }} />
        Config. de usuario
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Agregar configuración de usuario'
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <Formik
            innerRef={formRef}
            initialValues={initialValues(dynamicConfig)}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <CreateUserDynamicConfigForm
                closeDialog={closeDialog}
                dynamicConfig={dynamicConfig}
                {...props}
              />
            )}
          </Formik>
        </LocalizationProvider>
      </Dialog>
    </React.Fragment>
  )
}

export default UserDynamicConfigCreator
