import * as React from 'react'

import { useQuery } from '@apollo/client'
import { AccountCircle, AdminPanelSettings, Warning } from '@mui/icons-material'
import {
  FormControl,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'

import {
  AppContainer,
  BackButton,
  CenterText,
  Currency,
  CurrencyProps,
  DatedList,
  DatedListItem,
  GridDivider,
  Loading,
  SeoHeaders,
} from 'shared/components'
import { BaseCurrencyField } from 'shared/forms'

import { BULK_PURCHASES_CONCILIATION_QUERY, SUPPLIER_LABELS } from '../../queries/bulk_purchases'
import {
  getProfitColor,
  getSupplierCreditAmount,
  getUnpaid,
  withSupplier,
} from '../../services/conciliation'
import ConciliationBalances from '../conciliation_balances'

import type {
  BulkPurchasesConciliationData,
  BulkPurchasesConciliationVars,
  Supplier,
  SupplierBulkPurchase,
} from '../../queries/bulk_purchases'
import type { RouteComponentProps } from '@reach/router'

type CreditAvailableBannerProps = CurrencyProps

const CreditAvailableBanner = ({
  currency,
  digits,
  value,
}: CreditAvailableBannerProps) => (
  <AppContainer
    sm={12}
    sx={{
      px: 2,
      py: 1.5,
      bgcolor: 'primary.main',
      color: 'primary.contrastText',
    }}
  >
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
      spacing={1}
    >
      <Warning fontSize='small' />
      <Typography
        textAlign='center'
        lineHeight={1}
      >
        Existe un excedente de pagos por
        {' '}
        <Currency
          currency={currency}
          digits={digits}
          value={value}
        />
      </Typography>
    </Stack>
  </AppContainer>
)

type CalcNextPaymentAmountPorps = {
  bulkPurchases: SupplierBulkPurchase[]
}

const CalcNextPaymentAmount = ({
  bulkPurchases,
}: CalcNextPaymentAmountPorps) => {
  const [paymentAmount, setPaymentAmount] = React.useState<number | undefined>(0)
  const [creditAmount, setCreditAmount] = React.useState(0)
  const [receiveAmount, setReceiveAmount] = React.useState(0)

  React.useEffect(() => {
    if (typeof paymentAmount === 'undefined') {
      setCreditAmount(0)
      setReceiveAmount(0)
      return
    }

    let available = paymentAmount
    let received = 0

    bulkPurchases.sort((a, b) => a.timestamp - b.timestamp).forEach((bulkPurchase) => {
      if (!bulkPurchase.supplierPrice) {
        return
      }

      const receiveAmount = Math.min(available, bulkPurchase.supplierAmountLeft)
      received += receiveAmount / bulkPurchase.supplierPrice
      available -= receiveAmount
    })

    setReceiveAmount(received)
    setCreditAmount(available)
  }, [bulkPurchases, paymentAmount])

  return (
    <React.Fragment>
      <AppContainer
        sm={12}
        md={6}
        sx={{
          p: 3,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography
          variant='h6'
          textAlign='center'
        >
          Simular pago
        </Typography>
        <FormControl fullWidth>
          <BaseCurrencyField
            label='Monto a transferir'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <small>CLP</small>&nbsp;$
                </InputAdornment>
              ),
            }}
            onChange={setPaymentAmount}
            value={paymentAmount}
            digits={0}
            positive
          />
        </FormControl>
      </AppContainer>
      <AppContainer
        sm={12}
        md={6}
        sx={{ p: 3, height: '100%' }}
      >
        <List>
          <ListItem
            disableGutters
            divider
          >
            <ListItemText primary='Total a recibir' />
            <ListItemText
              sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}
              primary={(
                <Currency
                  currency='USDT'
                  digits={2}
                  value={receiveAmount}
                />
              )}
            />
          </ListItem>
          <ListItem
            disableGutters
            divider
          >
            <ListItemText primary='Precio promedio' />
            <ListItemText
              sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}
              primary={(
                <Currency
                  currency='CLP/USDT'
                  digits={2}
                  value={
                    (typeof paymentAmount !== 'undefined' && receiveAmount !== 0)
                      ? ((paymentAmount - creditAmount) / receiveAmount)
                      : 0
                  }
                />
              )}
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary='Excedente' />
            <ListItemText
              sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}
              primary={(
                <Currency
                  currency='CLP'
                  digits={0}
                  value={creditAmount}
                />
              )}
            />
          </ListItem>
        </List>
      </AppContainer>
    </React.Fragment>
  )
}

const BulkPurchaseDisplay = ({ bulkPurchase }: { bulkPurchase: SupplierBulkPurchase }) => (
  <DatedListItem
    icon={bulkPurchase.vectorQuoteId ? <AccountCircle /> : <AdminPanelSettings />}
    text={(
      <React.Fragment>
        Orden {bulkPurchase.id}
        <br />
        <small>Usuario&nbsp;{bulkPurchase.userId}</small>
      </React.Fragment>
    )}
    timestamp={bulkPurchase.timestamp}
  >
    {bulkPurchase.vectorQuoteId && (
      <CenterText>
        API Quote ID: {bulkPurchase.vectorQuoteId}
      </CenterText>
    )}
    <ListItemText
      sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}
      primary={(
        <Currency
          currency='CLP'
          digits={0}
          value={bulkPurchase.supplierAmountLeft}
        />
      )}
      secondary={(
        <React.Fragment>
          {(bulkPurchase.supplierPrice) ? (
            <Currency
              currency='CLP/USDT'
              digits={2}
              value={bulkPurchase.supplierPrice}
            />
          ) : (
            'Sin precio de compra'
          )}
          <br />
          <Typography
            component='span'
            variant='body2'
            sx={{ color: getProfitColor(bulkPurchase.profit) }}
          >
            {'Utilidad: '}
            <Currency
              currency={bulkPurchase.profitCurrency}
              digits={bulkPurchase.profitCurrency === 'USDT' ? 2 : 0}
              value={bulkPurchase.profit}
            />
          </Typography>
        </React.Fragment>
      )}
    />
  </DatedListItem>
)

type UnpaidBulkPurchasesListProps = {
  bulkPurchases: SupplierBulkPurchase[]
}

const UnpaidBulkPurchasesList = ({
  bulkPurchases,
}: UnpaidBulkPurchasesListProps) => (
  <DatedList
    emptyListDisplay={(
      <ListItem>
        <ListItemText
          primary='No hay ordenes por pagar'
          primaryTypographyProps={{ color: 'text.secondary', textAlign: 'center' }}
        />
      </ListItem>
    )}
    items={bulkPurchases.map((bulkPurchase) => ({
      timestamp: bulkPurchase.timestamp * 1000,
      component: <BulkPurchaseDisplay bulkPurchase={bulkPurchase} />,
    }))}
  />
)

type ConciliationDetailsProps = RouteComponentProps & {
  supplier?: string
}

const ConciliationDetails = (props: ConciliationDetailsProps) => {
  const supplier = (props.supplier || '') as Supplier
  const supplierLabel = SUPPLIER_LABELS[supplier]

  const { loading, data } =
    useQuery<BulkPurchasesConciliationData, BulkPurchasesConciliationVars>(
      BULK_PURCHASES_CONCILIATION_QUERY,
    )

  const pastUnpaid = withSupplier((data?.bulkPurchasesConciliation.pastUnpaid || []), supplier)
  const todayAll = withSupplier((data?.bulkPurchasesConciliation.todayAll || []), supplier)
  const allUnpaid = [...pastUnpaid, ...getUnpaid(todayAll)]

  const unspentBankWithdrawals = data?.bulkPurchasesConciliation.unspentBankWithdrawals || []
  const creditAmount = getSupplierCreditAmount(unspentBankWithdrawals, supplier)

  return loading ? (
    <Loading />
  ) : (
    <Grid
      container
      spacing={3}
    >
      <SeoHeaders title={`Conciliación - ${supplierLabel}`} />
      <GridDivider>Conciliación - {supplierLabel}</GridDivider>
      <ConciliationBalances
        pastUnpaid={pastUnpaid}
        todayAll={todayAll}
      />
      <BackButton
        text='Volver a proveedores'
        containerProps={{ xs: 12 }}
      />
      {(creditAmount > 0) && (
        <CreditAvailableBanner
          currency='CLP'
          digits={0}
          value={creditAmount}
        />
      )}
      <CalcNextPaymentAmount bulkPurchases={allUnpaid} />
      <UnpaidBulkPurchasesList bulkPurchases={allUnpaid} />
    </Grid>
  )
}

export default ConciliationDetails
