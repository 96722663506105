import * as React from 'react'

import { useMutation } from '@apollo/client'
import { KeyboardDoubleArrowRight } from '@mui/icons-material'
import {
  Button,
  Fab,
  FormControl,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Currency,
  Dialog,
  ErrorDisplay,
} from 'shared/components'
import { CurrencyField, SelectField } from 'shared/forms'
import { BulkPurchase } from 'shared/queries'
import { setFormError } from 'shared/services'

import UserSelectField from './user_select_field'
import {
  BULK_PURCHASES_OVERVIEW_QUERY,
  TRANSFER_BULK_PURCHASE_MUTATION,
} from '../queries/bulk_purchases'
import { translateGuitaError } from '../services/error_messages'

import type { TransferBulkPurchaseData, TransferBulkPurchaseVars } from '../queries/bulk_purchases'
import type { User } from '../queries/users'
import type { FormikProps } from 'formik'

type FormValues = {
  bulkPurchaseId: string
  amount: number
  receiver: User | null
}

const initialValues: FormValues = ({
  bulkPurchaseId: '',
  amount: 0,
  receiver: null,
})

const validationSchema = (bulkPurchases: BulkPurchase[]): Yup.SchemaOf<FormValues> => (
  Yup.object().shape({
    bulkPurchaseId: Yup.string()
      .oneOf(bulkPurchases.map(({ id }) => id))
      .required('Este campo es obligatorio')
      .test(
        'validReceiver',
        'La orden de compra ya pertenece a este usuario',
        (value, context) => {
          const receiverId = context.parent.receiver?.id

          if (value === undefined || !receiverId) {
            return true
          }

          return value !== receiverId
        },
      ),
    amount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .integer('Debes introducir un monto sin decimales')
      .test(
        'validAmount',
        'Introduce un número menor o igual al monto disponible',
        (value, context) => {
          if (value === undefined) {
            return true
          }

          const bulkPurchaseId = context.parent.bulkPurchaseId
          const bulkPurchase = bulkPurchases.find(({ id }) => id === bulkPurchaseId)
          return !!bulkPurchase && value <= bulkPurchase.amountLeft
        },
      ),
    receiver: Yup.object()
      .shape({
        id: Yup.string().required(),
        email: Yup.string().required(),
      })
      .required('Este campo es obligatorio'),
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  bulkPurchases: BulkPurchase[]
}

export const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  setFieldValue,
  setFieldTouched,
  submitForm,
  values,
  bulkPurchases,
}: InnerFormProps) => {
  const bulkPurchase = bulkPurchases.find(({ id }) => id === values.bulkPurchaseId)

  const onBulkPurchaseChange = () => {
    setFieldValue('amount', 0)
    setFieldTouched('amount', false, false)
    setFieldValue('receiver', null)
    setFieldTouched('receiver', false, false)
  }

  return (
    <Form>
      <Stack spacing={3}>
        <FormControl fullWidth>
          <SelectField
            name='bulkPurchaseId'
            label='Orden de compra'
            onChange={onBulkPurchaseChange}
          >
            {bulkPurchases.sort((a, b) => b.timestamp - a.timestamp).map((bulkPurchase) => (
              <MenuItem
                key={bulkPurchase.id}
                value={bulkPurchase.id}
              >
                {new Date(bulkPurchase.timestamp).toLocaleString()}
                {' / '}
                <Currency
                  currency='CLP'
                  digits={0}
                  value={bulkPurchase.inAmount}
                />
              </MenuItem>
            ))}
          </SelectField>
        </FormControl>
        <Typography textAlign='right'>
          Monto restante:
          {' '}
          <Currency
            currency='CLP'
            digits={0}
            value={bulkPurchase?.amountLeft}
          />
        </Typography>
        <CurrencyField
          name='amount'
          label='Monto a transferir'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>CLP</small>&nbsp;$
              </InputAdornment>
            ),
          }}
          disabled={!bulkPurchase}
          digits={0}
          positive
        />
        <UserSelectField
          label='Destinatario'
          name='receiver'
          disabled={!bulkPurchase}
          exceptUserId={bulkPurchase?.userId}
        />
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={12}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            color='error'
          >
            Confirmar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type BulkPurchaseTransferrerProps = {
  userId: string
  bulkPurchases: BulkPurchase[]
}

const BulkPurchaseTransferrer = ({
  userId,
  bulkPurchases,
}: BulkPurchaseTransferrerProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const [transferBulkPurchase] =
    useMutation<TransferBulkPurchaseData, TransferBulkPurchaseVars>(
      TRANSFER_BULK_PURCHASE_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          { query: BULK_PURCHASES_OVERVIEW_QUERY, variables: { userId } },
        ],
      })

  const handleSubmit = async ({ bulkPurchaseId, amount, receiver }: FormValues) => {
    if (!receiver?.id) {
      return
    }

    const response = await transferBulkPurchase({
      variables: {
        bulkPurchaseId,
        amount,
        receiverId: receiver.id,
      },
    })

    if (response.data?.transferBulkPurchase === 'OK!') {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  return (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Transferir orden de compra'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(12),
        })}
      >
        <KeyboardDoubleArrowRight />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Transferir orden de compra'
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema(bulkPurchases)}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <InnerForm
              {...props}
              bulkPurchases={bulkPurchases}
            />
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  )
}

export default BulkPurchaseTransferrer
