import * as React from 'react'

import { Add, Balance, Download, Upload } from '@mui/icons-material'
import { Button, Fab, Stack } from '@mui/material'

import { Dialog } from 'shared/components'

import { FundDepositCreator } from './fund_deposit_creator'
import { FundSwapCreator } from './fund_swap_creator'
import { FundWithdrawalCreator } from './fund_withdrawal_creator'
import { FundDetails } from '../../../queries/funds'

type StepKey = 'OPERATION_SELECT' | 'DEPOSIT' | 'SWAP' | 'WITHDRAWAL'

const STEP_LABELS: { [key in StepKey]: string } = {
  OPERATION_SELECT: 'Agregar nueva operación',
  DEPOSIT: 'Agregar depósito de activos',
  SWAP: 'Rebalanceo de activos',
  WITHDRAWAL: 'Agregar retiro de activos',
}

type FundOperationCreatorProps = {
  fund: FundDetails
}

export const FundOperationCreator = ({
  fund,
}: FundOperationCreatorProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState<StepKey>('OPERATION_SELECT')

  const cancelCreate = () => setActiveStep('OPERATION_SELECT')

  const openDialog = () => {
    cancelCreate()
    setDialogOpen(true)
  }

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Crear operación'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title={STEP_LABELS[activeStep]}
        maxWidth='xs'
      >
        {(() => {
          switch (activeStep) {
          case 'OPERATION_SELECT': return (
            <Stack spacing={3}>
              <Button
                onClick={() => setActiveStep('DEPOSIT')}
                endIcon={<Download />}
                variant='contained'
                size='large'
              >
                Depósito
              </Button>
              <Button
                onClick={() => setActiveStep('WITHDRAWAL')}
                endIcon={<Upload />}
                variant='contained'
                size='large'
              >
                Retiro
              </Button>
              <Button
                onClick={() => setActiveStep('SWAP')}
                endIcon={<Balance />}
                variant='contained'
                size='large'
              >
                Rebalanceo
              </Button>
            </Stack>
          )
          case 'DEPOSIT': return (
            <FundDepositCreator
              cancelCreate={cancelCreate}
              closeDialog={closeDialog}
              fund={fund}
            />
          )
          case 'SWAP': return (
            <FundSwapCreator
              cancelCreate={cancelCreate}
              closeDialog={closeDialog}
              fund={fund}
            />
          )
          case 'WITHDRAWAL' : return (
            <FundWithdrawalCreator
              cancelCreate={cancelCreate}
              closeDialog={closeDialog}
              fund={fund}
            />
          )
          }
        })()}
      </Dialog>
    </React.Fragment>
  )
}
