import { gql } from '@apollo/client'

import { SPOUSE_IDENTITY_FIELDS } from 'shared/queries'

import type { SpouseIdentity } from 'shared/queries'

export type SpouseIdentityVars =  {
  legalIdentityId: string
}

export type SpouseIdentityData = {
  spouseIdentity: SpouseIdentity
}

export const SPOUSE_IDENTITY_QUERY = gql`
  ${SPOUSE_IDENTITY_FIELDS}
  query SpouseIdentity(
    $legalIdentityId: ID!
  ) {
    spouseIdentity(
      legalIdentityId: $legalIdentityId
    ) {
      ...SpouseIdentityFields
    }
  }
`

export type UpdateSpouseIdentityVars = {
  userId: string
  legalIdentityId: string
  givenName: string
  familyName: string
  birthdate: string
  identificationNumber: string
  nationality: string
}

export type UpdateSpouseIdentityData = {
  updateSpouseIdentity: string
}

export const UPDATE_SPOUSE_IDENTITY_MUTATION = gql`
  mutation UpdateSpouseIdentity(
    $userId: ID!
    $legalIdentityId: ID!
    $givenName: String!
    $familyName: String!
    $birthdate: ISO8601Date!
    $identificationNumber: String!
    $nationality: String!
  ) {
    updateSpouseIdentity(
      input: {
        userId: $userId
        legalIdentityId: $legalIdentityId
        givenName: $givenName
        familyName: $familyName
        birthdate: $birthdate
        identificationNumber: $identificationNumber
        nationality: $nationality
      }
    )
  }
`
