import { gql } from '@apollo/client'

import { DEPOSIT_ADDRESS_FIELDS } from 'shared/queries'

import type { DepositAddress } from 'shared/queries'

export type DepositAddressesVars =  {
  userId: string
}

export type DepositAddressesData = {
  depositAddresses: DepositAddress[]
}

export const DEPOSIT_ADDRESSES_QUERY = gql`
  ${DEPOSIT_ADDRESS_FIELDS}
  query DepositAddresses(
    $userId: ID!
  ) {
    depositAddresses(
      userId: $userId
    ) {
      ...DepositAddressFields
    }
  }
`

export type CreateDepositAddressVars = {
  blockchain: string
  userId: string
  value: string
}

export type CreateDepositAddressData = {
  createDepositAddress: string
}

export const CREATE_DEPOSIT_ADDRESS_MUTATION = gql`
  mutation CreateDepositAddress(
    $blockchain: String!
    $userId: ID!
    $value: String!
  ) {
    createDepositAddress(
      input: {
        blockchain: $blockchain
        userId: $userId
        value: $value
      }
    )
  }
`
