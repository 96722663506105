import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid, Stack, Typography } from '@mui/material'
import { navigate } from '@reach/router'

import {
  AppContainer,
  Currency,
  CurrencySkeleton,
  FundDisplay,
  Loading,
  SeoHeaders,
} from 'shared/components'
import { FUNDS_QUERY } from 'shared/queries'

import { FundCreator } from '../fund_creator'

import type { RouteComponentProps } from '@reach/router'
import type { FundsData, FundsVars } from 'shared/queries'

type BalanceProps = {
  descriptionText: string
  loading: boolean
  currency: string
  digits?: number
  value: number
}

export const Balance = ({
  descriptionText,
  loading,
  currency,
  digits,
  value,
}: BalanceProps) => (
  <AppContainer
    sm={12}
    md={6}
    sx={{ p: 3, backgroundColor: 'primary.dark', color: 'common.white' }}
  >
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
    >
      <Typography
        variant='h4'
        component='p'
        fontWeight={600}
        lineHeight='1.2'
        textAlign='center'
      >
        {loading ? (
          <CurrencySkeleton animation='wave' />
        ) : (
          <Currency
            currency={currency}
            digits={digits}
            value={value}
          />
        )}
      </Typography>
    </Stack>
    <Typography
      variant='h6'
      component='p'
      textAlign='center'
    >
      {descriptionText}
    </Typography>
  </AppContainer>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MainFunds = (props: RouteComponentProps) => {
  const { loading: fundsLoading, data: fundsData } =
    useQuery<FundsData, FundsVars>(FUNDS_QUERY)

  const funds = fundsData?.funds.funds || []
  const totalBalance = fundsData?.funds.totalBalance || 0

  return fundsLoading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <SeoHeaders title='Fondos' />
      <Grid
        container
        spacing={3}
        paddingBottom={12}
      >
        <Balance
          loading={false}
          descriptionText='Monto total'
          currency='USD'
          digits={2}
          value={totalBalance}
        />
        {(funds.length === 0) ? (
          <AppContainer
            sx={{
              height: 280,
              p: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Presiona el botón para crear un fondo
          </AppContainer>
        ) : (
          funds.map((fund, index) => (
            <FundDisplay
              key={index}
              fund={fund}
              onClick={() => navigate(`funds/${fund.id}`)}
            >
              <Typography sx={{ marginY: 'auto' }}>
                Monto total:
                {' '}
                <Currency
                  currency='USD'
                  digits={2}
                  value={fund.totalBalance}
                />
              </Typography>
            </FundDisplay>
          ))
        )}
      </Grid>
      <FundCreator />
    </React.Fragment>
  )
}

export default MainFunds
