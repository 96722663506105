import * as React from 'react'

import { useQuery } from '@apollo/client'

import { PortfolioDisplay, SeoHeaders } from 'shared/components'
import { ALL_TICKER_PRICES_QUERY, MARKET_ASSETS_QUERY } from 'shared/queries'
import { asOperationsArray, hourlyTimestamp } from 'shared/services'

import { BASE_APYS_QUERY } from '../../queries/base_apys'
import { ALL_USER_OPERATIONS_QUERY } from '../../queries/user_operations'
import AntiFomoText from '../anti_fomo_text'

import type {
  BaseApysData,
  BaseApysVars,
} from '../../queries/base_apys'
import type {
  AllUserOperationsData,
  AllUserOperationsVars,
} from '../../queries/user_operations'
import type { RouteComponentProps } from '@reach/router'
import type {
  AllTickerPricesData,
  AllTickerPricesVars,
  MarketAssetsData,
  MarketAssetsVars,
} from 'shared/queries'

type Range = AllTickerPricesVars['range']

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MainPortfolio = (props: RouteComponentProps) => {
  const [range, setRange] = React.useState<Range>('24h')

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: pricesLoading, data: pricesData } =
    useQuery<AllTickerPricesData, AllTickerPricesVars>(ALL_TICKER_PRICES_QUERY, {
      variables: {
        range,
        endTimestamp: hourlyTimestamp(),
      },
    })

  const { loading: baseApysLoading, data: baseApysData } =
    useQuery<BaseApysData, BaseApysVars>(BASE_APYS_QUERY)

  const { loading: operationsLoading, data: operationsData } =
    useQuery<AllUserOperationsData, AllUserOperationsVars>(ALL_USER_OPERATIONS_QUERY)

  const operations = asOperationsArray(operationsData?.allUserOperations)

  return (
    <React.Fragment>
      <SeoHeaders title='Reportería' />
      <PortfolioDisplay
        loading={assetsLoading || pricesLoading || baseApysLoading || operationsLoading}
        assetsData={assetsData}
        pricesData={pricesData}
        baseApysData={baseApysData}
        operations={operations}
        range={range}
        setRange={setRange}
        fomoText={<AntiFomoText />}
      />
    </React.Fragment>
  )
}

export default MainPortfolio
