import { gql } from '@apollo/client'

export type DeleteBankDepositVars = {
  bankDepositId: string
}

export type DeleteBankDepositData = {
  deleteBankDeposit: string
}

export const DELETE_BANK_DEPOSIT_MUTATION = gql`
  mutation DeleteBankDeposit(
    $bankDepositId: ID!
  ) {
    deleteBankDeposit(
      input: {
        bankDepositId: $bankDepositId
      }
    )
  }
`
