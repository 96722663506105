import { gql } from '@apollo/client'

import type { PaginableQuery, PaginableVars } from './pagination_helpers'

export type Admin = {
  id: string
  email: string
  nickName: string
  role: string
}

const ADMIN_DATA_FIELDS = gql`
  fragment AdminFields on Admin {
    id
    email
    nickName
    role
  }
`

export type AdminsVars = PaginableVars

export type AdminsData = {
  admins: PaginableQuery<Admin>
}

export const ADMINS_QUERY = gql`
  ${ADMIN_DATA_FIELDS}
  query Admins(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    admins(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      edges {
        cursor
        node {
          ...AdminFields
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
`

export type AdminData = Record<string, never>

export type AdminVars = {
  admin: Admin
}

export const ADMIN_QUERY = gql`
  ${ADMIN_DATA_FIELDS}
  query Admin {
    admin {
      ...AdminFields
    }
  }
`
