import * as React from 'react'

import { ListItem, ListItemText } from '@mui/material'

import ListDisplay from './list_display'
import {
  listCoincidenceLabel,
  riskValueLabel,
} from '../../../queries/risk_ratings'

import type { RiskRating } from '../../../queries/risk_ratings'

type RiskRatingDetailsDisplayProps = {
  riskRating: RiskRating
}

const RiskRatingDetailsDisplay = ({
  riskRating,
}: RiskRatingDetailsDisplayProps) =>  {
  const matchedLists = riskRating?.matchedLists || []

  return (
    <React.Fragment>
      <ListItem divider>
        <ListItemText primary={`Perfil de riesgo: ${riskValueLabel(riskRating?.value)}`} />
      </ListItem>
      {(matchedLists.length === 0) ? (
        <ListItem>
          <ListItemText primary='No se encontraron coincidencias con listas de riesgo' />
        </ListItem>
      ) : (
        matchedLists.map((list, index) => (
          <ListItem
            divider={index != matchedLists.length - 1}
            key={index}
          >
            <ListItemText
              primary={listCoincidenceLabel(list)}
              secondary={list.details}
            />
          </ListItem>
        ))
      )}
    </React.Fragment>
  )
}

type RiskRatingDisplayProps = {
  riskRating: RiskRating
}

const RiskRatingDisplay = ({
  riskRating,
}: RiskRatingDisplayProps) => (
  <ListDisplay
    title={riskRating.identityName}
    item={riskRating}
  >
    {(item) => <RiskRatingDetailsDisplay riskRating={item} />}
  </ListDisplay>
)

export default RiskRatingDisplay
