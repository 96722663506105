import * as React from 'react'

import { useMutation } from '@apollo/client'
import { DeleteForever } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay } from 'shared/components'

import {
  DELETE_USER_DYNAMIC_CONFIG_MUTATION,
  DYNAMIC_CONFIG_QUERY,
} from '../../../queries/dynamic_configs'
import { translateGuitaError } from '../../../services/error_messages'

import type {
  DeleteUserDynamicConfigData,
  DeleteUserDynamicConfigVars,
  DynamicConfig,
  UserDynamicConfig,
} from '../../../queries/dynamic_configs'

type UserDynamicConfigDeleterProps = {
  dynamicConfig: DynamicConfig
  userDynamicConfig: UserDynamicConfig
}

export const UserDynamicConfigDeleter = ({
  dynamicConfig,
  userDynamicConfig,
}: UserDynamicConfigDeleterProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [updating, setUpdating] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()

  const [deleteUserDynamicConfig] =
    useMutation<DeleteUserDynamicConfigData, DeleteUserDynamicConfigVars>(
      DELETE_USER_DYNAMIC_CONFIG_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          { query: DYNAMIC_CONFIG_QUERY, variables: { configId: dynamicConfig.id } },
        ],
      })

  const handleConfirm = async () => {
    setUpdating(true)
    const response = await deleteUserDynamicConfig({
      variables: {
        configId: dynamicConfig.id,
        userId: userDynamicConfig.user.id,
      },
    })
    setUpdating(false)

    if (response.data?.deleteUserDynamicConfig) {
      closeDialog()
    } else {
      setErrorMsg(translateGuitaError(response))
    }
  }

  const openDialog = () => {
    setErrorMsg(null)
    setDialogOpen(true)
  }

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <IconButton
        edge='end'
        aria-label='Eliminar'
        onClick={openDialog}
      >
        <DeleteForever />
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Eliminar configuración global'
        contentText={(
          <React.Fragment>
            Estás a punto de eliminar esta configuración de usuario.
            Esta acción <strong>NO</strong> se puede revertir.
          </React.Fragment>
        )}
      >
        <ErrorDisplay
          errorMsg={errorMsg}
          mt={2}
        />
        <ButtonsContainer mt={2}>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              onClick={closeDialog}
              disabled={updating}
            >
              Cancelar
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='error'
              variant='contained'
              onClick={handleConfirm}
              disabled={updating}
            >
              Eliminar
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Dialog>
    </React.Fragment>
  )
}

export default UserDynamicConfigDeleter
