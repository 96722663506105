import * as React from 'react'

import { useQuery } from '@apollo/client'

import { BulkPurchasesDashboard, SeoHeaders } from 'shared/components'
import { withJsTimestamp } from 'shared/services'

import { BULK_PURCHASES_OVERVIEW_QUERY } from '../../queries/bulk_purchases'
import { USER_QUERY } from '../../queries/users'
import { canCreateOperations } from '../../services/admin_roles'
import BankDepositDeleter from '../bank_deposit_deleter'
import BulkPurchaseCreator from '../bulk_purchase_creator'
import BulkPurchaseDeleter from '../bulk_purchase_deleter'
import BulkPurchaseTransferrer from '../bulk_purchase_transferrer'

import type {
  BulkPurchasesOverviewData,
  BulkPurchasesOverviewVars,
} from '../../queries/bulk_purchases'
import type { UserData, UserVars } from '../../queries/users'
import type { RouteComponentProps } from '@reach/router'

type UserProps = RouteComponentProps & {
  userId?: string
  adminRole?: string
}

const BulkPurchases = (props: UserProps) => {
  const userId = props.userId || ''

  const { loading: overviewLoading, data: overviewData } =
    useQuery<BulkPurchasesOverviewData, BulkPurchasesOverviewVars>(
      BULK_PURCHASES_OVERVIEW_QUERY, {
        variables: {
          userId,
        },
      })

  const { loading: userLoading, data: userData } =
    useQuery<UserData, UserVars>(USER_QUERY, {
      variables: {
        userId,
      },
    })

  const bulkPurchasesOverview = overviewData?.bulkPurchasesOverview
  const openBulkPurchases = withJsTimestamp(bulkPurchasesOverview?.openBulkPurchases || [])
  const unspentBankDeposits = withJsTimestamp(bulkPurchasesOverview?.unspentBankDeposits || [])

  return (
    <React.Fragment>
      <SeoHeaders title={`Órdenes - ${userId}`} />
      <BulkPurchasesDashboard
        loading={overviewLoading || userLoading}
        bulkPurchasesOverview={bulkPurchasesOverview}
        withBankTransferDialog={userData?.user.verification?.state === 'approved'}
        withBankDepositSimulator
      />
      {canCreateOperations(props.adminRole) && (
        <React.Fragment>
          <BulkPurchaseCreator userId={userId} />
          <BulkPurchaseTransferrer
            userId={userId}
            bulkPurchases={openBulkPurchases}
          />
          <BulkPurchaseDeleter
            userId={userId}
            bulkPurchases={openBulkPurchases}
          />
          <BankDepositDeleter
            userId={userId}
            bankDeposits={unspentBankDeposits}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default BulkPurchases
