import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Autorenew, MoreVert, Publish } from '@mui/icons-material'
import { Button, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material'

import { Dialog, ErrorDisplay } from 'shared/components'

import {
  BINANCE_KYC_CHECK_MUTATION,
  BINANCE_KYC_PUSH_MUTATION,
  BINANCE_KYC_QUERY,
} from '../../../queries/binance_kyc'
import { translateGuitaError } from '../../../services/error_messages'

import type {
  BinanceKycCheckData,
  BinanceKycCheckVars,
  BinanceKycData,
  BinanceKycPushData,
  BinanceKycPushVars,
  BinanceKycVars,
} from '../../../queries/binance_kyc'

const KycBinanceButtons = ({ userId }: { userId: string }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const open = Boolean(anchorEl)

  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)

  const { loading, data } =
    useQuery<BinanceKycData, BinanceKycVars>(BINANCE_KYC_QUERY, {
      variables: {
        userId,
      },
    })

  const [binanceKycCheck] =
    useMutation<BinanceKycCheckData, BinanceKycCheckVars>(BINANCE_KYC_CHECK_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: BINANCE_KYC_QUERY, variables: { userId } },
      ],
    })

  const [binanceKycPush] =
    useMutation<BinanceKycPushData, BinanceKycPushVars>(BINANCE_KYC_PUSH_MUTATION, {
      errorPolicy: 'all',
    })

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const handleRefreshKycBinance = async () => {
    closeMenu()
    await binanceKycCheck({ variables: { userId } })
  }

  const handlePushKycBinance = async () => {
    closeMenu()
    const response = await binanceKycPush({ variables: { userId } })

    if (response.data?.binanceKycPush) {
      closeDialog()
      return
    }

    setErrorMsg(translateGuitaError(response))
  }

  return loading ? (
    null
  ) : (
    <React.Fragment>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              boxShadow: 12,
              overflow: 'visible',
              mt: 0.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 16,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(-135deg)',
                zIndex: 0,
              },
            },
          },
        }}
      >
        {[
          (
            <MenuItem
              key='push'
              onClick={openDialog}
            >
              <ListItemIcon>
                <Publish fontSize='small' />
              </ListItemIcon>
              Empujar datos
            </MenuItem>
          ),
          data?.binanceKyc && (
            <MenuItem
              key='refresh'
              onClick={handleRefreshKycBinance}
            >
              <ListItemIcon>
                <Autorenew fontSize='small' />
              </ListItemIcon>
              Consultar cambios
            </MenuItem>
          ),
        ]}
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Empujar datos de usuario hacia Binance'
        contentText='Esta operación actualizará los datos del usuario en Binance. No se puede deshacer.'
      >
        <ErrorDisplay errorMsg={errorMsg} />
        <Button
          variant='contained'
          onClick={handlePushKycBinance}
        >
          EMPUJAR DATOS
        </Button>
      </Dialog>
    </React.Fragment>
  )
}

export default KycBinanceButtons
