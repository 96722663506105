import { gql } from '@apollo/client'

import { BASE_APY_FIELDS } from 'shared/queries'

import type { BaseApy } from 'shared/queries'

export type BaseApysVars = {
  userId?: string
}

export type BaseApysData = {
  baseApys: BaseApy[]
}

export const BASE_APYS_QUERY = gql`
  ${BASE_APY_FIELDS}
  query BaseApys(
    $userId: ID
  ) {
    baseApys(
      userId: $userId
    ) {
      ...BaseApyFields
    }
  }
`
