import { gql } from '@apollo/client'

import { BANK_ACCOUNT_FIELDS } from 'shared/queries'

import type { BankAccount } from 'shared/queries'

export type BankAccountsVars = {
  userId: string
}

export type BankAccountsData = {
  bankAccounts: BankAccount[]
}

export const BANK_ACCOUNTS_QUERY = gql`
  ${BANK_ACCOUNT_FIELDS}
  query BankAccounts(
    $userId: ID!
  ) {
    bankAccounts(
      userId: $userId
    ) {
      ...BankAccountFields
    }
  }
`

export type CreateBankAccountVars = {
  accountType: string
  bankCode: string
  number: string
  userId: string
}

export type CreateBankAccountData = {
  createBankAccount: string
}

export const CREATE_BANK_ACCOUNT_MUTATION = gql`
  mutation CreateBankAccount(
    $accountType: String!
    $bankCode: String!
    $number: String!
    $userId: ID!
  ) {
    createBankAccount(
      input: {
        accountType: $accountType
        bankCode: $bankCode
        number: $number
        userId: $userId
      }
    )
  }
`

export type DeleteBankAccountVars = {
  bankAccountId: string
  userId: string
}

export type DeleteBankAccountData = {
  deleteBankAccount: string
}

export const DELETE_BANK_ACCOUNT_MUTATION = gql`
  mutation DeleteBankAccount(
    $bankAccountId: ID!
    $userId: ID!
  ) {
      deleteBankAccount(
        input: {
          bankAccountId: $bankAccountId
          userId: $userId
        }
      )
   }
`
