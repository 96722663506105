import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, DialogContentText, Stack } from '@mui/material'

import { ErrorDisplay } from 'shared/components'

import {
  DELETE_ULTIMATE_BENEFICIAL_OWNER_MUTATION,
} from '../../../../queries/ultimate_beneficial_owners'
import { USER_QUERY } from '../../../../queries/users'
import { translateGuitaError } from '../../../../services/error_messages'

import type {
  DeleteUltimateBeneficialOwnerData,
  DeleteUltimateBeneficialOwnerVars,
} from '../../../../queries/ultimate_beneficial_owners'

type UltimateBeneficialOwnerDeleteFormProps = {
  closeDialog: () => void
  userId: string
  ultimateBeneficialOwnerId: string
}

const UltimateBeneficialOwnerDeleteForm = ({
  closeDialog,
  userId,
  ultimateBeneficialOwnerId,
}: UltimateBeneficialOwnerDeleteFormProps) => {
  const [updating, setUpdating] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()

  const [deleteUltimateBeneficialOwner] =
    useMutation<
      DeleteUltimateBeneficialOwnerData,
      DeleteUltimateBeneficialOwnerVars
    >(DELETE_ULTIMATE_BENEFICIAL_OWNER_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_QUERY, variables: { userId } },
      ],
    })

  const handleConfirm = async () => {
    setUpdating(true)
    const response = await deleteUltimateBeneficialOwner({
      variables: {
        ultimateBeneficialOwnerId: ultimateBeneficialOwnerId,
        userId: userId,
      },
    })
    setUpdating(false)

    if (response.data?.deleteUltimateBeneficialOwner) {
      closeDialog()
      return
    }

    setErrorMsg(translateGuitaError(response))
  }

  return (
    <Stack spacing={2}>
      <DialogContentText id='alert-dialog-description'>
        Esta acción <strong>NO</strong> se puede revertir.
      </DialogContentText>
      <ErrorDisplay errorMsg={errorMsg} />
      <Button
        color='error'
        variant='contained'
        onClick={handleConfirm}
        disabled={updating}
        fullWidth
      >
        Eliminar
      </Button>
    </Stack>
  )
}

export default UltimateBeneficialOwnerDeleteForm
