import * as React from 'react'

import { NoteAdd } from '@mui/icons-material'
import { Button } from '@mui/material'

import { AppContainer, Dialog } from 'shared/components'

import LegalIdentityProofDisplay from './legal_identity_proof_display'
import LegalIdentityProofEditForm from './legal_identity_proof_edit_form'

import type { LegalIdentityProofView } from 'shared/queries'

type LegalIdentityProofCreatorProps = {
  userId: string
  isBusiness: boolean
}

const LegalIdentityProofCreator = ({
  userId,
  isBusiness,
}: LegalIdentityProofCreatorProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <AppContainer
        md={6}
        sx={{
          p: 3,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <Button
          endIcon={<NoteAdd />}
          onClick={openDialog}
          variant='contained'
          size='large'
        >
          Agregar documento
        </Button>
      </AppContainer>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title='Agregar documento'
      >
        <LegalIdentityProofEditForm
          closeDialog={closeDialog}
          userId={userId}
          isBusiness={isBusiness}
        />
      </Dialog>
    </React.Fragment>
  )
}

type LegalIdentityProofsDisplayProps = {
  userId: string
  isBusiness: boolean
  legalIdentityProofs: LegalIdentityProofView[]
}

const LegalIdentityProofsDisplay = ({
  userId,
  isBusiness,
  legalIdentityProofs,
}: LegalIdentityProofsDisplayProps) => (
  <React.Fragment>
    {legalIdentityProofs.map((legalIdentityProof, index) => (
      <LegalIdentityProofDisplay
        key={index}
        userId={userId}
        isBusiness={isBusiness}
        legalIdentityProof={legalIdentityProof}
      />
    ))}
    <LegalIdentityProofCreator
      userId={userId}
      isBusiness={isBusiness}
    />
  </React.Fragment>
)

export default LegalIdentityProofsDisplay
