import * as React from 'react'

import { TableCell, TableRow } from '@mui/material'

type TableEmptyProps = {
  columns: number
  message?: React.ReactNode
}

export const TableEmpty = ({
  columns,
  message = 'No hay datos para mostrar',
}: TableEmptyProps) => (
  <TableRow>
    <TableCell
      colSpan={columns}
      sx={{
        color: 'text.secondary',
        textAlign: 'center',
      }}
    >
      {message}
    </TableCell>
  </TableRow>
)

export default TableEmpty
