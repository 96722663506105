import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Autocomplete, Box, Grid } from '@mui/material'

import {
  AssetBadge,
  AutocompleteContainer,
  OperationsList,
  OptionCheckbox,
  OptionDisplay,
  OptionTextField,
  SeoHeaders,
} from 'shared/components'
import { MARKET_ASSETS_QUERY, USER_OPERATION_LABELS } from 'shared/queries'
import { asOperationsArray, withSymbols, withTypes } from 'shared/services'

import { USER_OPERATIONS_QUERY } from '../../queries/user_operations'
import { canCreateOperations } from '../../services/admin_roles'
import OperationCreator from '../operation_creator'

import type {
  UserOperationsData,
  UserOperationsVars,
} from '../../queries/user_operations'
import type { RouteComponentProps } from '@reach/router'
import type { MarketAssetsData, MarketAssetsVars } from 'shared/queries'

type FilteredOperationsListProps = {
  symbols: string[]
  operationTypes: string[]
  userId: string
}

const FilteredOperationsList = ({
  symbols,
  operationTypes,
  userId,
}: FilteredOperationsListProps) => {
  const { loading, data } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY, {
      variables: {
        userId,
      },
    })

  let assetOperations = asOperationsArray(data?.userOperations)

  if (symbols.length) {
    assetOperations = withSymbols(assetOperations, symbols)
  }

  if (operationTypes.length) {
    assetOperations = withTypes(assetOperations, operationTypes)
  }

  return (
    <OperationsList
      loading={loading}
      operations={assetOperations}
    />
  )
}

type HistoryContentProps = {
  userId: string
}

const HistoryContent = ({
  userId,
}: HistoryContentProps) => {
  const [symbols, setSymbols] = React.useState<string[]>([])
  const [operationTypes, setOperationTypes] = React.useState<string[]>([])

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const marketAssets = assetsData?.marketAssets || []

  return (
    <Grid
      container
      spacing={3}
    >
      <AutocompleteContainer>
        <Autocomplete
          disableCloseOnSelect
          multiple
          limitTags={2}
          disabled={assetsLoading}
          options={marketAssets}
          getOptionLabel={(option) => option.name}
          onChange={(_event, newValue) => {
            setSymbols(newValue.map((asset) => asset.symbol))
          }}
          renderOption={(props, option, { selected }) => (
            <OptionDisplay {...props}>
              <OptionCheckbox checked={selected} />
              <AssetBadge
                symbol={option.symbol}
                height={20}
              />
              {option.name}
            </OptionDisplay>
          )}
          renderInput={(params) => (
            <OptionTextField
              name='marketAsset'
              label='Filtrar por activo'
              params={params}
            />
          )}
          disablePortal
        />
      </AutocompleteContainer>
      <AutocompleteContainer>
        <Autocomplete
          disableCloseOnSelect
          multiple
          limitTags={2}
          options={USER_OPERATION_LABELS}
          getOptionLabel={(option) => option.label}
          onChange={(_event, newValue) => {
            setOperationTypes(newValue.map((operationLabel) => operationLabel.typename))
          }}
          renderOption={(props, option, { selected }) => (
            <OptionDisplay {...props}>
              <OptionCheckbox checked={selected} />
              {option.label}
            </OptionDisplay>
          )}
          renderInput={(params) => (
            <OptionTextField
              name='operationType'
              label='Filtrar por tipo de operación'
              params={params}
            />
          )}
          disablePortal
        />
      </AutocompleteContainer>
      <FilteredOperationsList
        symbols={symbols}
        operationTypes={operationTypes}
        userId={userId}
      />
    </Grid>
  )
}

type HistoryProps = RouteComponentProps & {
  userId?: string
  adminRole?: string
}

const History = (props: HistoryProps) => {
  const userId = props.userId || ''

  return (
    <React.Fragment>
      <SeoHeaders title={`Historial - ${userId}`} />
      <Box sx={{ maxWidth: 'md', mx: 'auto' }}>
        <HistoryContent userId={userId} />
      </Box>
      {canCreateOperations(props.adminRole) && (
        <OperationCreator
          userId={userId}
          adminRole={props.adminRole}
        />
      )}
    </React.Fragment>
  )
}

export default History
