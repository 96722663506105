import * as React from 'react'

import { useQuery } from '@apollo/client'
import { List, ListItem, ListItemText, Stack } from '@mui/material'

import { AppContainer, Loading } from 'shared/components'

import KycBinanceButtons from './binance_kyc_buttons'
import { BINANCE_KYC_QUERY, STATUS_LABELS } from '../../../queries/binance_kyc'

import type {
  BinanceKyc,
  BinanceKycData,
  BinanceKycVars,
} from '../../../queries/binance_kyc'

type BinanceKycDetailsDisplayProps = {
  binanceKyc: BinanceKyc
}

const BinanceKycDetailsDisplay = ({
  binanceKyc,
}: BinanceKycDetailsDisplayProps) => (
  <React.Fragment>
    <ListItem divider>
      <ListItemText
        primary='Estado general'
        secondary={STATUS_LABELS[binanceKyc.kycStatus]}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Información principal'
        secondary={STATUS_LABELS[binanceKyc.basicStatus]}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Documentos de verificación'
        secondary={STATUS_LABELS[binanceKyc.identityStatus]}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Reconocimiento facial'
        secondary={STATUS_LABELS[binanceKyc.faceStatus]}
      />
    </ListItem>
    <ListItem>
      <ListItemText
        primary='Cruce con listas de sanciones'
        secondary={STATUS_LABELS[binanceKyc.worldCheckStatus]}
      />
    </ListItem>
  </React.Fragment>
)

type BinanceKycDisplayProps = {
  userId: string
}

const BinanceKycDisplay = ({
  userId,
}: BinanceKycDisplayProps) => {
  const { loading, data } =
    useQuery<BinanceKycData, BinanceKycVars>(BINANCE_KYC_QUERY, {
      variables: {
        userId,
      },
    })

  const binanceKyc = data?.binanceKyc

  return loading ? (
    <AppContainer
      md={6}
      sx={{ p: 2, textAlign: 'center' }}
    >
      <Loading />
    </AppContainer>
  ) : (
    <AppContainer
      md={6}
      sx={{ height: '100%' }}
    >
      <List
        dense
        sx={{ p: 1 }}
      >
        <ListItem divider>
          <ListItemText
            primaryTypographyProps={{ variant: 'h5' }}
            primary={(
              <Stack
                direction='row'
                justifyContent='space-between'
              >
                Detalle del Binance KYC
                <KycBinanceButtons userId={userId} />
              </Stack>
            )}
          />
        </ListItem>
        {binanceKyc ? (
          <BinanceKycDetailsDisplay binanceKyc={binanceKyc} />
        ) : (
          <ListItem>
            <ListItemText primary='No se han empujado los datos del usuario aún.' />
          </ListItem>
        )}
      </List>
    </AppContainer>
  )
}

export default BinanceKycDisplay
