import * as React from 'react'

import { useMutation } from '@apollo/client'
import { DeleteForever } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import { navigate } from '@reach/router'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay } from 'shared/components'

import { DELETE_DYNAMIC_CONFIG_MUTATION } from '../../../queries/dynamic_configs'
import { translateGuitaError } from '../../../services/error_messages'

import type {
  DeleteDynamicConfigData,
  DeleteDynamicConfigVars,
  DynamicConfig,
} from '../../../queries/dynamic_configs'

type DynamicConfigDeleterProps = {
  dynamicConfig: DynamicConfig
}

export const DynamicConfigDeleter = ({
  dynamicConfig,
}: DynamicConfigDeleterProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [updating, setUpdating] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()

  const [deleteDynamicConfig] =
    useMutation<DeleteDynamicConfigData, DeleteDynamicConfigVars>(DELETE_DYNAMIC_CONFIG_MUTATION, {
      errorPolicy: 'all',
      update(cache) {
        const normalizedId = cache.identify({ id: dynamicConfig.id, __typename: 'DynamicConfig' })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    })

  const handleConfirm = async () => {
    setUpdating(true)
    const response = await deleteDynamicConfig({
      variables: {
        configId: dynamicConfig.id,
      },
    })
    setUpdating(false)

    if (response.data?.deleteDynamicConfig) {
      navigate('/app/dynamic-configs/')
    } else {
      setErrorMsg(translateGuitaError(response))
    }
  }

  const openDialog = () => {
    setErrorMsg(null)
    setDialogOpen(true)
  }

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <IconButton
        edge='end'
        aria-label='Eliminar'
        onClick={openDialog}
      >
        <DeleteForever />
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Eliminar configuración global'
        contentText={(
          <React.Fragment>
            Estás a punto de eliminar esta configuración y la de los usuarios asociados.
            Esta acción <strong>NO</strong> se puede revertir.
          </React.Fragment>
        )}
      >
        <ErrorDisplay
          errorMsg={errorMsg}
          mt={2}
        />
        <ButtonsContainer mt={2}>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              onClick={closeDialog}
              disabled={updating}
            >
              Cancelar
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='error'
              variant='contained'
              onClick={handleConfirm}
              disabled={updating}
            >
              Eliminar
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Dialog>
    </React.Fragment>
  )
}

export default DynamicConfigDeleter
