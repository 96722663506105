import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'

import { ButtonContainer, ButtonsContainer, ErrorDisplay } from 'shared/components'
import {
  LegalAddressFields,
  getLegalAddressInitialValues as getInitialValues,
  legalAddressValidationSchema as validationSchema,
} from 'shared/forms'
import { setFormError } from 'shared/services'

import { UPDATE_LEGAL_ADDRESS_MUTATION } from '../../../queries/legal_addresses'
import { USER_QUERY } from '../../../queries/users'
import { translateGuitaError } from '../../../services/error_messages'

import type {
  UpdateLegalAddressData,
  UpdateLegalAddressVars,
} from '../../../queries/legal_addresses'
import type { LegalAddressFormValues as FormValues } from 'shared/forms'
import type { LegalAddress } from 'shared/queries'

type InnerFormProps = FormikProps<FormValues> & {
  isPresent: boolean
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  isPresent,
}: InnerFormProps) => (
  <Form>
    <LegalAddressFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          {isPresent ? 'Modificar' : 'Crear'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type LegalAddressEditFormProps = {
  closeDialog: () => void
  userId: string
  legalAddress?: LegalAddress
}

const LegalAddressEditForm = ({
  closeDialog,
  userId,
  legalAddress,
}: LegalAddressEditFormProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [updateLegalAddress] =
    useMutation<UpdateLegalAddressData, UpdateLegalAddressVars>(UPDATE_LEGAL_ADDRESS_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_QUERY, variables: { userId } },
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateLegalAddress({
      variables: {
        userId,
        ...values,
      },
    })

    if (response.data?.updateLegalAddress === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={getInitialValues(legalAddress)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <InnerForm
          {...props}
          isPresent={!!legalAddress}
        />
      )}
    </Formik>
  )
}

export default LegalAddressEditForm
