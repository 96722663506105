import * as React from 'react'

import { ListItem, ListItemText } from '@mui/material'

import { ORIGIN_LABELS } from 'shared/queries'

import FundsOriginEditForm from './funds_origin_edit_form'
import ListDisplay from '../list_display'

import type { FundsOrigin } from 'shared/queries'

type FundsOriginDisplayProps = {
  userId: string
  isBusiness: boolean
  fundsOrigin?: FundsOrigin
}

const FundsOriginDisplay = ({
  userId,
  isBusiness,
  fundsOrigin,
}: FundsOriginDisplayProps) => (
  <ListDisplay
    title='Origen de fondos'
    item={fundsOrigin}
    editForm={(closeDialog) => (
      <FundsOriginEditForm
        closeDialog={closeDialog}
        userId={userId}
        isBusiness={isBusiness}
        fundsOrigin={fundsOrigin}
      />
    )}
  >
    {(item) => item.origins.map((origin, index) => (
      <ListItem
        key={index}
        divider={index < item.origins.length - 1}
      >
        <ListItemText
          primary={ORIGIN_LABELS.find((item) => item.type === origin)?.name || origin}
          secondary={item.descriptions[index]}
        />
      </ListItem>
    ))}
  </ListDisplay>
)

export default FundsOriginDisplay
