import * as React from 'react'

import { Email } from '@mui/icons-material'
import { Button, Container, Stack, Typography } from '@mui/material'

import { AppContainer, Image, SeoHeaders } from 'shared/components'

import GuitaLogo from '../../images/guita-logo.svg'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotFound = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Feature deshabilitado' />
    <Container
      maxWidth='sm'
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AppContainer sx={{ px: 3, py: 5 }}>
        <Stack
          spacing={3}
          alignItems='center'
        >
          <Image
            src={GuitaLogo}
            alt='Logo Guita'
            width='75%'
            duration={500}
          />
          <Typography
            variant='h5'
            textAlign='center'
          >
            Esta función no ha sido habilitada en tu plataforma
          </Typography>
          <Typography
            variant='subtitle1'
            textAlign='center'
          >
            Solicita su activación contactándonos por correo:
          </Typography>
          <Button
            target='_blank'
            href='mailto:soporte@guita.cl?subject=Habilitar función'
            rel='noopener noreferrer'
            variant='contained'
            startIcon={<Email />}
            size='large'
          >
            soporte@guita.cl
          </Button>
        </Stack>
      </AppContainer>
    </Container>
  </React.Fragment>
)

export default NotFound
