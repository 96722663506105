import * as React from 'react'

import { useQuery } from '@apollo/client'

import { BulkPurchasesDashboard, SeoHeaders } from 'shared/components'

import { ALL_BULK_PURCHASES_OVERVIEW_QUERY } from '../../queries/bulk_purchases'
import BulkPurchaseCreator from '../bulk_purchase_creator'

import type {
  AllBulkPurchasesOverviewData,
  AllBulkPurchasesOverviewVars,
} from '../../queries/bulk_purchases'
import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MainBulkPurchases = (props: RouteComponentProps) => {
  const { loading, data } =
    useQuery<AllBulkPurchasesOverviewData, AllBulkPurchasesOverviewVars>(
      ALL_BULK_PURCHASES_OVERVIEW_QUERY,
    )

  return (
    <React.Fragment>
      <SeoHeaders title='Órdenes' />
      <BulkPurchasesDashboard
        loading={loading}
        bulkPurchasesOverview={data?.allBulkPurchasesOverview}
        withBankDepositSimulator
        withUserId
      />
      <BulkPurchaseCreator />
    </React.Fragment>
  )
}

export default MainBulkPurchases
