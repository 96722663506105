import * as React from 'react'

import { useQuery } from '@apollo/client'
import { BarChart } from '@mui/icons-material'
import { Button, Container, Stack, Typography } from '@mui/material'

import { AppContainer, Image, Loading, SeoHeaders } from 'shared/components'

import NotFound from './not_found'
import GuitaLogo from '../../images/guita-logo.svg'
import { DOMAIN_QUERY } from '../../queries/domains'

import type { DomainData, DomainVars } from '../../queries/domains'
import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Dashboard = (props: RouteComponentProps) => {
  const { loading, data } = useQuery<DomainData, DomainVars>(DOMAIN_QUERY)

  const dashboardLink = data?.domain.dashboardLink

  if (loading) {
    return <Loading />
  } else if (!dashboardLink) {
    return <NotFound />
  }

  return (
    <React.Fragment>
      <SeoHeaders title='Reporteria' />
      <Container
        disableGutters
        maxWidth='sm'
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AppContainer sx={{ px: 3, py: 4 }}>
          <Stack
            spacing={3}
            alignItems='center'
          >
            <Image
              src={GuitaLogo}
              alt='Logo Guita'
              width='75%'
              duration={500}
            />
            <Typography
              variant='h5'
              textAlign='center'
            >
              Paneles de reportería para Marketing y Business&nbsp;Analytics
            </Typography>
            <Typography textAlign='center'>
              Al hacer clic en el botón, tenrás acceso a reportes detallados
              del uso de tu plataforma:
            </Typography>
            <Button
              href={dashboardLink}
              target='_blank'
              rel='noopener noreferrer'
              variant='contained'
              startIcon={<BarChart />}
              size='large'
            >
              Acceder a reportes
            </Button>
            <Typography
              typography='body2'
              textAlign='center'
            >
              La información recolectada mediante Firebase Analytics y GA4
              incluye métricas y eventos para entender mejor la experiencia de
              tus clientes, y con ello optimizar su experiencia de uso dentro de
              tu plataforma.
            </Typography>
          </Stack>
        </AppContainer>
      </Container>
    </React.Fragment>
  )
}

export default Dashboard
