import * as React from 'react'

import { useQuery } from '@apollo/client'
import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { navigate } from '@reach/router'

import {
  AppContainer,
  CurrencyCell,
  GridDivider,
  Loading,
  SeoHeaders,
  Table,
} from 'shared/components'

import { BULK_PURCHASES_CONCILIATION_QUERY, SUPPLIER_LABELS } from '../../queries/bulk_purchases'
import {
  getSupplierCreditAmount,
  getTotalSupplierIn,
  getUnpaid,
  withSupplier,
} from '../../services/conciliation'
import BlancBulkPurchasesList from '../blanc_bulk_purchases_list'
import ConciliationBalances from '../conciliation_balances'

import type {
  BulkPurchasesConciliationData,
  BulkPurchasesConciliationVars,
} from '../../queries/bulk_purchases'
import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Conciliation = (props: RouteComponentProps) => {
  const { loading, data } =
    useQuery<BulkPurchasesConciliationData, BulkPurchasesConciliationVars>(
      BULK_PURCHASES_CONCILIATION_QUERY,
    )

  const todayAll = (data?.bulkPurchasesConciliation.todayAll || [])
  const pastUnpaid = (data?.bulkPurchasesConciliation.pastUnpaid || [])
  const unspentBankWithdrawals = (data?.bulkPurchasesConciliation.unspentBankWithdrawals || [])

  const blancBulkPurchases = withSupplier([...pastUnpaid, ...todayAll], 'BLANC')

  const suppliersData = Object.entries(SUPPLIER_LABELS).map(([supplier, supplierLabel]) => ({
    supplier,
    supplierLabel,
    todaySupplierIn: getTotalSupplierIn(getUnpaid(withSupplier(todayAll, supplier))),
    pastSupplierIn: getTotalSupplierIn(withSupplier(pastUnpaid, supplier)),
    creditAmount: getSupplierCreditAmount(unspentBankWithdrawals, supplier),
  }))

  return loading ? (
    <Loading />
  ) : (
    <Grid
      container
      spacing={3}
    >
      <SeoHeaders title='Conciliación' />
      <GridDivider>Conciliación General</GridDivider>
      <ConciliationBalances
        pastUnpaid={pastUnpaid}
        todayAll={todayAll}
      />
      <TableContainer
        component={AppContainer}
        sx={{ mt: 3 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Proveedor</TableCell>
              <TableCell>Deuda anterior <small>(CLP)</small></TableCell>
              <TableCell>Deuda hoy <small>(CLP)</small></TableCell>
              <TableCell>Deuda total <small>(CLP)</small></TableCell>
              <TableCell>Excedente pagos <small>(CLP)</small></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliersData.map((data, index) => (
              <TableRow
                hover
                key={index}
                onClick={() => navigate(`conciliation/${data.supplier}`)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell sx={{ border: '1px solid #ccc' }}>{data.supplierLabel}</TableCell>
                <CurrencyCell
                  currency='$'
                  digits={0}
                  value={data.pastSupplierIn}
                />
                <CurrencyCell
                  currency='$'
                  digits={0}
                  value={data.todaySupplierIn}
                />
                <CurrencyCell
                  currency='$'
                  digits={0}
                  value={data.pastSupplierIn + data.todaySupplierIn}
                />
                <CurrencyCell
                  currency='$'
                  digits={0}
                  value={data.creditAmount}
                />
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      {blancBulkPurchases.length > 0 && (
        <React.Fragment>
          <GridDivider>Ordenes sin proveedor asignado</GridDivider>
          <BlancBulkPurchasesList bulkPurchases={blancBulkPurchases} />
        </React.Fragment>
      )}
    </Grid>
  )
}

export default Conciliation
