import { gql } from '@apollo/client'

import { FUND_DEPOSIT_FIELDS, FUND_SWAP_FIELDS, FUND_WITHDRAWAL_FIELDS } from './fund_operations'

import type { FundDeposit, FundSwap, FundWithdrawal } from './fund_operations'
import type { Fund } from 'shared/queries'

export type FundDetails = Fund & {
  currentAmounts: number[]
  currentAssets: string[]
  currentPercentages: number[]
  currentRebalanceAmounts: number[]
  fundDeposits: FundDeposit[]
  fundSwaps: FundSwap[]
  fundWithdrawals: FundWithdrawal[]
}

const DETAILED_FUND_FIELDS = gql`
  ${FUND_DEPOSIT_FIELDS}
  ${FUND_SWAP_FIELDS}
  ${FUND_WITHDRAWAL_FIELDS}
  fragment FundDetailsFields on FundDetails {
    id
    assets
    name
    percentages
    totalBalance
    currentAmounts
    currentAssets
    currentPercentages
    currentRebalanceAmounts
    fundDeposits {
      ...FundDepositFields
    }
    fundSwaps {
      ...FundSwapFields
    }
    fundWithdrawals {
      ...FundWithdrawalFields
    }
  }
`

export type FundVars = {
  fundId: string
}

export type FundData = {
  fund: FundDetails
}

export const FUND_QUERY = gql`
  ${DETAILED_FUND_FIELDS}
  query Fund(
    $fundId: ID!
  ) {
    fund (
      fundId: $fundId
    ) {
      ...FundDetailsFields
    }
  }
`

export type CreateFundVars = {
  assets: string[]
  name: string
  percentages: string[]
}

export type CreateFundData = {
  createFund: string
}

export const CREATE_FUND_MUTATION = gql`
  mutation CreateFund(
    $assets: [String!]!
    $name: String!
    $percentages: [String!]!
  ) {
    createFund(
      input: {
        assets: $assets
        name: $name
        percentages: $percentages
      }
    )
  }
`

export type UpdateFundVars = {
  fundId: string
  assets: string[]
  percentages: string[]
}

export type UpdateFundData = {
  updateFund: string
}

export const UPDATE_FUND_MUTATION = gql`
  mutation UpdateFund(
    $fundId: ID!
    $assets: [String!]!
    $percentages: [String!]!
  ) {
    updateFund(
      input: {
        fundId: $fundId
        assets: $assets
        percentages: $percentages
      }
    )
  }
`
