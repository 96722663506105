import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Add } from '@mui/icons-material'
import { Button, Divider, Fab, Stack } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay } from 'shared/components'
import {
  FundCompositionFields,
  fundCompositionValidationSchema,
  getFundCompositionInitialValues,
  validTotalPercentage,
} from 'shared/forms'
import { FUNDS_QUERY } from 'shared/queries'
import { setFormError } from 'shared/services'

import { CREATE_FUND_MUTATION } from '../queries/funds'
import { translateGuitaError } from '../services/error_messages'

import type { CreateFundData, CreateFundVars } from '../queries/funds'
import type { FormikProps } from 'formik'
import type { FundCompositionFormValues } from 'shared/forms'

type FormValues = { name: string } & FundCompositionFormValues

const initialValues: FormValues = ({
  name: '',
  ...getFundCompositionInitialValues(),
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    name: Yup.string()
      .required('Este campo es obligatorio')
      .min(2, 'Debes ingresar al menos dos caracteres')
      .max(64, 'No puede tener más de 64 caracteres'),
    ...fundCompositionValidationSchema.fields,
  })

type InnerFormProps = FormikProps<FormValues> & {
  closeDialog: () => void
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  values,
  closeDialog,
}: InnerFormProps) => (
  <Form>
    <Stack spacing={3}>
      <Field
        required
        name='name'
        type='text'
        label='Nombre del fondo'
        component={TextField}
        fullWidth
      />
      <Divider>Composición del fondo:</Divider>
      <FundCompositionFields />
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={closeDialog}
          variant='outlined'
          color='secondary'
        >
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid || !validTotalPercentage(values.percentages)}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          Crear
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

export const FundCreator = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const [createFund] =
    useMutation<CreateFundData, CreateFundVars>(CREATE_FUND_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [FUNDS_QUERY],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await createFund({
      variables: {
        ...values,
        percentages: values.percentages.map((percentage) => percentage.toString()),
      },
    })

    if (response.data?.createFund === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  return (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Crear un fondo'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Crear fondo'
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <InnerForm
              {...props}
              closeDialog={closeDialog}
            />
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  )
}
