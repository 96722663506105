import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, Stack } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, ErrorDisplay } from 'shared/components'
import {
  BaseIdentityFields,
  baseIdentityValidationSchema,
  getBaseIdentityInitialValues,
} from 'shared/forms'
import { setFormError, toISO8601Date } from 'shared/services'

import {
  SPOUSE_IDENTITY_QUERY,
  UPDATE_SPOUSE_IDENTITY_MUTATION,
} from '../../../../queries/spouse_identities'
import { translateGuitaError } from '../../../../services/error_messages'

import type {
  UpdateSpouseIdentityData,
  UpdateSpouseIdentityVars,
} from '../../../../queries/spouse_identities'
import type { BaseIdentityFormValues } from 'shared/forms'
import type { SpouseIdentity } from 'shared/queries'

export type FormValues = BaseIdentityFormValues

const getInitialValues = (data?: SpouseIdentity): FormValues => ({
  ...getBaseIdentityInitialValues(data),
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    ...baseIdentityValidationSchema.fields,
  })

type InnerFormProps = FormikProps<FormValues> & {
  isPresent: boolean
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  isPresent,
}: InnerFormProps) => (
  <Form>
    <Stack spacing={3}>
      <BaseIdentityFields />
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={2}
      />
    </Stack>
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          {isPresent ? 'Modificar' : 'Crear'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type SpouseIdentityEditFormProps = {
  closeDialog: () => void
  userId: string
  legalIdentityId: string
  spouseIdentity?: SpouseIdentity
}

const SpouseIdentityEditForm = ({
  closeDialog,
  userId,
  legalIdentityId,
  spouseIdentity,
}: SpouseIdentityEditFormProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [updateSpouseIdentity] =
    useMutation<UpdateSpouseIdentityData, UpdateSpouseIdentityVars>(
      UPDATE_SPOUSE_IDENTITY_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          { query: SPOUSE_IDENTITY_QUERY, variables: { legalIdentityId } },
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateSpouseIdentity({
      variables: {
        userId,
        legalIdentityId,
        ...values,
        birthdate: toISO8601Date(values.birthdate),
      },
    })

    if (response.data?.updateSpouseIdentity === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(spouseIdentity)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            isPresent={!!spouseIdentity}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}

export default SpouseIdentityEditForm
