import { gql } from '@apollo/client'

import { USER_OPERATIONS_FIELDS } from 'shared/queries'

import type { UserOperations } from 'shared/queries'

export type UserOperationsVars = {
  userId: string
}

export type UserOperationsData = {
  userOperations: UserOperations
}

export const USER_OPERATIONS_QUERY = gql`
  ${USER_OPERATIONS_FIELDS}
  query UserOperations(
    $userId: ID!
  ) {
    userOperations(
      userId: $userId
    ) {
      ...UserOperationsFields
    }
  }
`

export type AllUserOperationsVars = {
  endDate?: string
  startDate?: string
}

export type AllUserOperationsData = {
  allUserOperations: UserOperations
}

export const ALL_USER_OPERATIONS_QUERY = gql`
  ${USER_OPERATIONS_FIELDS}
  query AllUserOperations(
    $endDate: ISO8601DateTime
    $startDate: ISO8601DateTime
  ) {
    allUserOperations(
      endDate: $endDate
      startDate: $startDate
    ) {
      ...UserOperationsFields
    }
  }
`
