import * as React from 'react'

import { useQuery } from '@apollo/client'
import {
  AllInclusive,
  AttachMoney,
  Badge,
  Business,
  BusinessCenter,
  CloudCircle,
  ElectricalServices,
  Engineering,
  Insights,
  Person,
  PriceCheck,
  Tune,
} from '@mui/icons-material'
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@mui/material'
import { Link, Redirect, Router, useLocation } from '@reach/router'

import {
  DrawerDivider,
  DrawerList,
  DrawerListItem,
  Loading,
  UserNotificationsIcon,
} from 'shared/components'
import { DomainContext } from 'shared/contexts'

import Admins from '../components/views/admins'
import AllUserNotifications from '../components/views/all_user_notifications'
import BankAccounts from '../components/views/bank_accounts'
import BulkPurchases from '../components/views/bulk_purchases'
import Conciliation from '../components/views/conciliation'
import ConciliationDetails from '../components/views/conciliation_details'
import Dashboard from '../components/views/dashboard'
import Deployments from '../components/views/deployments'
import Deposit from '../components/views/deposit'
import Documents from '../components/views/documents'
import DynamicConfig from '../components/views/dynamic_config'
import DynamicConfigs from '../components/views/dynamic_configs'
import History from '../components/views/history'
import MainBulkPurchases from '../components/views/main_bulk_purchases'
import MainFunds from '../components/views/main_funds'
import MainFundsDetails from '../components/views/main_funds_details'
import MainPortfolio from '../components/views/main_portfolio'
import MainPortfolioDetails from '../components/views/main_portfolio_details'
import NotFound from '../components/views/not_found'
import Portfolio from '../components/views/portfolio'
import PortfolioDetails from '../components/views/portfolio_details'
import Security from '../components/views/security'
import Transactions from '../components/views/transactions'
import User from '../components/views/user'
import UserNotifications from '../components/views/user_notifications'
import Users from '../components/views/users'
import Verification from '../components/views/verification'
import Verifications from '../components/views/verifications'
import Layout from '../layouts/app'
import { ADMIN_QUERY } from '../queries/admins'
import { DOMAIN_QUERY } from '../queries/domains'
import { USER_NOTIFICATIONS_QUERY } from '../queries/user_notifications'
import { canViewDeployments } from '../services/admin_roles'
import { isLoggedIn } from '../services/auth'
import { hasFunds, isManagedDomain } from '../services/managed_domain'

import type { AdminData, AdminVars } from '../queries/admins'
import type { DomainData, DomainVars } from '../queries/domains'
import type { UserNotificationsData, UserNotificationsVars } from '../queries/user_notifications'

const OrderApproveIcon = () => (
  <SvgIcon viewBox='0 96 960 960'>
    {/* eslint-disable-next-line max-len */}
    <path d='m704 917 139-138-42-42-97 95-39-39-42 43 81 81ZM240 436h480v-60H240v60Zm492.5 579q-77.5 0-132.5-55.5T545 828q0-78.435 54.99-133.718Q654.98 639 733 639q77 0 132.5 55.282Q921 749.565 921 828q0 76-55.5 131.5t-133 55.5ZM120 975V276q0-24.75 17.625-42.375T180 216h600q24.75 0 42.375 17.625T840 276v327q-14.169-6.857-29.085-11.429Q796 587 780 584V276H180v599h310q2.885 18.172 8.942 34.586Q505 926 513 942l-33 33-60-60-60 60-60-60-60 60-60-60-60 60Zm120-199h252.272q3.728-16 8.228-31t12.5-29H240v60Zm0-170h384q22-11 46-17.5t50-8.5v-34H240v60Zm-60 269V276v599Z' />
  </SvgIcon>
)

const App = () => {
  const { loading: adminLoading, data: adminData } =
    useQuery<AdminVars, AdminData>(ADMIN_QUERY)
  const adminRole = adminData?.admin.role

  const { loading: domainLoading, data: domainData } =
    useQuery<DomainData, DomainVars>(DOMAIN_QUERY)
  const quoteSymbol = domainData?.domain.quoteSymbol
  const conversionCurrency = domainData?.domain.conversionCurrency

  const { loading: notificationsLoading, data: notificationsData } =
    useQuery<UserNotificationsData, UserNotificationsVars>(
      USER_NOTIFICATIONS_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {
          first: 25,
          filter: '',
          state: 'PENDING',
        },
      })

  if (!isLoggedIn() && typeof window !== 'undefined') {
    window.location.href = '/auth/login/'
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = useLocation().pathname.replace(/\/$/, '')
  const basepath = '/app'

  const isCurrentPath = (href: string): boolean => pathname.startsWith(basepath + '/' + href)

  const ApiLink = () => (
    <ListItemButton
      href={'https://docs.guita.cl'}
      rel='noopener noreferrer'
      target='_blank'
      sx={{ borderRadius: 1, color: 'text.primary' }}
    >
      <ListItemIcon sx={{ color: 'text.primary' }}>
        <ElectricalServices />
      </ListItemIcon>
      <ListItemText primary='API' />
    </ListItemButton>
  )

  const listItem = (text: string, href: string, icon: React.ReactNode, color?: string) => (
    <DrawerListItem
      text={text}
      href={href}
      icon={icon}
      color={color}
      selected={isCurrentPath(href)}
      component={Link}
    />
  )

  const drawerContent = adminLoading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <DrawerList>
        {listItem('Reportería', 'dashboard', <Insights />)}
        {isManagedDomain('Fugiro') ? (
          <React.Fragment>
            {listItem('Órdenes', 'bulk-purchases', <AttachMoney />)}
            {listItem('Conciliación', 'conciliation', <AllInclusive />)}
          </React.Fragment>
        ) : (
          listItem('Portafolios', 'portfolio', <BusinessCenter />)
        )}
        {hasFunds() && listItem('Fondos', 'funds', <Business />)}
        {listItem('Transacciones', 'transactions', <OrderApproveIcon />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Usuarios', 'users', <Person />)}
        {listItem('Verificaciones', 'verifications', <Badge />)}
        {listItem('Notificaciones', 'notifications', (
          <UserNotificationsIcon
            loading={notificationsLoading}
            userNotifications={notificationsData?.userNotifications.edges.map(({ node }) => node)}
          />
        ))}
        {listItem('Anti Money-Laundering', 'aml', <PriceCheck />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Admins', 'admins', <Engineering />)}
        {listItem('Plataforma', 'dynamic-configs', <Tune />)}
        {canViewDeployments(adminRole) &&
          listItem('Deployments', 'deployments', <CloudCircle />)
        }
        <ApiLink />
      </DrawerList>
    </React.Fragment>
  )

  return domainLoading ? (
    <Loading />
  ) : (
    <DomainContext.Provider value={{ quoteSymbol, conversionCurrency }}>
      <Router basepath={basepath}>
        <Layout
          path='/'
          drawerContent={drawerContent}
        >
          <Redirect
            noThrow
            from='/'
            to='users'
          />
          <MainPortfolio path='portfolio' />
          <MainPortfolioDetails path='portfolio/:symbol' />
          <MainFunds path='funds' />
          <MainFundsDetails path='funds/:id' />
          <MainBulkPurchases path='bulk-purchases' />
          <Conciliation path='conciliation' />
          <ConciliationDetails path='conciliation/:supplier' />
          <Security path='security' />
          <Users path='users' />
          <User path='users/:userId'>
            <Portfolio path='portfolio' />
            <PortfolioDetails path='portfolio/:symbol' />
            <History
              path='history'
              adminRole={adminRole}
            />
            <Deposit path='deposit' />
            <BulkPurchases
              path='bulk-purchases'
              adminRole={adminRole}
            />
            <Verification path='verification' />
            <BankAccounts path='bank' />
            <Documents path='documents' />
            <UserNotifications path='notifications' />
          </User>
          <Admins path='admins' />
          <Dashboard path='dashboard' />
          <Transactions path='transactions' />
          <Verifications path='verifications' />
          <AllUserNotifications path='notifications' />
          <DynamicConfigs path='dynamic-configs' />
          <DynamicConfig path='dynamic-configs/:configId' />
          <Deployments path='deployments' />
          <NotFound default />
        </Layout>
      </Router>
    </DomainContext.Provider>
  )
}

export default App
