import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Add } from '@mui/icons-material'
import {
  Button,
  Fab,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Currency,
  Dialog,
  ErrorDisplay,
  Loading,
} from 'shared/components'
import { CurrencyField, SelectField } from 'shared/forms'
import {
  isValidAssetAmount,
  setFormError,
} from 'shared/services'

import UserSelectField from './user_select_field'
import {
  ALL_BULK_PURCHASES_OVERVIEW_QUERY,
  BULK_PURCHASES_CONCILIATION_QUERY,
  BULK_PURCHASES_OVERVIEW_QUERY,
  CREATE_BULK_PURCHASE_MUTATION,
  SUPPLIER_LABELS,
} from '../queries/bulk_purchases'
import { USER_QUERY, User } from '../queries/users'
import { translateGuitaError } from '../services/error_messages'

import type { CreateBulkPurchaseData, CreateBulkPurchaseVars } from '../queries/bulk_purchases'
import type { UserData, UserVars } from '../queries/users'
import type { FormikProps } from 'formik'

type FormValues = {
  user: User | null
  amount: number
  purchasePrice: number
  supplier: string
  supplierPrice?: number
  profitSide: string
}

const initialValues = (user?: User): FormValues => ({
  user: user || null,
  amount: 0,
  purchasePrice: 0,
  supplier: '',
  supplierPrice: undefined,
  profitSide: 'OUT',
})

const validationSchema: Yup.SchemaOf<FormValues> = (
  Yup.object().shape({
    user: Yup.object()
      .shape({
        id: Yup.string().required(),
        email: Yup.string().required(),
      })
      .required('Este campo es obligatorio'),
    amount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .integer('Debes introducir un monto sin decimales'),
    purchasePrice: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .test(
        'validFormat',
        'Introduce un número con máximo 6 decimales',
        (value) => isValidAssetAmount(value),
      ),
    supplier: Yup.string()
      .required('Este campo es obligatorio'),
    supplierPrice: Yup.number()
      .when('supplier', {
        is: (value: string) => value !== 'BLANC',
        then: Yup.number()
          .typeError('Debes ingresar un número')
          .required('Este campo es obligatorio')
          .positive('Debes ingresar un monto mayor a cero')
          .test(
            'validFormat',
            'Introduce un número con máximo 6 decimales',
            (value) => isValidAssetAmount(value),
          )
          .test(
            'smallerThanPurchasePrice',
            'Debes introducir un monto menor al precio de venta',
            (value, context) => {
              if (!value) {
                return true
              }

              return value < context.parent.purchasePrice
            },
          ),
      }),
    profitSide: Yup.string()
      .oneOf(['IN', 'OUT'], 'Debes seleccionar una opción')
      .required('Debes seleccionar una respuesta'),
  })
)

const clientTotal = (values: FormValues) => {
  if (values.profitSide === 'IN') {
    return values.amount * values.purchasePrice
  }

  return (values.amount > 0 && values.purchasePrice > 0)
    ? Math.floor(1e6 * values.amount / values.purchasePrice) / 1e6
    : 0
}

type InnerFormProps = FormikProps<FormValues> & {
  showUserSelect: boolean
  closeDialog: () => void
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  setFieldValue,
  submitForm,
  values,
  showUserSelect,
  closeDialog,
}: InnerFormProps) => (
  <Form>
    <Stack spacing={3}>
      {showUserSelect && (
        <UserSelectField
          label='Usuario'
          name='user'
        />
      )}
      <SelectField
        name='profitSide'
        label='Cliente cierra total en'
      >
        <MenuItem value='OUT'>CLP</MenuItem>
        <MenuItem value='IN'>USDT</MenuItem>
      </SelectField>
      <CurrencyField
        name='amount'
        label='Total a comprar'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <small>{values.profitSide === 'OUT' ? 'CLP' : 'USDT'}</small>&nbsp;$
            </InputAdornment>
          ),
        }}
        digits={0}
        positive
      />
      <CurrencyField
        name='purchasePrice'
        label='Precio de venta USDT'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <small>CLP</small>&nbsp;$
            </InputAdornment>
          ),
        }}
        digits={2}
        positive
      />
      <SelectField
        name='supplier'
        label='Proveedor'
        onChange={(newValue) => {
          setFieldValue('supplierPrice', (newValue === 'BLANC') ? undefined : 0)
        }}
      >
        {[['BLANC', 'Compra en blanco'], ...Object.entries(SUPPLIER_LABELS)]
          .map(([supplierType, supplierLabel]) => (
            <MenuItem
              key={supplierType}
              value={supplierType}
            >
              {supplierLabel}
            </MenuItem>
          ))}
      </SelectField>
      {values.supplier && values.supplier !== 'BLANC' && (
        <CurrencyField
          name='supplierPrice'
          label='Precio de compra USDT'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>CLP</small>&nbsp;$
              </InputAdornment>
            ),
          }}
          digits={2}
          positive
        />
      )}
      <Typography
        mt={2}
        textAlign='right'
      >
        Total cliente:
        {' '}
        <Currency
          currency={values.profitSide === 'OUT' ? 'USDT' : 'CLP'}
          digits={values.profitSide === 'OUT' ? 2 : 0}
          value={clientTotal(values)}
        />
      </Typography>
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={3}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={closeDialog}
          variant='outlined'
          color='secondary'
        >
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          Confirmar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type BulkPurchaseCreatorProps = {
  userId?: string
}

const BulkPurchaseCreator = ({
  userId,
}: BulkPurchaseCreatorProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const { loading, data } =
    useQuery<UserData, UserVars>(USER_QUERY, {
      skip: !userId,
      variables: { userId: userId! },
    })

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const overviewQuery = userId
    ? { query: BULK_PURCHASES_OVERVIEW_QUERY, variables: { userId } }
    : { query: ALL_BULK_PURCHASES_OVERVIEW_QUERY }

  const [createBulkPurchase] = useMutation<CreateBulkPurchaseData, CreateBulkPurchaseVars>(
    CREATE_BULK_PURCHASE_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [overviewQuery, BULK_PURCHASES_CONCILIATION_QUERY],
    })

  const handleSubmit = async ({ user, ...values }: FormValues) => {
    if (!user?.id) {
      return
    }

    const response = await createBulkPurchase({
      variables: {
        userId: user.id,
        ...values,
      },
    })

    if (response.data?.createBulkPurchase === 'OK!') {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Crear una orden de compra'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Crear orden de compra'
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <Formik
            innerRef={formRef}
            initialValues={initialValues(data?.user)}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <InnerForm
                {...props}
                showUserSelect={!userId}
                closeDialog={closeDialog}
              />
            )}
          </Formik>
        </LocalizationProvider>
      </Dialog>
    </React.Fragment>
  )
}

export default BulkPurchaseCreator
