import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Autocomplete, Box, Collapse, Grid, Stack } from '@mui/material'

import {
  AddressDisplay,
  AppContainer,
  AssetBadge,
  AutocompleteContainer,
  MarketAssetAdornment,
  OptionDisplay,
  OptionTextField,
  SeoHeaders,
} from 'shared/components'
import { MARKET_ASSETS_QUERY } from 'shared/queries'

import { DEPOSIT_ADDRESSES_QUERY } from '../../queries/deposit_addresses'
import AddressCreator from '../address_creator'

import type {
  DepositAddressesData,
  DepositAddressesVars,
} from '../../queries/deposit_addresses'
import type { RouteComponentProps } from '@reach/router'
import type {
  DepositAddress,
  MarketAsset,
  MarketAssetsData,
  MarketAssetsVars,
} from 'shared/queries'

type AddressInfoProps = {
  marketAsset: MarketAsset | null
  blockchain: string | null
  depositAddresses: DepositAddress[]
  userId: string
}

const AddressInfo = ({
  marketAsset,
  blockchain,
  depositAddresses,
  userId,
}: AddressInfoProps) => {
  const shouldDisplay = (marketAsset && blockchain)
  const existingAddress = shouldDisplay
    ? depositAddresses.find((address) => address.blockchain === blockchain)
    : null

  return (
    <AppContainer sx={shouldDisplay ? { px: 3, py: 2 } : { p: 0 }}>
      <Collapse in={Boolean(shouldDisplay)}>
        {shouldDisplay && (
          <Stack
            spacing={2}
            pb={1}
          >
            <AssetBadge
              animated
              symbol={marketAsset.symbol}
              height={100}
            />
            {existingAddress ? (
              <AddressDisplay
                blockchain={blockchain}
                address={existingAddress}
              />
            ) : (
              <AddressCreator
                userId={userId}
                blockchain={blockchain}
              />
            )}
          </Stack>
        )}
      </Collapse>
    </AppContainer>
  )
}

type DepositContentProps = {
  userId: string
}

const DepositContent = ({
  userId,
}: DepositContentProps) => {
  const [marketAsset, setMarketAsset] = React.useState<MarketAsset | null>(null)
  const [blockchain, setBlockchain] = React.useState<string | null>(null)

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: addressesLoading, data: addressesData } =
    useQuery<DepositAddressesData, DepositAddressesVars>(DEPOSIT_ADDRESSES_QUERY, {
      variables: {
        userId,
      },
    })

  const marketAssets = assetsData
    ? assetsData.marketAssets.filter((asset) => asset.blockchains.length > 0)
    : []

  const depositAddresses = addressesData?.depositAddresses || []

  return (
    <Grid
      container
      spacing={3}
    >
      <AutocompleteContainer>
        <Autocomplete
          disabled={assetsLoading || addressesLoading}
          options={marketAssets.filter((asset) => asset.blockchains.length > 0)}
          getOptionLabel={(option) => option.name}
          value={marketAsset}
          onChange={(_event, newValue) => {
            setMarketAsset(newValue)
            const availableBlockchains = newValue?.blockchains

            if (availableBlockchains?.length == 1) {
              setBlockchain(availableBlockchains[0])
            } else {
              setBlockchain(null)
            }
          }}
          renderOption={(props, option) => (
            <OptionDisplay {...props}>
              <AssetBadge
                symbol={option.symbol}
                height={20}
              />
              {option.name}
            </OptionDisplay>
          )}
          renderInput={(params) => (
            <OptionTextField
              startAdornment={<MarketAssetAdornment symbol={marketAsset?.symbol} />}
              name='marketAsset'
              label='Selecciona un activo'
              params={params}
            />
          )}
          disablePortal
        />
      </AutocompleteContainer>
      <AutocompleteContainer>
        <Autocomplete
          disabled={!marketAsset || marketAsset.blockchains.length < 2}
          options={marketAsset?.blockchains || []}
          value={blockchain}
          onChange={(_event, newValue) => setBlockchain(newValue)}
          renderInput={(params) => (
            <OptionTextField
              name='networkName'
              label='Selecciona una red'
              params={params}
            />
          )}
          disablePortal
        />
      </AutocompleteContainer>
      <AddressInfo
        marketAsset={marketAsset}
        blockchain={blockchain}
        depositAddresses={depositAddresses}
        userId={userId}
      />
    </Grid>
  )
}

type DepositProps = RouteComponentProps & {
  userId?: string
}

const Deposit = (props: DepositProps) => {
  const userId = props.userId || ''

  return (
    <React.Fragment>
      <SeoHeaders title={`Depositar - ${userId}`} />
      <Box sx={{ maxWidth: 'md', mx: 'auto' }}>
        <DepositContent userId={userId} />
      </Box>
    </React.Fragment>
  )
}

export default Deposit
