import * as React from 'react'

import { List, ListItem, ListItemText, Skeleton } from '@mui/material'

import { AppContainer } from 'shared/components'

import type { GridSize } from '@mui/material'

type LoadingProps = {
  md?: boolean | GridSize
}

export const Loading = ({ md }: LoadingProps) => (
  <AppContainer
    md={md}
    sx={{ height: '100%' }}
  >
    <List
      aria-label='Dirección'
      sx={{ p: 1 }}
    >
      <ListItem divider>
        <ListItemText
          primaryTypographyProps={{ variant: 'h5' }}
          primary={<Skeleton />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={<Skeleton />}
          secondary={<Skeleton />}
        />
      </ListItem>
    </List>
  </AppContainer>
)

export default Loading
