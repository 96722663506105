import { gql } from '@apollo/client'

export type CreateWithdrawalVars = {
  userId: string
  address?: string
  blockchain: string
  changedAmount: string
  symbol: string
  timestamp: string
  notifyUser: boolean
  useMaxAmount: boolean
}

export type CreateWithdrawalData = {
  createWithdrawal: string
}

export const CREATE_WITHDRAWAL_MUTATION = gql`
  mutation CreateWithdrawal(
    $userId: ID!
    $address: String
    $blockchain: String!
    $changedAmount: String!
    $symbol: String!
    $timestamp: String!
    $notifyUser: Boolean!
    $useMaxAmount: Boolean!
  ) {
    createWithdrawal(
      input: {
        userId: $userId
        address: $address
        blockchain: $blockchain
        changedAmount: $changedAmount
        symbol: $symbol
        timestamp: $timestamp
        notifyUser: $notifyUser
        useMaxAmount: $useMaxAmount
      }
    )
  }
`
