import { gql } from '@apollo/client'

export type UpdateFundsOriginVars = {
  userId: string
  descriptions: string[]
  origins: string[]
}

export type UpdateFundsOriginData = {
  updateFundsOrigin: string
}

export const UPDATE_FUNDS_ORIGIN_MUTATION = gql`
  mutation UpdateFundsOrigin(
    $userId: ID!
    $descriptions: [String!]!
    $origins: [String!]!
  ) {
    updateFundsOrigin(
      input: {
        userId: $userId
        descriptions: $descriptions
        origins: $origins
      }
    )
  }
`
