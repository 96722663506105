import * as React from 'react'

import { useMutation } from '@apollo/client'
import { CreditCardOff } from '@mui/icons-material'
import {
  Button,
  Fab,
  FormControl,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Currency,
  Dialog,
  ErrorDisplay,
} from 'shared/components'
import { SelectField } from 'shared/forms'
import { BankDeposit } from 'shared/queries'
import { setFormError } from 'shared/services'

import { DELETE_BANK_DEPOSIT_MUTATION } from '../queries/bank_deposits'
import { BULK_PURCHASES_OVERVIEW_QUERY } from '../queries/bulk_purchases'
import { translateGuitaError } from '../services/error_messages'

import type { DeleteBankDepositData, DeleteBankDepositVars } from '../queries/bank_deposits'
import type { FormikProps } from 'formik'

type FormValues = {
  bankDepositId: string
}

const initialValues: FormValues = ({
  bankDepositId: '',
})

const validationSchema = (bankDeposits: BankDeposit[]): Yup.SchemaOf<FormValues> => (
  Yup.object().shape({
    bankDepositId: Yup.string()
      .oneOf(bankDeposits.map(({ id }) => id))
      .required('Este campo es obligatorio'),
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  bankDeposits: BankDeposit[]
}

export const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  values,
  bankDeposits,
}: InnerFormProps) => {
  const bankDeposit = bankDeposits.find(({ id }) => id === values.bankDepositId)

  return (
    <Form>
      <Stack spacing={3}>
        <FormControl fullWidth>
          <SelectField
            name='bankDepositId'
            label='Depósito bancario'
          >
            {bankDeposits.sort((a, b) => b.timestamp - a.timestamp).map((bankDeposit) => (
              <MenuItem
                key={bankDeposit.id}
                value={bankDeposit.id}
              >
                {new Date(bankDeposit.timestamp).toLocaleString()}
                {' / '}
                <Currency
                  currency='CLP'
                  digits={0}
                  value={bankDeposit.amount}
                />
              </MenuItem>
            ))}
          </SelectField>
        </FormControl>
        <Typography textAlign='right'>
          Saldo a crédito:
          {' '}
          <Currency
            currency='CLP'
            digits={0}
            value={bankDeposit?.amountLeft}
          />
        </Typography>
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={12}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            color='error'
          >
            Confirmar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type BankDepositDeleterProps = {
  userId: string
  bankDeposits: BankDeposit[]
}

const BankDepositDeleter = ({
  userId,
  bankDeposits,
}: BankDepositDeleterProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const [deleteBankDeposit] =
    useMutation<DeleteBankDepositData, DeleteBankDepositVars>(
      DELETE_BANK_DEPOSIT_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          { query: BULK_PURCHASES_OVERVIEW_QUERY, variables: { userId } },
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    const response = await deleteBankDeposit({ variables: values })

    if (response.data?.deleteBankDeposit === 'OK!') {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  return (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Eliminar depósito bancario'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(28),
        })}
      >
        <CreditCardOff />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Eliminar depósito bancario'
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema(bankDeposits)}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <InnerForm
              {...props}
              bankDeposits={bankDeposits}
            />
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  )
}

export default BankDepositDeleter
