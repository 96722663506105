import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, Grid, TextField, Typography } from '@mui/material'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay } from 'shared/components'

import { USER_QUERY } from '../../../queries/users'
import {
  UPDATE_VERIFICATION_MUTATION,
  colorState,
  translateState,
} from '../../../queries/verifications'
import { translateGuitaError } from '../../../services/error_messages'

import type { DetailedUser } from '../../../queries/users'
import type {
  UpdateVerificationData,
  UpdateVerificationVars,
  VerificationAction,
  VerificationState,
} from '../../../queries/verifications'
import type { ButtonProps } from '@mui/material'

type ColoredStateProps = {
  state?: VerificationState
}

const ColoredState = ({ state }: ColoredStateProps) => (
  <Typography
    color={colorState(state)}
    component='span'
  >
    {translateState(state)}
  </Typography>
)

type ActionButtonProps = Omit<ButtonProps, 'fullWidth' | 'variant' | 'size'>

const ActionButton = ({
  children,
  ...rest
}: ActionButtonProps) => (
  <Grid
    item
    xs={3}
    mb={3}
  >
    <Button
      fullWidth
      variant='contained'
      size='large'
      {...rest}
    >
      {children}
    </Button>
  </Grid>
)

type VerificationActionsProps = {
  user: DetailedUser
}

const VerificationActions = ({
  user,
}: VerificationActionsProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [updateMessage, setUpdateMessage] = React.useState('')
  const [updateAction, setUpdateAction] = React.useState<VerificationAction>('approve')
  const [updateState, setUpdateState] = React.useState<VerificationState>('approved')
  const [updating, setUpdating] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()

  const [updateVerification] =
    useMutation<UpdateVerificationData, UpdateVerificationVars>(UPDATE_VERIFICATION_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_QUERY, variables: { userId: user.id } },
      ],
    })

  const verificationState = user.verification?.state

  const handleConfirm = async () => {
    if (typeof verificationState === 'undefined') {
      return
    }

    setUpdating(true)
    const response = await updateVerification({
      variables: {
        userId: user.id,
        fromState: verificationState,
        withAction: updateAction,
        message: updateMessage,
      },
    })
    setUpdating(false)

    if (response.data?.updateVerification) {
      closeDialog()
      return
    }

    setErrorMsg(translateGuitaError(response))
  }

  const openDialog = (action: VerificationAction, state: VerificationState) => {
    setUpdateAction(action)
    setUpdateState(state)
    setDialogOpen(true)
  }

  const closeDialog = () => {
    if (updating) {
      return
    }

    setErrorMsg(null)
    setDialogOpen(false)
  }

  return (
    <React.Fragment>
      <ActionButton
        color='error'
        onClick={() => openDialog('reject', 'rejected')}
        disabled={verificationState === 'rejected'}
      >
        Rechazar
      </ActionButton>
      <ActionButton
        color='primary'
        onClick={() => openDialog('request_review', 'pending_review')}
        disabled={verificationState === 'pending_review'}
      >
        Revisar
      </ActionButton>
      <ActionButton
        color='info'
        onClick={() => openDialog('request_changes', 'pending_changes')}
        disabled={verificationState === 'pending_changes'}
      >
        Pedir modificaciones
      </ActionButton>
      <ActionButton
        color='success'
        onClick={() => openDialog('approve', 'approved')}
        disabled={verificationState !== 'pending_review'}
      >
        Verificar
      </ActionButton>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title={`Confirmar acción para ${user.nickName}`}
        contentText={(
          <React.Fragment>
            Estás a punto de modificar el estado de verificación
            de <ColoredState state={verificationState} /> a <ColoredState state={updateState} />.
          </React.Fragment>
        )}
      >
        <TextField
          fullWidth
          label='Mensaje adicional para el usuario (opcional)'
          type='text'
          margin='dense'
          variant='standard'
          value={updateMessage}
          onChange={(event) => setUpdateMessage(event.target.value)}
        />
        <ErrorDisplay errorMsg={errorMsg} />
        <ButtonsContainer mt={2}>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              onClick={closeDialog}
              disabled={updating}
            >
              Cancelar
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='error'
              variant='contained'
              onClick={handleConfirm}
              disabled={updating}
            >
              Modificar
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Dialog>
    </React.Fragment>
  )
}

export default VerificationActions
