import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material'

import { AppContainer, ErrorDisplay, SeoHeaders } from 'shared/components'

import {
  DEPLOYMENTS_QUERY,
  TRIGGER_DEPLOYMENT_MUTATION,
} from '../../queries/deployments'
import { translateGuitaError } from '../../services/error_messages'

import type {
  Deployment,
  DeploymentsData,
  DeploymentsVars,
  TriggerDeploymentData,
  TriggerDeploymentVars,
} from '../../queries/deployments'
import type { RouteComponentProps } from '@reach/router'

const isFinalState = (status: string) => ['ACTIVE', 'ERROR'].includes(status)

type DeploymentDisplayProps = {
  deployment: Deployment
}

const DeploymentDisplay = ({
  deployment,
}: DeploymentDisplayProps) => {
  const [submitting, setSubmitting] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()

  const [triggerDeployment] =
    useMutation<TriggerDeploymentData, TriggerDeploymentVars>(TRIGGER_DEPLOYMENT_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        DEPLOYMENTS_QUERY,
      ],
      variables: {
        id: deployment.id,
      },
    })

  const handleClick = async () => {
    setSubmitting(true)
    setErrorMsg(null)
    const response = await triggerDeployment()
    setSubmitting(false)

    if (response.data?.triggerDeployment) {
      return
    }

    setErrorMsg(translateGuitaError(response))
  }

  return (
    <AppContainer>
      <List>
        <ListItem
          secondaryAction={(
            <Button
              disabled={submitting || !isFinalState(deployment.status)}
              onClick={handleClick}
              variant='contained'
              color='error'
            >
              DEPLOY
            </Button>
          )}
        >
          <ListItemText
            primary={deployment.name}
            secondary={`Estado: ${deployment.status}`}
          />
        </ListItem>
        <ErrorDisplay
          errorMsg={errorMsg}
          listItem
        />
      </List>
    </AppContainer>
  )
}

const LoadingDeploymentDisplay = () => (
  <AppContainer>
    <List>
      <ListItem
        secondaryAction={(
          <Button
            disabled
            variant='contained'
          >
            Cargando
          </Button>
        )}
      >
        <ListItemText
          primary={<Skeleton sx={{ width: '50%' }} />}
          secondary={<Skeleton sx={{ width: '50%' }} />}
        />
      </ListItem>
    </List>
  </AppContainer>
)

type DeploymentsContentProps = {
  deployments: Deployment[]
  loading: boolean
}

const DeploymentsContent = ({
  deployments,
  loading,
}: DeploymentsContentProps) => {
  if (deployments.length > 0) {
    return (
      deployments.map((deployment) => (
        <DeploymentDisplay
          key={deployment.id}
          deployment={deployment}
        />
      ))
    )
  } else if (loading) {
    return (
      [...Array(2)].map((_e, i) => (
        <LoadingDeploymentDisplay key={i} />
      ))
    )
  } else {
    return (
      <AppContainer sx={{ p: 2 }}>
        <Typography align='center'>
          No cuentas con deployments en tu plataforma.
        </Typography>
      </AppContainer>
    )
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Deployments = (props: RouteComponentProps) => {
  const { loading, data } =
    useQuery<DeploymentsData, DeploymentsVars>(DEPLOYMENTS_QUERY)

  const deployments = data?.deployments || []

  return (
    <React.Fragment>
      <SeoHeaders title='Deployments' />
      <Container maxWidth='xs'>
        <Grid
          container
          spacing={3}
        >
          <DeploymentsContent
            deployments={deployments}
            loading={loading}
          />
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default Deployments
