import { gql } from '@apollo/client'

export type Deployment = {
  id: string
  name: string
  status: string
}

const DEPLOYMENT_FIELDS = gql`
  fragment DeploymentFields on Deployment {
    id
    name
    status
  }
`

export type DeploymentsVars = Record<string, never>

export type DeploymentsData = {
  deployments: Deployment[]
}

export const DEPLOYMENTS_QUERY = gql`
  ${DEPLOYMENT_FIELDS}
  query Deployments {
    deployments {
      ...DeploymentFields
    }
  }
`

export type TriggerDeploymentVars = {
  id: string
}

export type TriggerDeploymentData = {
  triggerDeployment: string
}

export const TRIGGER_DEPLOYMENT_MUTATION = gql`
  mutation TriggerDeployment(
    $id: String!
  ) {
    triggerDeployment(
      input: {
        id: $id
      }
    )
  }
`
