import * as React from 'react'

import { useMutation } from '@apollo/client'
import {
  Button,
  InputAdornment,
  MenuItem,
  Stack,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, Select, TextField } from 'formik-mui'
import { DesktopDateTimePicker } from 'formik-mui-x-date-pickers'
import * as Yup from 'yup'

import {
  AssetBadge,
  ButtonContainer,
  ButtonsContainer,
  Currency,
  ErrorDisplay,
} from 'shared/components'
import {
  getSymbol,
  isValidAssetAmount,
  setFormError,
  toISO8601DateTime,
} from 'shared/services'

import { USER_OPERATIONS_QUERY } from '../queries/user_operations'
import { CREATE_WITHDRAWAL_MUTATION } from '../queries/withdrawals'
import { translateGuitaError } from '../services/error_messages'
import { isManagedDomain } from '../services/managed_domain'

import type { CreateWithdrawalData, CreateWithdrawalVars } from '../queries/withdrawals'
import type { FormikProps } from 'formik'
import type { MarketAsset } from 'shared/queries'

const isAicTech = () => isManagedDomain('AicTech')

type FormValues = {
  address?: string
  blockchain: string
  changedAmount: number
  symbol: string
  timestamp: Date | null
  notifyUser: boolean
  useMaxAmount: boolean
}

const initialValues: FormValues = ({
  address: '',
  blockchain: isAicTech() ? 'Dummy' : '',
  changedAmount: 0,
  symbol: '',
  timestamp: null,
  notifyUser: true,
  useMaxAmount: false,
})

type TotalAmounts = {
  [symbol: string]: number
}

const validationSchema = (totalAmounts: TotalAmounts): Yup.SchemaOf<FormValues> => (
  Yup.object().shape({
    address: Yup.string(),
    blockchain: Yup.string()
      .required('Este campo es obligatorio'),
    symbol: Yup.string()
      .required('Este campo es obligatorio')
      .nullable(),
    changedAmount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .test(
        'validFormat',
        'Introduce un número con máximo 6 decimales',
        (value, context) => context.parent.useMaxAmount || isValidAssetAmount(value),
      )
      .test(
        'maxAmount',
        'Debes ingresar un monto menor o igual al balance disponible',
        (value, context) => {
          if (!value) {
            return false
          }

          const symbol = context.parent.symbol

          if (!symbol) {
            return false
          }

          const totalAmount = totalAmounts[symbol]

          if (!totalAmount) {
            return false
          }

          return value <= totalAmount
        },
      ),
    timestamp: Yup.date()
      .required('Este campo es obligatorio'),
    notifyUser: Yup.boolean()
      .required('Este campo es obligatorio'),
    useMaxAmount: Yup.boolean()
      .required('Este campo es obligatorio'),
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  cancelCreate: () => void
  marketAssets: MarketAsset[]
  totalAmounts: TotalAmounts
}

export const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  setFieldValue,
  setFieldTouched,
  values,
  cancelCreate,
  marketAssets,
  totalAmounts,
}: InnerFormProps) => {
  const { symbol, useMaxAmount } = values
  const marketAsset = marketAssets.find((asset) => asset.symbol === symbol)
  const blockchains = marketAsset?.blockchains || []

  const onSymbolChange = () => {
    setFieldValue('changedAmount', 0)
    setFieldTouched('changedAmount', false, false)
    setFieldValue('useMaxAmount', false)
    setFieldTouched('useMaxAmount', false, false)
    setFieldValue('blockchain', isAicTech() ? 'Dummy' : '')
    setFieldTouched('blockchain', false, false)
  }

  const onUseMaxAmountChange = () => {
    if (!symbol) {
      return
    }

    const newUseMaxAmount = !useMaxAmount
    setFieldValue('useMaxAmount', newUseMaxAmount)
    setFieldValue('changedAmount', newUseMaxAmount ? totalAmounts[symbol] : 0)
    setFieldTouched('changedAmount', false, false)
  }

  return (
    <Form>
      <Stack spacing={3}>
        <Field
          required
          name='symbol'
          type='text'
          label='Activo'
          onChange={onSymbolChange}
          component={Select}
        >
          {marketAssets?.map((asset) => (
            <MenuItem
              key={asset.symbol}
              value={asset.symbol}
            >
              <AssetBadge
                symbol={asset.symbol}
                height={20}
                style={{ marginRight: 10 }}
              />
              {asset.name}
            </MenuItem>
          ))}
        </Field>
        {!isAicTech() && (
          <Field
            required
            name='blockchain'
            type='text'
            label='Blockchain'
            component={Select}
            disabled={!marketAsset}
          >
            {blockchains.map((blockchain) => (
              <MenuItem
                key={blockchain}
                value={blockchain}
              >
                {blockchain}
              </MenuItem>
            ))}
          </Field>
        )}
        <Field
          required
          name='changedAmount'
          type='number'
          label='Monto a retirar'
          component={TextField}
          disabled={!marketAsset || useMaxAmount}
          inputProps={{
            autoComplete: 'off',
            inputMode: 'numeric',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>{getSymbol(symbol)}</small>
              </InputAdornment>
            ),
            onClick: (event: React.MouseEvent<HTMLInputElement>) => {
              event.currentTarget.querySelector('input')?.select()
            },
          }}
          sx={{ flexGrow: 1 }}
        />
        <Field
          component={CheckboxWithLabel}
          disabled={!marketAsset}
          onChange={onUseMaxAmountChange}
          type='checkbox'
          name='useMaxAmount'
          Label={{
            label: (
              <React.Fragment>
                Usar el máximo disponible (aprox.&nbsp;
                <Currency
                  currency={getSymbol(symbol)}
                  value={totalAmounts[symbol]}
                />)
              </React.Fragment>
            ),
          }}
        />
        <Field
          required
          name='timestamp'
          type='date'
          label='Fecha y hora de confirmación (local)'
          component={DesktopDateTimePicker}
          disabled={!marketAsset}
          views={['year', 'month', 'day']}
          inputFormat='yyyy/MM/dd HH:mm:ss'
          toolbarTitle='Fecha y hora de confirmación'
          textField={{ fullWidth: true, margin: 'normal' }}
          closeOnSelect
          disableFuture
        />
        {!isAicTech() && (
          <Field
            inputProps={{ autoComplete: 'off' }}
            name='address'
            type='text'
            label='Dirección blockchain de retiro (opcional)'
            component={TextField}
            disabled={!marketAsset}
            margin='normal'
            fullWidth
          />
        )}
        <Field
          component={CheckboxWithLabel}
          disabled={!marketAsset}
          type='checkbox'
          name='notifyUser'
          Label={{ label: 'Notificar por mail al usuario' }}
        />
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting}
            onClick={cancelCreate}
            variant='outlined'
            color='secondary'
          >
            Cancelar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            color='error'
          >
            Confirmar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type WithdrawalCreatorProps = {
  cancelCreate: () => void
  closeDialog: () => void
  marketAssets: MarketAsset[]
  totalAmounts: TotalAmounts
  userId: string
}

const WithdrawalCreator = ({
  cancelCreate,
  closeDialog,
  marketAssets,
  totalAmounts,
  userId,
}: WithdrawalCreatorProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [createWithdrawal] = useMutation<CreateWithdrawalData, CreateWithdrawalVars>(
    CREATE_WITHDRAWAL_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_OPERATIONS_QUERY, variables: { userId } },
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await createWithdrawal({
      variables: {
        userId,
        ...values,
        changedAmount: values.changedAmount.toString(),
        timestamp: toISO8601DateTime(values.timestamp),
      },
    })

    if (response.data?.createWithdrawal) {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema(totalAmounts)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            cancelCreate={cancelCreate}
            marketAssets={marketAssets}
            totalAmounts={totalAmounts}
            {...props}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}

export default WithdrawalCreator
