import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, TextField, Typography } from '@mui/material'

import { Dialog, ErrorDisplay } from 'shared/components'

import {
  CREATE_DEPOSIT_ADDRESS_MUTATION,
  DEPOSIT_ADDRESSES_QUERY,
} from '../queries/deposit_addresses'
import { translateGuitaError } from '../services/error_messages'

import type {
  CreateDepositAddressData,
  CreateDepositAddressVars,
} from '../queries/deposit_addresses'

type AddressCreatorProps = {
  userId: string
  blockchain: string
}

const AddressCreator = ({
  userId,
  blockchain,
}: AddressCreatorProps) => {
  const [value, setValue] = React.useState('')
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [generateDepositAddress, { loading }] =
    useMutation<CreateDepositAddressData, CreateDepositAddressVars>(
      CREATE_DEPOSIT_ADDRESS_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          { query: DEPOSIT_ADDRESSES_QUERY, variables: { userId } },
        ],
        variables: {
          blockchain,
          userId,
          value,
        },
      })

  const handleClick = async () => {
    const response = await generateDepositAddress()

    if (response.data?.createDepositAddress) {
      closeDialog()
      return
    }

    setErrorMsg(translateGuitaError(response))
  }

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <Typography textAlign='center'>
        Si es la primera vez que depositas a través de la&nbsp;
        <span style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
          {blockchain} network
        </span> deberás generar una dirección de depósito.
      </Typography>
      <Button
        disabled={loading}
        onClick={openDialog}
        sx={{ fontWeight: 500, py: 1.5 }}
        variant='contained'
        size='large'
      >
        GENERAR DIRECCIÓN
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Generar dirección de depósito'
        contentText={(
          <React.Fragment>
            Escribe la dirección a asignar para este usuario en
            la <span style={{ fontWeight: 'bold' }}>{blockchain}</span> network:
          </React.Fragment>
        )}
      >
        <TextField
          fullWidth
          label='Dirección de depósito'
          type='text'
          margin='dense'
          variant='standard'
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
        <ErrorDisplay errorMsg={errorMsg} />
        <Button
          onClick={handleClick}
          variant='contained'
          size='large'
          fullWidth
        >
          Generar
        </Button>
      </Dialog>
    </React.Fragment>
  )
}

export default AddressCreator
