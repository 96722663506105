import * as React from 'react'

import { useMutation } from '@apollo/client'
import { PersonAdd } from '@mui/icons-material'
import { Button } from '@mui/material'
import { Form, Formik } from 'formik'

import {
  AppContainer, ButtonContainer,
  ButtonsContainer,
  Dialog,
  ErrorDisplay,
} from 'shared/components'
import {
  UltimateBeneficialOwnerFields,
  getUltimateBeneficialOwnerInitialValues as getInitialValues,
  getUltimateBeneficialOwnerMutationVars as getMutationVariables,
  ultimateBeneficialOwnerValidationSchema as validationSchema,
} from 'shared/forms'
import { setFormError, translateError } from 'shared/services'

import {
  CREATE_ULTIMATE_BENEFICIAL_OWNER_MUTATION,
  CreateUltimateBeneficialOwnerData,
  CreateUltimateBeneficialOwnerVars,
} from '../../../../queries/ultimate_beneficial_owners'
import { USER_QUERY } from '../../../../queries/users'

import type { FormikHelpers, FormikProps } from 'formik'
import type { UltimateBeneficialOwnerFormValues as FormValues } from 'shared/forms'

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
}: FormikProps<FormValues>) => (
  <Form style={{ width: '100%' }}>
    <UltimateBeneficialOwnerFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Agregar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type UltimateBeneficialOwnerCreatorProps = {
    userId: string
  }

const UltimateBeneficialOwnerCreator = ({
  userId,
}: UltimateBeneficialOwnerCreatorProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const [createUltimateBeneficialOwner] =
    useMutation<CreateUltimateBeneficialOwnerData, CreateUltimateBeneficialOwnerVars>(
      CREATE_ULTIMATE_BENEFICIAL_OWNER_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          USER_QUERY,
        ],
      })

  const handleSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    const variables = getMutationVariables(values)
    const response = await createUltimateBeneficialOwner({
      variables: {
        userId,
        ...variables,
      },
    })

    if (response.data?.createUltimateBeneficialOwner === 'OK!') {
      closeDialog()
      resetForm()
      return
    }

    setFormError(formRef, translateError(response))
  }

  return (
    <React.Fragment>
      <AppContainer
        md={6}
        sx={{
          p: 3,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <Button
          endIcon={<PersonAdd />}
          onClick={openDialog}
          variant='contained'
          size='large'
        >
          Agregar persona natural
        </Button>
      </AppContainer>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title='Agregar persona natural'
      >
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(undefined)}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => <InnerForm {...props} />}
        </Formik>
      </Dialog>
    </React.Fragment>
  )
}

export default UltimateBeneficialOwnerCreator
