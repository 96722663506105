import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Divider, Grid } from '@mui/material'

import DynamicConfigDisplay from './dynamic_config_display'
import UserDynamicConfigCreator from './user_dynamic_config_creator'
import UserDynamicConfigsTable from './user_dynamic_configs_table'
import { DYNAMIC_CONFIG_QUERY } from '../../../queries/dynamic_configs'

import type {
  DynamicConfigData,
  DynamicConfigVars,
} from '../../../queries/dynamic_configs'
import type { RouteComponentProps } from '@reach/router'

type DynamicConfigProps = RouteComponentProps & {
  configId?: string
}

const DynamicConfig = (props: DynamicConfigProps) => {
  const configId = props.configId || ''

  const { data } =
    useQuery<DynamicConfigData, DynamicConfigVars>(DYNAMIC_CONFIG_QUERY, {
      variables: {
        configId,
      },
    })

  const dynamicConfig = data?.dynamicConfig

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        mb={12}
      >
        <Grid
          item
          xs={12}
        >
          <Divider>Configuración global</Divider>
        </Grid>
        <DynamicConfigDisplay dynamicConfig={dynamicConfig} />
        <Grid
          item
          xs={12}
        >
          <Divider>Configuración de usuarios</Divider>
        </Grid>
        <UserDynamicConfigsTable
          dynamicConfig={dynamicConfig}
          rows={dynamicConfig?.userDynamicConfigs}
        />
      </Grid>
      {dynamicConfig && <UserDynamicConfigCreator dynamicConfig={dynamicConfig} />}
    </React.Fragment>
  )
}

export default DynamicConfig
