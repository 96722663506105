import * as React from 'react'

import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

import { AppContainer, Table, TableLoading } from 'shared/components'
import { localizeISO8601 } from 'shared/services'

import UserDynamicConfigDeleter from './user_dynamic_config_deleter'
import UserDynamicConfigUpdater from './user_dynamic_config_updater'
import TableEmpty from '../../table_empty'

import type { DynamicConfig, UserDynamicConfig } from '../../../queries/dynamic_configs'

type UserDynamicConfigsTableProps = {
  dynamicConfig?: DynamicConfig
  rows?: UserDynamicConfig[]
}

const UserDynamicConfigsTable = ({
  dynamicConfig,
  rows,
}: UserDynamicConfigsTableProps) => (
  <TableContainer component={AppContainer}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Usuario</TableCell>
          <TableCell>Valor</TableCell>
          <TableCell>Modificada</TableCell>
          <TableCell>Acciones</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(typeof dynamicConfig === 'undefined' || typeof rows === 'undefined') ? (
          <TableLoading
            rows={3}
            columns={6}
          />
        ) : (
          rows.length === 0 ? (
            <TableEmpty columns={6} />
          ) : (
            rows.map((userDynamicConfig, index) => (
              <TableRow key={index}>
                <TableCell>{userDynamicConfig.id}</TableCell>
                <TableCell>ID: {userDynamicConfig.user.id} / {userDynamicConfig.user.email}</TableCell>
                <TableCell>
                  {dynamicConfig.valueType === 'date' ?
                    localizeISO8601(userDynamicConfig.value) : userDynamicConfig.value}
                </TableCell>
                <TableCell>{localizeISO8601(userDynamicConfig.updatedAt)}</TableCell>
                <TableCell sx={{ px: 1, py: 0, textAlign: 'center' }}>
                  <UserDynamicConfigUpdater
                    dynamicConfig={dynamicConfig}
                    userDynamicConfig={userDynamicConfig}
                  />
                  <UserDynamicConfigDeleter
                    dynamicConfig={dynamicConfig}
                    userDynamicConfig={userDynamicConfig}
                  />
                </TableCell>
              </TableRow>
            ))
          )
        )}
      </TableBody>
    </Table>
  </TableContainer>
)

export default UserDynamicConfigsTable
