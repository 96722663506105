import * as React from 'react'

import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { AppContainer, CurrencyCell, Table, TokenCell } from 'shared/components'
import { FUND_ASSET_LABELS } from 'shared/queries'

import { FundDetails } from '../queries/funds'
import {
  getCurrentAssetAmount,
  getCurrentAssetPercentage,
  getCurrentAssetRebalanceAmount,
} from '../services/funds'

type FundDetailsTableProps = {
  fund: FundDetails
}

export const FundDetailsTable = ({
  fund,
}: FundDetailsTableProps) => (
  <TableContainer component={AppContainer}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Activo</TableCell>
          <TableCell>Cantidad</TableCell>
          <TableCell>Porcentaje real</TableCell>
          <TableCell>Rebalanceo aprox</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fund.currentAssets.map((asset, index) => (
          <TableRow
            hover
            key={index}
          >
            <TokenCell
              name={FUND_ASSET_LABELS[asset]}
              symbol={asset}
            />
            <CurrencyCell
              currency={asset}
              digits={6}
              value={getCurrentAssetAmount(fund, asset)}
            />
            <CurrencyCell
              currency='%'
              digits={2}
              value={getCurrentAssetPercentage(fund, asset)}
            />
            <CurrencyCell
              currency={asset}
              digits={6}
              value={getCurrentAssetRebalanceAmount(fund, asset)}
            />
          </TableRow>
        ),
        )}
      </TableBody>
    </Table>
  </TableContainer>
)
