import * as React from 'react'

import { ListItem, ListItemText } from '@mui/material'

import { findCountryByCode, findRegionByCode } from 'shared/services'

import LegalAddressEditForm from './legal_address_edit_form'
import ListDisplay from './list_display'

import type { LegalAddress } from 'shared/queries'

type LegalAddressDetailsDisplayProps = {
  legalAddress: LegalAddress
}

const LegalAddressDetailsDisplay = ({
  legalAddress,
}: LegalAddressDetailsDisplayProps) => {
  const country = findCountryByCode(legalAddress.countryCode)
  const region = findRegionByCode(legalAddress.regionCode, country)

  return (
    <React.Fragment>
      <ListItem divider>
        <ListItemText
          primary='País'
          secondary={country?.name}
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary='Región/Estado'
          secondary={region?.name}
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary='Ciudad'
          secondary={legalAddress.city || '<Sin información>'}
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary='Calle, número, departamento, etc.'
          secondary={legalAddress.streetAddress}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary='Código Postal'
          secondary={legalAddress.postalCode || '<Sin información>'}
        />
      </ListItem>
    </React.Fragment>
  )
}

type LegalAddressDisplayProps = {
  userId: string
  legalAddress?: LegalAddress
}

const LegalAddressDisplay = ({
  userId,
  legalAddress,
}: LegalAddressDisplayProps) => (
  <ListDisplay
    title='Dirección'
    item={legalAddress}
    editForm={(closeDialog) => (
      <LegalAddressEditForm
        closeDialog={closeDialog}
        userId={userId}
        legalAddress={legalAddress}
      />
    )}
  >
    {(item) => <LegalAddressDetailsDisplay legalAddress={item} />}
  </ListDisplay>
)

export default LegalAddressDisplay
