import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Add } from '@mui/icons-material'
import {
  Button,
  Collapse,
  Fab,
  MenuItem,
  Paper,
  Stack,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik, FormikProps } from 'formik'
import { CheckboxWithLabel, Select, TextField } from 'formik-mui'
import { DesktopDateTimePicker } from 'formik-mui-x-date-pickers'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Dialog,
  DocumentUpload,
  ErrorDisplay,
} from 'shared/components'
import { USER_DOCUMENT_CONTENT_TYPE_LABELS } from 'shared/queries'
import {
  hasValidSize,
  setFormError,
  toISO8601DateTime,
  uploadToStorage,
} from 'shared/services'

import {
  CREATE_USER_DOCUMENT_MUTATION,
  UPDATE_USER_DOCUMENT_MUTATION,
  USER_DOCUMENTS_QUERY,
  USER_DOCUMENT_TYPE_LABELS,
} from '../queries/user_documents'
import { translateGuitaError } from '../services/error_messages'
import { isManagedDomain } from '../services/managed_domain'

import type {
  CreateUserDocumentData,
  CreateUserDocumentVars,
  UpdateUserDocumentData,
  UpdateUserDocumentVars,
  UserDocumentType,
} from '../queries/user_documents'

const CURRENCY_TYPE_LABELS = ['CLP', 'USD', 'USDT']

const isFugiro = () => isManagedDomain('Fugiro')

export const hasValidContentType = (file: File | undefined) => (
  !file || Object.keys(USER_DOCUMENT_CONTENT_TYPE_LABELS).includes(file.type)
)

const getDescription = (values: FormValues) => {
  if (values.documentType === 'OPERATIONAL' && isFugiro()) {
    const { fromAmount, fromCurrency, toAmount, toCurrency, description } = values
    return `Remesa de ${fromAmount} ${fromCurrency} hacia ${toAmount} ${toCurrency}: ${description}`
  }

  return values.description
}

type FormValues = {
  documentType: UserDocumentType
  timestamp: Date | null
  description: string
  notifyUser: boolean
  selectedFile?: File
  fromAmount?: number
  fromCurrency?: string
  toAmount?: number
  toCurrency?: string
}

const initialValues: FormValues = {
  documentType: 'LEGAL',
  timestamp: null,
  description: '',
  notifyUser: true,
  fromAmount: 0,
  fromCurrency: 'CLP',
  toAmount: 0,
  toCurrency: 'USDT',
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  documentType: Yup.mixed()
    .required('Este campo es obligatorio'),
  timestamp: Yup.date()
    .required('Este campo es obligatorio'),
  description: Yup.string()
    .required('Este campo es obligatorio'),
  notifyUser: Yup.boolean()
    .required('Este campo es obligatorio'),
  selectedFile: Yup.mixed()
    .required('Debes adjuntar un archivo')
    .test('fileType', 'Debes subir un archivo PDF', hasValidContentType)
    .test('fileSize', 'Tu archivo supera el tamaño máximo de 16 MB', hasValidSize),
  fromAmount: isFugiro()
    ? Yup.number()
      .when('documentType', {
        is: 'OPERATIONAL',
        then: Yup.number()
          .required('Este campo es obligatorio')
          .positive('El monto debe ser mayor a cero'),
      })
    : Yup.number(),
  fromCurrency: isFugiro()
    ? Yup.string()
      .when('documentType', {
        is: 'OPERATIONAL',
        then: Yup.string().required('Este campo es obligatorio'),
      })
    : Yup.string(),
  toAmount: isFugiro()
    ? Yup.number()
      .when('documentType', {
        is: 'OPERATIONAL',
        then: Yup.number()
          .required('Este campo es obligatorio')
          .positive('El monto debe ser mayor a cero'),
      })
    : Yup.number(),
  toCurrency: isFugiro()
    ? Yup.string()
      .when('documentType', {
        is: 'OPERATIONAL',
        then: Yup.string()
          .required('Este campo es obligatorio')
          .test({
            message: 'Moneda no puede ser ${value}',
            test: (toCurrency, context) => toCurrency !== context.parent.fromCurrency,
          }),
      })
    : Yup.string(),
})

const DocumentCreatorForm = ({
  isSubmitting,
  isValid,
  setFieldValue,
  status,
  submitForm,
  values,
}: FormikProps<FormValues>) => (
  <Form style={{ width: '100%', marginTop: '10px' }}>
    <Stack
      spacing={3}
      width='100%'
    >
      <Field
        required
        name='documentType'
        type='text'
        label='Tipo de documento'
        component={Select}
        onChange={(newValue: React.ChangeEvent<HTMLInputElement>) => (
          newValue.target.value === 'ADMINISTRATIVE' && setFieldValue('notifyUser', false)
        )}
        fullWidth
      >
        {Object.entries(USER_DOCUMENT_TYPE_LABELS).map(([type, label]) => (
          <MenuItem
            key={type}
            value={type}
          >
            {label}
          </MenuItem>
        ))}
      </Field>
      {isFugiro() && (
        <Collapse in={values.documentType === 'OPERATIONAL'}>
          <Paper
            variant='outlined'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: 2,
              mb: 1,
            }}
          >
            <Stack
              direction='row'
              width='100%'
              spacing={1}
            >
              <Field
                required
                name='fromAmount'
                type='number'
                label='Monto de origen'
                component={TextField}
                sx={{ flexGrow: 1 }}
              />
              <Field
                required
                component={Select}
                name='fromCurrency'
                label='Moneda'
                sx={{ minWidth: '105px' }}
              >
                {CURRENCY_TYPE_LABELS.map((currency) => (
                  <MenuItem
                    key={currency}
                    value={currency}
                  >
                    {currency}
                  </MenuItem>
                ))}
              </Field>
            </Stack>
            <Stack
              direction='row'
              width='100%'
              spacing={1}
            >
              <Field
                required
                name='toAmount'
                type='number'
                label='Monto de destino'
                component={TextField}
                sx={{ flexGrow: 1 }}
              />
              <Field
                required
                component={Select}
                name='toCurrency'
                label='Moneda'
                sx={{ minWidth: '105px' }}
              >
                {CURRENCY_TYPE_LABELS.map((currency) => (
                  <MenuItem
                    key={currency}
                    value={currency}
                  >
                    {currency}
                  </MenuItem>
                ))}
              </Field>
            </Stack>
          </Paper>
        </Collapse>
      )}
      <Field
        required
        name='timestamp'
        type='date'
        label='Fecha de emisión'
        component={DesktopDateTimePicker}
        views={['year', 'month', 'day']}
        inputFormat='yyyy/MM/dd HH:mm:ss'
        toolbarTitle='Fecha de emisión'
        disableFuture
        closeOnSelect
        fullWidth
      />
      <Field
        required
        name='description'
        type='text'
        label='Descripcion del documento'
        component={TextField}
        margin='normal'
        fullWidth
      />
      <DocumentUpload
        name='selectedFile'
        imageAlt='Documento del usuario'
        contentTypes={Object.keys(USER_DOCUMENT_CONTENT_TYPE_LABELS)}
      />
      <Field
        required
        component={CheckboxWithLabel}
        disabled={!values.selectedFile || values.documentType === 'ADMINISTRATIVE'}
        type='checkbox'
        name='notifyUser'
        Label={{ label: 'Notificar por mail al usuario' }}
      />
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2, justifyContent: 'center' }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          {isSubmitting ? 'Creando...' : 'Crear documento'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type UserDocumentCreatorProps = {
  userId: string
}

const UserDocumentCreator = ({
  userId,
}: UserDocumentCreatorProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const [createUserDocument] =
    useMutation<CreateUserDocumentData, CreateUserDocumentVars>(
      CREATE_USER_DOCUMENT_MUTATION, {
        errorPolicy: 'all',
      })

  const [updateUserDocument] =
    useMutation<UpdateUserDocumentData, UpdateUserDocumentVars>(
      UPDATE_USER_DOCUMENT_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          { query: USER_DOCUMENTS_QUERY, variables: { userId } },
        ],
      })

  const handleUpdate = async (userDocumentId: string) => {
    const response = await updateUserDocument({
      variables: {
        userId,
        userDocumentId,
      },
    })

    if (response.data?.updateUserDocument === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  const handleSubmit = async (values: FormValues) => {
    if (!values.selectedFile) {
      return
    }

    const response = await createUserDocument({
      variables: {
        userId,
        contentType: values.selectedFile.type,
        description: getDescription(values),
        documentType: values.documentType,
        notifyUser: values.notifyUser,
        timestamp: toISO8601DateTime(values.timestamp),
      },
    })

    const { id, storagePost } = (response.data?.createUserDocument || {})

    if (!id || !storagePost) {
      setFormError(formRef, translateGuitaError(response))
      return
    }

    const storageResponse = await uploadToStorage(storagePost, values.selectedFile)

    if (!storageResponse.ok) {
      setFormError(formRef, 'Ocurrió un error al subir el archivo.')
      return
    }

    await handleUpdate(id)
  }

  return (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Agregar un documento'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Crear documento'
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <DocumentCreatorForm
                {...props}
              />
            )}
          </Formik>
        </LocalizationProvider>
      </Dialog>
    </React.Fragment>
  )
}

export default UserDocumentCreator
