import { gql } from '@apollo/client'

export type BankWithdrawal = {
  id: string
  amount: number
  amountLeft: number
  spentAt: string | null
  supplier: string
  timestamp: number
}

export const BANK_WITHDRAWAL_FIELDS = gql`
  fragment BankWithdrawalFields on BankWithdrawal {
    id
    amount
    amountLeft
    spentAt
    supplier
    timestamp
  }
`
