import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Share } from '@mui/icons-material'
import { Autocomplete, Box, Fab, Grid, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { navigate } from '@reach/router'
import { timeFormat } from 'd3-time-format'
import esLocale from 'date-fns/locale/es'

import {
  AssetBadge,
  AutocompleteContainer,
  OperationsList,
  OptionCheckbox,
  OptionDisplay,
  OptionTextField,
  SeoHeaders,
} from 'shared/components'
import { MARKET_ASSETS_QUERY, USER_OPERATION_LABELS } from 'shared/queries'
import {
  asOperationsArray,
  downloadBlob,
  operationsToCSV,
  toISO8601DateTime,
  withSymbols,
  withTypes,
} from 'shared/services'

import { ALL_USER_OPERATIONS_QUERY } from '../../queries/user_operations'

import type {
  AllUserOperationsData,
  AllUserOperationsVars,
} from '../../queries/user_operations'
import type { RouteComponentProps } from '@reach/router'
import type { MarketAssetsData, MarketAssetsVars, UserOperation } from 'shared/queries'

type FilteredOperationsListProps = {
  endDate: Date
  startDate: Date
  symbols: string[]
  operationTypes: string[]
}

const FilteredOperationsList = ({
  endDate,
  startDate,
  symbols,
  operationTypes,
}: FilteredOperationsListProps) => {
  const { data, loading } =
    useQuery<AllUserOperationsData, AllUserOperationsVars>(
      ALL_USER_OPERATIONS_QUERY, {
        variables: {
          endDate: toISO8601DateTime(endDate),
          startDate: toISO8601DateTime(startDate),
        },
      })

  let assetOperations = asOperationsArray(data?.allUserOperations)

  if (symbols.length) {
    assetOperations = withSymbols(assetOperations, symbols)
  }

  if (operationTypes.length) {
    assetOperations = withTypes(assetOperations, operationTypes)
  }

  const handleOpClick = (op: UserOperation) => {
    navigate(`users/${op.userId}/history`)
  }

  return (
    <React.Fragment>
      <OperationsList
        loading={loading}
        operations={assetOperations}
        onClick={handleOpClick}
        withUserId
      />
      <Fab
        color='primary'
        aria-label='Descargar operaciones'
        disabled={assetOperations.length === 0}
        onClick={() => {
          const endDateStr = timeFormat('%Y_%m_%d')(endDate)
          const startDateStr = timeFormat('%Y_%m_%d')(startDate)
          const blob = operationsToCSV(assetOperations)
          const filename = `guita-operations-${startDateStr}-${endDateStr}.csv`
          downloadBlob(blob, filename)
        }}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Share />
      </Fab>
    </React.Fragment>
  )
}

const TransactionsContent = () => {
  const defaultDate = new Date()
  defaultDate.setHours(0, 0, 0, 0)

  const [endDate, setEndDate] = React.useState(defaultDate)
  const [startDate, setStartDate] = React.useState(defaultDate)
  const [symbols, setSymbols] = React.useState<string[]>([])
  const [operationTypes, setOperationTypes] = React.useState<string[]>([])

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const marketAssets = assetsData?.marketAssets || []

  return (
    <Grid
      container
      spacing={3}
    >
      <AutocompleteContainer>
        <DatePicker
          disableFuture
          value={startDate}
          inputFormat='yyyy/MM/dd'
          onChange={(newValue) => {
            const safeValue = newValue || new Date()
            safeValue.setHours(0, 0, 0, 0)
            setStartDate(safeValue)

            if (safeValue > endDate) {
              setEndDate(safeValue)
            }
          }}
          renderInput={(props) => (
            <TextField
              {...props}
              label='Filtrar por fecha inicial'
              fullWidth
            />
          )}
        />
      </AutocompleteContainer>
      <AutocompleteContainer>
        <DatePicker
          disableFuture
          value={endDate}
          inputFormat='yyyy/MM/dd'
          onChange={(newValue) => {
            const safeValue = newValue || new Date()
            safeValue.setHours(0, 0, 0, 0)
            setEndDate(safeValue)

            if (safeValue < startDate) {
              setStartDate(safeValue)
            }
          }}
          renderInput={(props) => (
            <TextField
              {...props}
              label='Filtrar por fecha final'
              fullWidth
            />
          )}
        />
      </AutocompleteContainer>
      <AutocompleteContainer>
        <Autocomplete
          disableCloseOnSelect
          multiple
          limitTags={2}
          disabled={assetsLoading}
          options={marketAssets}
          getOptionLabel={(option) => option.name}
          onChange={(_event, newValue) => {
            setSymbols(newValue.map((asset) => asset.symbol))
          }}
          renderOption={(props, option, { selected }) => (
            <OptionDisplay {...props}>
              <OptionCheckbox checked={selected} />
              <AssetBadge
                symbol={option.symbol}
                height={20}
              />
              {option.name}
            </OptionDisplay>
          )}
          renderInput={(params) => (
            <OptionTextField
              name='marketAsset'
              label='Filtrar por activo'
              params={params}
            />
          )}
          disablePortal
        />
      </AutocompleteContainer>
      <AutocompleteContainer>
        <Autocomplete
          disableCloseOnSelect
          multiple
          limitTags={2}
          options={USER_OPERATION_LABELS}
          getOptionLabel={(option) => option.label}
          onChange={(_event, newValue) => {
            setOperationTypes(newValue.map((operationLabel) => operationLabel.typename))
          }}
          renderOption={(props, option, { selected }) => (
            <OptionDisplay {...props}>
              <OptionCheckbox checked={selected} />
              {option.label}
            </OptionDisplay>
          )}
          renderInput={(params) => (
            <OptionTextField
              name='operationType'
              label='Filtrar por tipo de operación'
              params={params}
            />
          )}
          disablePortal
        />
      </AutocompleteContainer>
      <FilteredOperationsList
        endDate={endDate}
        startDate={startDate}
        symbols={symbols}
        operationTypes={operationTypes}
      />
    </Grid>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Transactions = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Transacciones' />
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Box sx={{ maxWidth: 'md', mx: 'auto' }}>
        <TransactionsContent />
      </Box>
    </LocalizationProvider>
  </React.Fragment>
)

export default Transactions
