import * as React from 'react'

import { ListItem, ListItemText } from '@mui/material'

import { EMPLOYMENT_SITUATION_LABELS } from 'shared/queries'

import OccupationProfileEditForm from './occupation_profile_edit_form'
import ListDisplay from '../list_display'

import type { OccupationProfile } from 'shared/queries'

type OccupationProfileDetailsDisplayProps = {
  occupationProfile: OccupationProfile
}

const OccupationProfileDetailsDisplay = ({
  occupationProfile,
}: OccupationProfileDetailsDisplayProps) => (
  <React.Fragment>
    <ListItem divider>
      <ListItemText
        primary='Situación laboral'
        secondary={EMPLOYMENT_SITUATION_LABELS[occupationProfile.employmentSituation]}
      />
    </ListItem>
    <ListItem divider={occupationProfile.employmentSituation === 'EMPLOYEE'}>
      <ListItemText
        primary='Profesión'
        secondary={occupationProfile.profession}
      />
    </ListItem>
    {(occupationProfile.employmentSituation === 'EMPLOYEE') && (
      <React.Fragment>
        <ListItem divider>
          <ListItemText
            primary='Empresa'
            secondary={occupationProfile.company}
          />
        </ListItem>
        <ListItem divider>
          <ListItemText
            primary='RUT de la empresa'
            secondary={occupationProfile.companyIdentificationNumber}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary='Cargo'
            secondary={occupationProfile.jobTitle}
          />
        </ListItem>
      </React.Fragment>
    )}
  </React.Fragment>
)

type OccupationProfileDisplayProps = {
  userId: string
  occupationProfile?: OccupationProfile
}

const OccupationProfileDisplay = ({
  userId,
  occupationProfile,
}: OccupationProfileDisplayProps) => (
  <ListDisplay
    title='Perfil laboral'
    item={occupationProfile}
    editForm={(closeDialog) => (
      <OccupationProfileEditForm
        closeDialog={closeDialog}
        userId={userId}
        occupationProfile={occupationProfile}
      />
    )}
  >
    {(item) => <OccupationProfileDetailsDisplay occupationProfile={item} />}
  </ListDisplay>
)

export default OccupationProfileDisplay
