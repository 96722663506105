import { gql } from '@apollo/client'

export type ManagedDomainsVars = Record<string, never>

export type ManagedDomainsData = {
  managedDomains: string[]
}

export const MANAGED_DOMAINS_QUERY = gql`
  query ManagedDomains {
    managedDomains
  }
`
