import * as React from 'react'

import { Delete, Edit, NoteAdd } from '@mui/icons-material'
import { IconButton, List, ListItem, ListItemText } from '@mui/material'

import { AppContainer, Dialog } from 'shared/components'

type FormState = 'EDIT' | 'DELETE'

type ListDisplayProps<Item> = {
  title: string
  item?: Item
  deleteForm?: (closeDialog: () => void) => React.ReactNode
  editForm?: (closeDialog: () => void) => React.ReactNode
  children: (item: Item) => React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const ListDisplay = <Item, >({
  title,
  item,
  deleteForm,
  editForm,
  children,
}: ListDisplayProps<Item>) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [dialogForm, setDialogForm] = React.useState<FormState>('EDIT')

  const form = dialogForm === 'EDIT' ? editForm : deleteForm

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const isPresent = !!item

  return (
    <React.Fragment>
      <AppContainer
        md={6}
        sx={{ height: '100%' }}
      >
        <List
          dense
          aria-label={title}
          sx={{ p: 1 }}
        >
          <ListItem
            divider
            secondaryAction={(
              <React.Fragment>
                {editForm && (
                  <IconButton
                    edge='end'
                    aria-label={isPresent ? 'Editar' : 'Crear'}
                    onClick={() => {
                      setDialogForm('EDIT')
                      openDialog()
                    }}
                  >
                    {isPresent ? <Edit /> : <NoteAdd />}
                  </IconButton>
                )}
                {isPresent && deleteForm && (
                  <IconButton
                    edge='end'
                    aria-label='Eliminar'
                    onClick={() => {
                      setDialogForm('DELETE')
                      openDialog()
                    }}
                    sx={{ ml: 1 }}
                  >
                    <Delete />
                  </IconButton>
                )}
              </React.Fragment>
            )}
          >
            <ListItemText
              primaryTypographyProps={{ variant: 'h5' }}
              primary={title}
            />
          </ListItem>
          {isPresent ? (
            children(item)
          ) : (
            <ListItem>
              <ListItemText primary='No hay datos' />
            </ListItem>
          )}
        </List>
      </AppContainer>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title={(dialogForm === 'EDIT') ? (
          isPresent ? `Modificar ${title.toLowerCase()}` : `Crear ${title.toLowerCase()}`
        ) : (
          `Eliminar ${title.toLowerCase()}`
        )}
      >
        {form && form(closeDialog)}
      </Dialog>
    </React.Fragment>
  )
}

export default ListDisplay
