import * as React from 'react'

import { FilterAlt } from '@mui/icons-material'
import { IconButton, TextField } from '@mui/material'

import { AppContainer } from 'shared/components'

import type { AppContainerProps } from 'shared/components'

const FILTER_DEBOUNCE_MS = 1000

type QueryFilterProps = AppContainerProps & {
  afterChange: (value: string) => void
  attributeName: string
}

const QueryFilter = ({
  afterChange,
  attributeName,
  ...rest
}: QueryFilterProps) => {
  const [filterInput, setFilterInput] = React.useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterInput(event.currentTarget.value)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const formProps = Object.fromEntries(formData)
    afterChange(formProps.filter.toString())
  }

  React.useEffect(() => {
    const timer = setTimeout(() => {
      afterChange(filterInput)
    }, FILTER_DEBOUNCE_MS)

    return () => {
      clearTimeout(timer)
    }
  }, [afterChange, filterInput])

  return (
    <AppContainer
      sx={{ p: 2 }}
      {...rest}
    >
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label={`Filtrar por ${attributeName}`}
          name='filter'
          value={filterInput}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <IconButton type='submit'>
                <FilterAlt />
              </IconButton>
            ),
          }}
        />
      </form>
    </AppContainer>
  )
}

export default QueryFilter
