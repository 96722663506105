import * as React from 'react'

import { List, ListItem, ListItemText } from '@mui/material'

import { AppContainer, Currency } from 'shared/components'

import {
  getSupplierPrice,
  getTotalProfit,
  getTotalSupplierIn,
  getTotalSupplierOut,
  getUnpaid,
} from '../services/conciliation'

import type { SupplierBulkPurchase } from '../queries/bulk_purchases'

type BalanceProps = {
  descriptionText: string
  currencyIn: string
  digitsIn?: number
  valueIn: number
  currencyOut: string
  digitsOut?: number
  valueOut: number
}

const Balance = ({
  descriptionText,
  currencyIn,
  digitsIn,
  valueIn,
  currencyOut,
  digitsOut,
  valueOut,
}: BalanceProps) => (
  <AppContainer
    sm={12}
    md={6}
    sx={{ bgcolor: 'grey.800' }}
  >
    <List disablePadding>
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'h5', color: 'common.white' }}
          primary={descriptionText}
        />
        <ListItemText
          primaryTypographyProps={{
            textAlign: 'right',
            variant: 'h6',
            fontWeight: 'normal',
            color: 'common.white',
          }}
          primary={(
            <Currency
              currency={currencyIn}
              digits={digitsIn}
              value={valueIn}
            />
          )}
          secondaryTypographyProps={{
            textAlign: 'right',
            variant: 'h6',
            fontWeight: 'normal',
            color: 'common.white',
          }}
          secondary={(
            <Currency
              currency={currencyOut}
              digits={digitsOut}
              value={valueOut}
            />
          )}
        />
      </ListItem>
    </List>
  </AppContainer>
)

type ConciliationBalancesProps = {
  pastUnpaid: SupplierBulkPurchase[]
  todayAll: SupplierBulkPurchase[]
}

const ConciliationBalances = ({
  pastUnpaid,
  todayAll,
}: ConciliationBalancesProps) => {
  const todayProfitIn = getTotalProfit(todayAll, 'CLP')
  const todayProfitOut = getTotalProfit(todayAll, 'USDT')

  const todaySupplierIn = getTotalSupplierIn(getUnpaid(todayAll))
  const pastSupplierIn = getTotalSupplierIn(pastUnpaid)
  const totalSupplierIn = todaySupplierIn + pastSupplierIn

  const todaySupplierOut = getTotalSupplierOut(getUnpaid(todayAll))
  const pastSupplierOut = getTotalSupplierOut(pastUnpaid)
  const totalSupplierOut = todaySupplierOut + pastSupplierOut

  const todaySupplierPrice = getSupplierPrice(todayAll)
  const totalSupplierPrice = getSupplierPrice([...todayAll, ...pastUnpaid])

  return (
    <React.Fragment>
      <Balance
        descriptionText='Deuda total'
        currencyIn='CLP'
        digitsIn={0}
        valueIn={totalSupplierIn}
        currencyOut='USDT'
        digitsOut={2}
        valueOut={totalSupplierOut}
      />
      <Balance
        descriptionText='Deuda hoy'
        currencyIn='CLP'
        digitsIn={0}
        valueIn={todaySupplierIn}
        currencyOut='USDT'
        digitsOut={2}
        valueOut={todaySupplierOut}
      />
      <Balance
        descriptionText='Precio promedio'
        currencyIn='Total'
        digitsIn={2}
        valueIn={totalSupplierPrice}
        currencyOut='Hoy'
        digitsOut={2}
        valueOut={todaySupplierPrice}
      />
      <Balance
        descriptionText='Utilidad hoy'
        currencyIn='CLP'
        digitsIn={0}
        valueIn={todayProfitIn}
        currencyOut='USDT'
        digitsOut={2}
        valueOut={todayProfitOut}
      />
    </React.Fragment>
  )
}

export default ConciliationBalances
