import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, Stack, Typography } from '@mui/material'

import { AppContainer, ErrorDisplay, MonospaceText } from 'shared/components'

import { RESEND_CONFIRMATION_EMAIL_MUTATION } from '../../../queries/verifications'
import { translateGuitaError } from '../../../services/error_messages'

import type {
  ResendUserConfirmationEmailData,
  ResendUserConfirmationEmailVars,
} from '../../../queries/verifications'

type ConfirmationEmailResenderProps = {
  userId: string
  userEmail: string
}

const ConfirmationEmailResender = ({
  userId,
  userEmail,
}: ConfirmationEmailResenderProps) => {
  const [submitted, setSubmitted] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()

  const [resendUserConfirmationEmail] =
    useMutation<ResendUserConfirmationEmailData, ResendUserConfirmationEmailVars>(
      RESEND_CONFIRMATION_EMAIL_MUTATION, {
        errorPolicy: 'all',
        variables: {
          userId,
        },
      })

  const handleClick = async () => {
    setSubmitted(true)
    const response = await resendUserConfirmationEmail()

    if (response.data?.resendUserConfirmationEmail) {
      return
    }

    setErrorMsg(translateGuitaError(response))
    setSubmitted(false)
  }

  return (
    <AppContainer sx={{ px: 3, py: 5 }}>
      <Stack
        spacing={3}
        alignItems='center'
      >
        <Typography
          variant='h5'
          textAlign='center'
        >
          Este usuario aún no confirma su email
        </Typography>
        <MonospaceText
          textAlign='center'
          width='100%'
        >
          {userEmail}
        </MonospaceText>
        <ErrorDisplay
          errorMsg={errorMsg}
          mt={2}
        />
        <Button
          size='large'
          color='primary'
          variant='contained'
          onClick={handleClick}
          disabled={submitted}
        >
          {submitted ? 'Email de confirmación reenviado' : 'Reenviar email de confirmación'}
        </Button>
      </Stack>
    </AppContainer>
  )
}

export default ConfirmationEmailResender
