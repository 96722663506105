import * as React from 'react'

import { useQuery } from '@apollo/client'

import { USER_DOCUMENTS_QUERY } from '../../../queries/user_documents'
import UserDocumentsList from '../../user_documents_list'

import type {
  UserDocumentsData,
  UserDocumentsVars,
} from '../../../queries/user_documents'

type AdministrativeDocumentsProps = {
  userId: string
}

const AdministrativeDocuments = ({
  userId,
}: AdministrativeDocumentsProps) => {
  const { loading, data } =
    useQuery<UserDocumentsData, UserDocumentsVars>(USER_DOCUMENTS_QUERY, {
      variables: {
        userId,
      },
    })

  const filteredUserDocuments = (data?.userDocuments || [])
    .filter((userDocument) => ['ADMINISTRATIVE', 'UBO'].includes(userDocument.documentType))

  return (
    <UserDocumentsList
      userId={userId}
      loading={loading}
      documents={filteredUserDocuments}
    />
  )
}

export default AdministrativeDocuments
