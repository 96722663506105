import { gql } from '@apollo/client'

export type UpdatePhoneNumberVars = {
  userId: string
  primary: string
  secondary?: string
}

export type UpdatePhoneNumberData = {
  updatePhoneNumber: string
}

export const UPDATE_PHONE_NUMBER_MUTATION = gql`
  mutation UpdatePhoneNumber(
    $userId: ID!
    $primary: String!
    $secondary: String
  ) {
    updatePhoneNumber(input: {
      userId: $userId
      primary: $primary
      secondary: $secondary
    })
  }
`
