import * as React from 'react'

import { List, ListItem, ListItemText, Skeleton } from '@mui/material'

import { AppContainer } from 'shared/components'

const ListLoading = () => (
  <AppContainer
    md={6}
    sx={{ height: '100%' }}
  >
    <List sx={{ p: 1 }}>
      <ListItem divider>
        <ListItemText
          primaryTypographyProps={{ variant: 'h5' }}
          primary={<Skeleton />}
        />
      </ListItem>
      <ListItem divider>
        <ListItemText primary={<Skeleton />} />
      </ListItem>
      <ListItem>
        <ListItemText primary={<Skeleton />} />
      </ListItem>
    </List>
  </AppContainer>
)

export default ListLoading
