import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Add, Delete } from '@mui/icons-material'
import {
  Container,
  Fab,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'

import {
  AppContainer,
  Dialog,
  ErrorDisplay,
  Loading,
  SeoHeaders,
} from 'shared/components'
import { BANK_ACCOUNT_TYPE_LABELS } from 'shared/queries'
import { BANKS } from 'shared/services'

import { BANK_ACCOUNTS_QUERY, DELETE_BANK_ACCOUNT_MUTATION } from '../../queries/bank_accounts'
import { translateGuitaError } from '../../services/error_messages'
import { BankAccountCreator } from '../bank_account_creator'

import type {
  BankAccountsData,
  BankAccountsVars,
  DeleteBankAccountData,
  DeleteBankAccountVars,
} from '../../queries/bank_accounts'
import type { RouteComponentProps } from '@reach/router'
import type { BankAccount } from 'shared/queries'

type BankAccountDisplayProps = {
  userId: string
  bankAccount: BankAccount
}

const BankAccountDisplay = ({
  userId,
  bankAccount,
}: BankAccountDisplayProps ) => {
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()

  const [deleteBankAccount] =
    useMutation<DeleteBankAccountData, DeleteBankAccountVars>(
      DELETE_BANK_ACCOUNT_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [BANK_ACCOUNTS_QUERY],
      })

  const handleDelete = async () => {
    const response = await deleteBankAccount({
      variables: {
        userId,
        bankAccountId: bankAccount.id,
      },
    })

    if (response.data?.deleteBankAccount === 'OK!') {
      return
    }

    setErrorMsg(translateGuitaError(response))
  }

  return (
    <AppContainer
      md={6}
      sx={{ height: '100%', p: 2 }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack>
          <Typography variant='h6'>
            {BANKS[bankAccount.bankCode].label}
          </Typography>
          <Typography>
            {BANK_ACCOUNT_TYPE_LABELS[bankAccount.accountType]}: {bankAccount.number}
          </Typography>
        </Stack>
        <IconButton onClick={handleDelete}>
          <Delete />
        </IconButton>
      </Stack>
      <ErrorDisplay
        errorMsg={errorMsg}
        mt={1}
      />
    </AppContainer>
  )
}

type BankAccountsDisplayProps = {
  bankAccounts: BankAccount[]
  userId: string
}

const BankAccountsDisplay = ({
  bankAccounts,
  userId,
}: BankAccountsDisplayProps) => (
  <Container
    disableGutters
    maxWidth='lg'
  >
    <Grid
      container
      spacing={2}
    >
      {bankAccounts.map((bankAccount) => (
        <BankAccountDisplay
          key={bankAccount.id}
          userId={userId}
          bankAccount={bankAccount}
        />
      ))}
    </Grid>
  </Container>
)

const NoBankAccountsMessage = () => (
  <Container
    disableGutters
    maxWidth='sm'
  >
    <Grid container>
      <AppContainer sx={{ p: 3 }}>
        <Typography
          variant='h5'
          textAlign='center'
          fontWeight={500}
        >
          No hay una cuenta bancaria asociada
        </Typography>
        <Typography
          textAlign='center'
          pt={2}
        >
          Presiona el botón en la esquina inferior derecha para agregar
          una cuenta bancaria a este usuario.
        </Typography>
      </AppContainer>
    </Grid>
  </Container>
)

type BankAccountsProps = RouteComponentProps & {
  userId?: string
}

const BankAccounts = (props: BankAccountsProps) => {
  const userId = props.userId || ''

  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const { loading, data } = useQuery<BankAccountsData, BankAccountsVars>(BANK_ACCOUNTS_QUERY, {
    variables: {
      userId,
    },
  })

  const bankAccounts = data?.bankAccounts || []

  return (loading) ? (
    <Loading />
  ) : (
    <React.Fragment>
      <SeoHeaders title='Cuentas bancarias' />
      {(bankAccounts.length > 0) ? (
        <BankAccountsDisplay
          bankAccounts={bankAccounts}
          userId={userId}
        />
      ) : (
        <NoBankAccountsMessage />
      )}
      <Fab
        color='primary'
        aria-label='Agregar cuenta bancaria'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Agregar cuenta bancaria'
      >
        <BankAccountCreator
          handleBack={closeDialog}
          handleNext={closeDialog}
          userId={userId}
        />
      </Dialog>
    </React.Fragment>
  )
}

export default BankAccounts
