import { gql } from '@apollo/client'

import { LEGAL_IDENTITY_PROOF_FIELDS } from 'shared/queries'

import type { LegalIdentityProof } from 'shared/queries'

export type CreateLegalIdentityProofVars = {
  userId: string
  contentType: string
  documentType: string
  expirationDate: string | null
}

export type CreateLegalIdentityProofData = {
  createLegalIdentityProof: LegalIdentityProof
}

export const CREATE_LEGAL_IDENTITY_PROOF_MUTATION = gql`
  ${LEGAL_IDENTITY_PROOF_FIELDS}
  mutation CreateLegalIdentityProof(
    $userId: ID!
    $contentType: String!
    $documentType: String!
    $expirationDate: ISO8601Date
  ) {
    createLegalIdentityProof(
      input: {
        userId: $userId
        contentType: $contentType
        documentType: $documentType
        expirationDate: $expirationDate
      }
    ) {
      ...LegalIdentityProofFields
    }
  }
`

export type UpdateLegalIdentityProofVars = {
  userId: string
  documentType: string
  expirationDate: string | null
}

export type UpdateLegalIdentityProofData = {
  updateLegalIdentityProof: string
}

export const UPDATE_LEGAL_IDENTITY_PROOF_MUTATION = gql`
  mutation UpdateLegalIdentityProof(
    $userId: ID!
    $documentType: String!
    $expirationDate: ISO8601Date
  ) {
    updateLegalIdentityProof(
      input: {
        userId: $userId
        documentType: $documentType
        expirationDate: $expirationDate
      }
    )
  }
`

export type DeleteLegalIdentityProofVars = {
  id: string
  userId: string
}

export type DeleteLegalIdentityProofData = {
  deleteLegalIdentityProof: string
}

export const DELETE_LEGAL_IDENTITY_PROOF_MUTATION = gql`
  mutation DeleteLegalIdentityProof(
    $id: ID!
    $userId: ID!
  ) {
    deleteLegalIdentityProof(
      input: {
        id: $id
        userId: $userId
      }
    )
  }
`
