import * as React from 'react'

import { useQuery } from '@apollo/client'
import { HourglassEmpty, Refresh } from '@mui/icons-material'
import { Autocomplete, Button, IconButton, TextField } from '@mui/material'

import { Dialog } from 'shared/components'

import client from '../gatsby-plugin-apollo/client'
import { MANAGED_DOMAINS_QUERY } from '../queries/managed_domains'
import { getCredential } from '../services/auth'
import { getManagedDomain, setManagedDomain } from '../services/managed_domain'

import type { ManagedDomainsData, ManagedDomainsVars } from '../queries/managed_domains'

const ManagedDomainPicker = () => {
  const [refreshing, setRefreshing] = React.useState(false)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const { loading, data } =
    useQuery<ManagedDomainsData, ManagedDomainsVars>(MANAGED_DOMAINS_QUERY)

  const managedDomains = data?.managedDomains || []

  const credentialDomain = getCredential()?.domain
  const managedDomain = getManagedDomain() || credentialDomain
  const isManagingExternalDomain = credentialDomain !== managedDomain

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const refresh = async () => {
    setRefreshing(true)
    await client.refetchQueries({ include: 'active' })
    setRefreshing(false)
  }

  const changeDomain = async (newDomain: string | null) => {
    if (!newDomain) {
      return
    }

    setRefreshing(true)
    setManagedDomain(newDomain)
    await client.resetStore()
    closeDialog()
    setRefreshing(false)
  }

  return (
    <React.Fragment>
      {managedDomains.length > 1 && managedDomain && (
        <Button
          color={isManagingExternalDomain ? 'error' : 'primary'}
          variant={isManagingExternalDomain ? 'contained' : 'outlined'}
          onClick={openDialog}
          disabled={loading || refreshing}
        >
          {managedDomain}
        </Button>
      )}
      <IconButton
        id='refresh-button'
        aria-label='Actualizar'
        disabled={refreshing}
        onClick={refresh}
      >
        {refreshing ? <HourglassEmpty /> : <Refresh />}
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Selecciona un dominio para administrar:'
      >
        <Autocomplete
          disabled={loading}
          options={managedDomains}
          value={managedDomain}
          onChange={async (_event, newValue) => await changeDomain(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Nombre de dominio'
            />
          )}
        />
      </Dialog>
    </React.Fragment>
  )
}

export default ManagedDomainPicker
