import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid, TableCell, TableRow } from '@mui/material'
import { navigate } from '@reach/router'

import { SeoHeaders } from 'shared/components'

import PaginatedTable from '../../components/paginated_table'
import QueryFilter from '../../components/query_filter'
import UserCreator from '../../components/user_creator'
import { USERS_QUERY } from '../../queries/users'
import { isManagedDomain } from '../../services/managed_domain'
import UserCloner from '../user_cloner'

import type { User, UsersData, UsersVars } from '../../queries/users'
import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Users = (props: RouteComponentProps) => {
  const [filter, setFilter] = React.useState('')

  const initialRowsPerPage = 25
  const rowsPerPageOptions = [25, 50, 100]

  const { data, loading, fetchMore } = useQuery<UsersData, UsersVars>(USERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: initialRowsPerPage,
      filter,
    },
  })

  const path = isManagedDomain('Fugiro') ? 'bulk-purchases' : 'portfolio'

  const rowMapper = (node: User, index: number) => (
    <TableRow
      hover
      key={index}
      onClick={() => navigate(`users/${node.id}/${path}`)}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell>{node.id}</TableCell>
      <TableCell>{node.email}</TableCell>
      <TableCell>{node.nickName}</TableCell>
    </TableRow>
  )

  const headRow = [
    <TableCell key='id'>ID</TableCell>,
    <TableCell key='email'>Email</TableCell>,
    <TableCell key='nickName'>Nombre</TableCell>,
  ]

  return (
    <React.Fragment>
      <SeoHeaders title='Usuarios' />
      <Grid
        container
        spacing={3}
        mb={12}
      >
        <QueryFilter
          afterChange={setFilter}
          attributeName='nombre, email o ID de usuario'
        />
        <PaginatedTable<User>
          data={data?.users}
          loading={loading}
          fetchMore={fetchMore}
          rowMapper={rowMapper}
          headRow={headRow}
          initialRowsPerPage={initialRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </Grid>
      <UserCloner />
      <UserCreator />
    </React.Fragment>
  )
}

export default Users
