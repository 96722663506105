import { gql } from '@apollo/client'

export type UpdateLegalIdentityVars = {
  birthdate: string
  familyName: string
  givenName: string
  identificationNumber: string
  nationality: string
  isPoliticallyExposed: boolean
  userId: string
  civilState?: string
}

export type UpdateLegalIdentityData = {
  updateLegalIdentity: string
}

export const UPDATE_LEGAL_IDENTITY_MUTATION = gql`
  mutation UpdateLegalIdentity(
    $birthdate: ISO8601Date!
    $familyName: String!
    $givenName: String!
    $identificationNumber: String!
    $nationality: String!
    $isPoliticallyExposed: Boolean!
    $userId: ID!
    $civilState: String
  ) {
    updateLegalIdentity(
      input: {
        birthdate: $birthdate
        familyName: $familyName
        givenName: $givenName
        identificationNumber: $identificationNumber
        nationality: $nationality
        isPoliticallyExposed: $isPoliticallyExposed
        userId: $userId
        civilState: $civilState
      }
    )
  }
`
