import { gql } from '@apollo/client'

export type UpdateOccupationProfileVars = {
  userId: string
  employmentSituation: string
  profession: string
  company?: string
  companyIdentificationNumber?: string
  jobTitle?: string
}

export type UpdateOccupationProfileData = {
  updateOccupationProfile: string
}

export const UPDATE_OCCUPATION_PROFILE_MUTATION = gql`
  mutation UpdateOccupationProfile(
    $userId: ID!
    $employmentSituation: String!
    $profession: String!
    $company: String
    $companyIdentificationNumber: String
    $jobTitle: String
  ) {
    updateOccupationProfile(
      input: {
        userId: $userId
        employmentSituation: $employmentSituation
        profession: $profession
        company: $company
        companyIdentificationNumber: $companyIdentificationNumber
        jobTitle: $jobTitle
      }
    )
  }
`
