import * as React from 'react'

import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { ButtonContainer } from 'shared/components'

import { isManagedDomain } from '../services/managed_domain'

import type { ButtonContainerProps } from 'shared/components'

const isAicTech = () => isManagedDomain('AicTech')
const isFugiro = () => isManagedDomain('Fugiro')

type UserNavigationButtonsProps = {
  path: string
  navigate?: (path: string) => void
  containerProps?: ButtonContainerProps
}

export const UserNavigationButtons = ({
  path,
  navigate,
  containerProps,
}: UserNavigationButtonsProps) => {
  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newPath: string | undefined,
  ) => {
    navigate && newPath && navigate(newPath)
  }

  return (
    <ButtonContainer {...containerProps}>
      <ToggleButtonGroup
        color='primary'
        value={path}
        exclusive
        onChange={handleChange}
        sx={{
          '& .MuiToggleButton-root': {
            py: 1,
            px: 1.5,
            '&.Mui-selected': {
              color: 'text.primary',
            },
          },
        }}
      >
        {isFugiro() ? (
          <ToggleButton
            value='bulk-purchases'
          >
            Órdenes
          </ToggleButton>
        ) : (
          <ToggleButton
            value='portfolio'
          >
            Portafolio
          </ToggleButton>
        )}
        <ToggleButton
          value='history'
        >
          Historial
        </ToggleButton>
        {!isAicTech() && !isFugiro() && (
          <ToggleButton
            value='deposit'
          >
            Depositar
          </ToggleButton>
        )}
        <ToggleButton
          value='verification'
        >
          Verificación
        </ToggleButton>
        <ToggleButton
          value='bank'
        >
          Cuentas bancarias
        </ToggleButton>
        <ToggleButton
          value='documents'
        >
          Documentos
        </ToggleButton>
        {!isFugiro() && (
          <ToggleButton
            value='notifications'
          >
            Notificaciones
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </ButtonContainer>
  )
}

export default UserNavigationButtons
