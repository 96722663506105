import type { BankWithdrawal } from '../queries/bank_withdrawals'
import type { SupplierBulkPurchase } from '../queries/bulk_purchases'

export const getProfitColor = (amount: number) => {
  if (amount === 0) {
    return 'grey.800'
  }

  return amount > 0 ? 'success.main' : 'error.main'
}

export const getUnpaid = (bulkPurchases: SupplierBulkPurchase[]) => (
  bulkPurchases.filter(({ paidAt }) => !paidAt)
)

export const getTotalSupplierIn = (bulkPurchases: SupplierBulkPurchase[]) => (
  bulkPurchases.reduce((acc, { supplierAmountLeft }) => acc + supplierAmountLeft, 0)
)

export const getTotalSupplierOut = (bulkPurchases: SupplierBulkPurchase[]) => (
  bulkPurchases.reduce((acc, bp) => {
    if (!bp.supplierPrice) {
      return acc
    }

    return acc + (bp.supplierAmountLeft / bp.supplierPrice)
  }, 0)
)

export const getSupplierPrice = (bulkPurchases: SupplierBulkPurchase[]) => {
  const supplierIn = getTotalSupplierIn(withoutBlanc(bulkPurchases))
  const supplierOut = getTotalSupplierOut(withoutBlanc(bulkPurchases))

  return supplierOut ? (supplierIn / supplierOut) : 0
}

export const withoutBlanc = (bulkPurchases: SupplierBulkPurchase[]) => (
  bulkPurchases.filter((bp) => bp.supplier !== 'BLANC')
)

export const getTotalProfit = (bulkPurchases: SupplierBulkPurchase[], currency: string) => (
  bulkPurchases
    .filter((bulkPurchase) => bulkPurchase.profitCurrency === currency)
    .reduce((acc, { profit }) => acc + profit, 0)
)

export const withSupplier = (bulkPurchases: SupplierBulkPurchase[], supplier: string) => (
  bulkPurchases.filter((bulkPurchase) => bulkPurchase.supplier === supplier)
)

export const getSupplierCreditAmount = (bankWithdrawals: BankWithdrawal[], supplier: string) => {
  const supplierWithdrawals = bankWithdrawals.filter((bw) => bw.supplier === supplier)
  const creditAmount = supplierWithdrawals.reduce((acc, bw) => acc + bw.amountLeft, 0)

  return creditAmount > 1e-9 ? creditAmount : 0
}
