import { gql } from '@apollo/client'

export type RiskValue = 'LOW' | 'MEDIUM' | 'HIGH'

export const riskValueLabel = (risk?: RiskValue) => {
  switch (risk) {
  case 'LOW': return 'Bajo ✅'
  case 'MEDIUM': return 'Medio ⚠'
  case 'HIGH': return 'Alto 🚫'
  default: return 'En revisión ⏳'
  }
}

export const riskValueColor = (risk?: RiskValue) => {
  switch (risk) {
  case 'LOW': return 'success.main'
  case 'MEDIUM': return 'warning.main'
  case 'HIGH': return 'error.main'
  default: return 'primary.main'
  }
}

type CoincidenceTypes = 'ACTIVITY' | 'ADDRESS' | 'ID' | 'NAME' | 'NATIONALITY'

const COINCIDENCES_LABELS: { [key in CoincidenceTypes]: string } = {
  'ACTIVITY': 'Actividad económica',
  'ADDRESS': 'Dirección de registro',
  'ID': 'Número de identificación',
  'NAME': 'Nombre',
  'NATIONALITY': 'Nacionalidad',
}

type ListNames =
  'GAFI'
  | 'INTERPOL'
  | 'OFAC_NOT_SDN'
  | 'OFAC_SDN'
  | 'ONU'
  | 'PEP'
  | 'RTP'
  | 'RISKY_ECONOMIC_ACTIVITIES'
  | 'TAX_HAVEN'

const LISTS_LABELS: { [key in ListNames]: string } = {
  'GAFI': 'GAFI',
  'INTERPOL': 'INTERPOL',
  'OFAC_NOT_SDN': 'OFAC sin SDN',
  'OFAC_SDN': 'OFAC con SDN',
  'ONU': 'ONU',
  'PEP': 'Personas Expuestas Políticamente',
  'RTP': 'Regímenes Tributarios Preferentes',
  'RISKY_ECONOMIC_ACTIVITIES': 'Actividades económicas riesgosas',
  'TAX_HAVEN': 'Paraísos fiscales',
}

type ListCoincidence = {
  coincidence: CoincidenceTypes
  details?: string
  name: ListNames
}

export const listCoincidenceLabel = (list: ListCoincidence) => (
  `${COINCIDENCES_LABELS[list.coincidence]} coincide con lista ${LISTS_LABELS[list.name]}`
)

export type RiskRating = {
  id: string
  identityName: string
  value?: RiskValue
  matchedLists: ListCoincidence[]
}

const LIST_COINCIDENCE_FIELDS = gql`
  fragment ListCoincidenceFields on ListCoincidence {
    coincidence
    details
    name
  }
`

export const RISK_RATING_FIELDS = gql`
  ${LIST_COINCIDENCE_FIELDS}
  fragment RiskRatingFields on RiskRating {
    id
    identityName
    value
    matchedLists {
      ...ListCoincidenceFields
    }
  }
`

export type RiskRatingsVars = {
  userId: string
}

export type RiskRatingsData = {
  riskRatings: RiskRating[]
}

export const RISK_RATINGS_QUERY = gql`
  ${RISK_RATING_FIELDS}
  query RiskRatings(
    $userId: ID!
  ) {
    riskRatings(
      userId: $userId
    ) {
      ...RiskRatingFields
    }
  }
`

export type CheckRiskRatingVars = {
  userId: string
}

export type CheckRiskRatingData = {
  checkRiskRating: string
}

export const CHECK_RISK_RATING_MUTATION = gql`
  mutation CheckRiskRating(
    $userId: ID!
  ) {
    checkRiskRating(
      input: {
        userId: $userId
      }
    )
  }
`
