import * as React from 'react'

import { Download, SwapCalls, Upload } from '@mui/icons-material'
import { ListItem, ListItemText, Stack, Typography } from '@mui/material'

import { Currency, DatedList, DatedListItem } from 'shared/components'

import {
  FundDeposit,
  FundOperation,
  FundSwap,
  FundWithdrawal,
} from '../queries/fund_operations'

const MovementsDisplay = ({ operation }: { operation: FundOperation }) => {
  const isWithdrawal = operation.__typename === 'FundWithdrawal'

  return (
    <Stack
      width={170}
      justifyContent='left'
    >
      {operation.assets.map((asset, index) => (
        <Typography key={index}>
          <Currency
            currency={asset}
            digits={6}
            value={isWithdrawal ? -operation.amounts[index] : operation.amounts[index]}
          />
        </Typography>
      ))}
    </Stack>
  )
}

const FundDepositDisplay = ({
  operation,
}: OperationDisplay<FundDeposit>) => (
  <DatedListItem
    icon={<Download />}
    text={'Depósito'}
    timestamp={operation.timestamp}
  >
    <MovementsDisplay operation={operation} />
  </DatedListItem>
)

type OperationDisplay<OperationType> = {
  operation: OperationType
}

const FundSwapDisplay = ({
  operation,
}: OperationDisplay<FundSwap>) => (
  <DatedListItem
    icon={<SwapCalls />}
    text={'Rebalanceo'}
    timestamp={operation.timestamp}
  >
    <MovementsDisplay operation={operation} />
  </DatedListItem>
)

const FundWithdrawalDisplay = ({
  operation,
}: OperationDisplay<FundWithdrawal>) => (
  <DatedListItem
    icon={<Upload />}
    text={'Retiro'}
    timestamp={operation.timestamp}
  >
    <MovementsDisplay operation={operation} />
  </DatedListItem>
)

const getOperationComponent = (
  operation: FundOperation,
) => {
  switch (operation.__typename) {
  case 'FundDeposit':
    return (
      <FundDepositDisplay operation={operation} />
    )
  case 'FundSwap':
    return (
      <FundSwapDisplay operation={operation} />
    )
  case 'FundWithdrawal':
    return (
      <FundWithdrawalDisplay operation={operation} />
    )
  }
}

const EmptyOperationsDisplay = () => (
  <ListItem>
    <ListItemText
      primary='No hay operaciones para mostrar'
      primaryTypographyProps={{ color: 'text.secondary', textAlign: 'center' }}
    />
  </ListItem>
)

type FundOperationsListProps = {
  operations: FundOperation[]
}

export const FundOperationsList = ({
  operations,
}: FundOperationsListProps) => (
  <DatedList
    emptyListDisplay={<EmptyOperationsDisplay />}
    items={operations.map((op) => ({
      timestamp: op.timestamp,
      component: getOperationComponent(op),
    }))}
  />
)
