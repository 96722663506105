import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid, TableCell, TableRow } from '@mui/material'
import { navigate } from '@reach/router'

import { SeoHeaders } from 'shared/components'

import PaginatedTable from '../../components/paginated_table'
import QueryFilter from '../../components/query_filter'
import { VERIFICATIONS_QUERY, translateState } from '../../queries/verifications'

import type { Verification, VerificationsData, VerificationsVars } from '../../queries/verifications'
import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Verifications = (props: RouteComponentProps) => {
  const [filter, setFilter] = React.useState('')

  const initialRowsPerPage = 25
  const rowsPerPageOptions = [25, 50, 100]

  const { data, loading, fetchMore } =
    useQuery<VerificationsData, VerificationsVars>(VERIFICATIONS_QUERY, {
      notifyOnNetworkStatusChange: true,
      variables: {
        first: initialRowsPerPage,
        filter,
      },
    })

  const rowMapper = (node: Verification, index: number) => (
    <TableRow
      hover
      key={index}
      onClick={() => navigate(`users/${node.userId}/verification`)}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell>{node.id}</TableCell>
      <TableCell>{translateState(node.state)}</TableCell>
      <TableCell>{node.userEmail}</TableCell>
    </TableRow>
  )

  const headRow = [
    <TableCell key='id'>ID</TableCell>,
    <TableCell key='state'>Estado</TableCell>,
    <TableCell key='user'>Usuario</TableCell>,
  ]

  return (
    <React.Fragment>
      <SeoHeaders title='Verificaciones' />
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <QueryFilter
          afterChange={setFilter}
          attributeName='nombre, email o ID de usuario'
        />
        <PaginatedTable<Verification>
          data={data?.verifications}
          loading={loading}
          fetchMore={fetchMore}
          rowMapper={rowMapper}
          headRow={headRow}
          initialRowsPerPage={initialRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </Grid>
    </React.Fragment>
  )
}

export default Verifications
