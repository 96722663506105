import { gql } from '@apollo/client'

export type UpdateLegalAddressVars = {
  userId: string
  countryCode: string
  regionCode: string
  city: string
  streetAddress: string
  postalCode: string
}

export type UpdateLegalAddressData = {
  updateLegalAddress: string
}

export const UPDATE_LEGAL_ADDRESS_MUTATION = gql`
  mutation UpdateLegalAddress(
    $userId: ID!
    $countryCode: String!
    $regionCode: String!
    $city: String!
    $streetAddress: String!
    $postalCode: String!
  ) {
    updateLegalAddress(
      input: {
        userId: $userId
        countryCode: $countryCode
        regionCode: $regionCode
        city: $city
        streetAddress: $streetAddress
        postalCode: $postalCode
      }
    )
  }
`
