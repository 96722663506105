import { gql } from '@apollo/client'

import type { PaginableQuery, PaginableVars } from './pagination_helpers'

export type VerificationAction = 'request_review' | 'request_changes' | 'reject' | 'approve'
export type VerificationState = 'pending_review' | 'pending_changes' | 'rejected' | 'approved'

export type ResendUserConfirmationEmailVars = {
  userId: string
}

export type ResendUserConfirmationEmailData = {
  resendUserConfirmationEmail: string
}

export const RESEND_CONFIRMATION_EMAIL_MUTATION = gql`
  mutation ResendUserConfirmationEmail(
    $userId: ID!
  ) {
    resendUserConfirmationEmail(
      input: {
        userId: $userId
      }
    )
  }
`

export type Verification = {
  id: string
  state: VerificationState
  userId: string
  userEmail: string
}

const VERIFICATION_FIELDS = gql`
  fragment VerificationFields on Verification {
    id
    state
    userId
    userEmail
  }
`

export type VerificationsVars = PaginableVars & {
  filter?: string
}

export type VerificationsData = {
  verifications: PaginableQuery<Verification>
}

export const VERIFICATIONS_QUERY = gql`
  ${VERIFICATION_FIELDS}
  query Verifications(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $filter: String
  ) {
    verifications(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...VerificationFields
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
`

export type UpdateVerificationVars = {
  userId: string
  fromState: string
  withAction: string
  message?: string
}

export type UpdateVerificationData = {
  updateVerification: string
}

export const UPDATE_VERIFICATION_MUTATION = gql`
  mutation UpdateVerification(
    $userId: ID!
    $fromState: String!
    $withAction: String!
    $message: String
  ) {
    updateVerification(
      input: {
        userId: $userId
        fromState: $fromState
        withAction: $withAction
        message: $message
      }
    )
  }
`

export const translateState = (state: VerificationState | undefined) => {
  switch (state) {
  case 'pending_review': return 'Esperando revisión'
  case 'pending_changes': return 'Esperando modificaciones'
  case 'rejected': return 'Rechazada'
  case 'approved': return 'Aprobada'
  default: return 'No iniciada'
  }
}

export const colorState = (state: VerificationState | undefined) => {
  switch (state) {
  case 'pending_review': return 'warning.main'
  case 'pending_changes': return 'info.main'
  case 'rejected': return 'error.main'
  case 'approved': return 'success.main'
  default: return 'primary.main'
  }
}
