import { gql } from '@apollo/client'

type Status = 'INITIAL' | 'PROCESS' | 'REVIEW' | 'PASS' | 'REJECT'

export const STATUS_LABELS: { [key in Status | 'SKIP']: string } = {
  'INITIAL': 'Iniciando ⏳',
  'PROCESS': 'En proceso ⏳',
  'REVIEW': 'En revisión ⏳',
  'PASS': 'Aprobado ✅',
  'REJECT': 'Rechazado 🚫',
  'SKIP': 'Omitido 👍',
}

export type BinanceKyc = {
  id: string
  basicStatus: Status
  faceStatus: Status & 'SKIP'
  identityStatus: Status
  kycStatus: Status
  worldCheckStatus: Status
}

export const BINANCE_KYC_FIELDS = gql`
  fragment BinanceKycFields on BinanceKyc {
    id
    basicStatus
    faceStatus
    identityStatus
    kycStatus
    worldCheckStatus
  }
`

export type BinanceKycVars = {
  userId: string
}

export type BinanceKycData = {
  binanceKyc: BinanceKyc
}

export const BINANCE_KYC_QUERY = gql`
  ${BINANCE_KYC_FIELDS}
  query BinanceKyc(
    $userId: ID!
  ) {
    binanceKyc(
      userId: $userId
    ) {
      ...BinanceKycFields
    }
  }
`

export type BinanceKycCheckVars = {
  userId: string
}

export type BinanceKycCheckData = {
  binanceKycCheck: string
}

export const BINANCE_KYC_CHECK_MUTATION = gql`
  mutation BinanceKycCheck(
    $userId: ID!
  ) {
    binanceKycCheck(
      input: {
        userId: $userId
      }
    )
  }
`

export type BinanceKycPushVars = {
  userId: string
}

export type BinanceKycPushData = {
  binanceKycPush: string
}

export const BINANCE_KYC_PUSH_MUTATION = gql`
  mutation BinanceKycPush(
    $userId: ID!
  ) {
    binanceKycPush(
      input: {
        userId: $userId
      }
    )
  }
`
