import * as React from 'react'

import { useQuery } from '@apollo/client'
import { TableCell, TableRow } from '@mui/material'
import { navigate } from '@reach/router'

import { SeoHeaders } from 'shared/components'

import PaginatedTable from '../../components/paginated_table'
import { ADMINS_QUERY } from '../../queries/admins'

import type { Admin, AdminsData, AdminsVars } from '../../queries/admins'
import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Admins = (props: RouteComponentProps) => {
  const initialRowsPerPage = 25
  const rowsPerPageOptions = [25, 50, 100]

  const { data, loading, fetchMore } = useQuery<AdminsData, AdminsVars>(ADMINS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: initialRowsPerPage,
    },
  })

  const rowMapper = (node: Admin, index: number) => (
    <TableRow
      hover
      key={index}
      onClick={() => navigate(`admins/${node.id}`)}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell>{node.id}</TableCell>
      <TableCell>{node.email}</TableCell>
      <TableCell>{node.nickName}</TableCell>
    </TableRow>
  )

  const headRow = [
    <TableCell key='id'>ID</TableCell>,
    <TableCell key='email'>Email</TableCell>,
    <TableCell key='nickName'>Nombre</TableCell>,
  ]

  return (
    <React.Fragment>
      <SeoHeaders title='Admins' />
      <PaginatedTable<Admin>
        data={data?.admins}
        loading={loading}
        fetchMore={fetchMore}
        rowMapper={rowMapper}
        headRow={headRow}
        initialRowsPerPage={initialRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </React.Fragment>
  )
}

export default Admins
