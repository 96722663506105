import * as React from 'react'

import { ListItem, ListItemText } from '@mui/material'

import { SOCIETY_TYPE_LABELS } from 'shared/queries'
import { siiActivityLabel } from 'shared/services'

import BusinessIdentityEditForm from './business_identity_edit_form'
import ListDisplay from '../list_display'

import type { BusinessIdentity } from 'shared/queries'

type BusinessIdentityDetailsDisplayProps = {
  userEmail: string
  businessIdentity: BusinessIdentity
}

const BusinessIdentityDetailsDisplay = ({
  userEmail,
  businessIdentity,
}: BusinessIdentityDetailsDisplayProps) => (
  <React.Fragment>
    <ListItem divider>
      <ListItemText
        primary='Correo electrónico'
        secondary={userEmail}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Nombre'
        secondary={businessIdentity.companyName}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Tipo de sociedad'
        secondary={SOCIETY_TYPE_LABELS[businessIdentity.societyType] || 'Sin declarar'}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='RUT'
        secondary={businessIdentity.identificationNumber}
      />
    </ListItem>
    <ListItem>
      <ListItemText
        primary='Actividad tributaria'
        secondary={siiActivityLabel(businessIdentity.siiActivityCode)}
      />
    </ListItem>
  </React.Fragment>
)

type BusinessIdentityDisplayProps = {
  userEmail: string
  userId: string
  businessIdentity?: BusinessIdentity
}

const BusinessIdentityDisplay = ({
  userEmail,
  userId,
  businessIdentity,
}: BusinessIdentityDisplayProps) => (
  <ListDisplay
    title='Información de la empresa'
    item={businessIdentity}
    editForm={(closeDialog) => (
      <BusinessIdentityEditForm
        closeDialog={closeDialog}
        userId={userId}
        businessIdentity={businessIdentity}
      />
    )}
  >
    {(item) => (
      <BusinessIdentityDetailsDisplay
        userEmail={userEmail}
        businessIdentity={item}
      />
    )}
  </ListDisplay>
)

export default BusinessIdentityDisplay
