import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import { GridDivider, SeoHeaders } from 'shared/components'

import AdministrativeDocuments from './administrative_documents'
import AdvancedOnboardingDisplay from './advanced_onboarding_display'
import BinanceKycDisplay from './binance_kyc_display'
import ConfirmationEmailResender from './confirmation_email_resender'
import LegalAddressDisplay from './legal_address_display'
import LegalIdentityDisplay from './legal_identity_display'
import LegalIdentityProofsDisplay from './legal_identity_proofs_display'
import ListLoading from './list_loading'
import LivenessProofsDisplay from './liveness_proofs_display'
import RiskRatingsDisplay from './risk_ratings_display'
import VerificationActions from './verification_actions'
import { USER_QUERY } from '../../../queries/users'
import { isManagedDomain } from '../../../services/managed_domain'

import type { DetailedUser, UserData, UserVars } from '../../../queries/users'
import type { RouteComponentProps } from '@reach/router'

const isSugarblock = () => isManagedDomain('Sugarblock')

type UserInformationDisplayProps = {
  user: DetailedUser
}

const UserInformationDisplay = ({
  user,
}: UserInformationDisplayProps) => {
  if (!user.isConfirmed) {
    return (
      <ConfirmationEmailResender
        userId={user.id}
        userEmail={user.email}
      />
    )
  } else if (user.onboardingType === 'ADVANCED') {
    return <AdvancedOnboardingDisplay user={user} />
  }

  return (
    <React.Fragment>
      <GridDivider>Perfil de riesgo</GridDivider>
      <RiskRatingsDisplay user={user} />
      <GridDivider>Información personal</GridDivider>
      <LegalIdentityDisplay
        userEmail={user.email}
        userId={user.id}
        legalIdentity={user.legalIdentity}
        onboardingType={user.onboardingType}
      />
      <LegalAddressDisplay
        userId={user.id}
        legalAddress={user.legalAddress}
      />
      <GridDivider>Documentos de verificación</GridDivider>
      <LivenessProofsDisplay livenessProofs={user.livenessProofs || []} />
      <LegalIdentityProofsDisplay
        userId={user.id}
        isBusiness={false}
        legalIdentityProofs={user.legalIdentityProofs || []}
      />
      <GridDivider>Documentos administrativos</GridDivider>
      <AdministrativeDocuments userId={user.id} />
      {isSugarblock() && (
        <React.Fragment>
          <GridDivider>Binance KYC</GridDivider>
          <BinanceKycDisplay userId={user.id} />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

type UserProps = RouteComponentProps & {
  userId?: string
}

const Verification = (props: UserProps) => {
  const userId = props.userId || ''

  const { loading, data } =
    useQuery<UserData, UserVars>(USER_QUERY, {
      notifyOnNetworkStatusChange: true,
      variables: {
        userId,
      },
    })

  const user = data?.user

  return (
    <React.Fragment>
      <SeoHeaders title={`Verificación - ${userId}`} />
      <Grid
        container
        spacing={3}
      >
        {(loading || !user) ? (
          <React.Fragment>
            <ListLoading />
            <ListLoading />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <UserInformationDisplay user={user} />
            {user.verification && (
              <React.Fragment>
                <GridDivider>Acciones</GridDivider>
                <VerificationActions user={user} />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  )
}

export default Verification
