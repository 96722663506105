import { isManagedDomain } from './managed_domain'

const hasRole = (expected: string | string[], actual: string | undefined) => {
  if (!actual) {
    return false
  }

  return Array.isArray(expected) ? expected.includes(actual) : expected === actual
}

export const canViewDeployments = (role?: string) => (
  hasRole('SUPER_MANAGER', role)
)

export const canCreateOperations = (role?: string) => (
  hasRole(['SUPER_MANAGER', 'MANAGER'], role)
)

export const canCreateDeposit = (role?: string) => (
  !isManagedDomain('Fugiro') && hasRole(['SUPER_MANAGER', 'MANAGER'], role)
)

export const canCreateSwap = (role?: string) => (
  !isManagedDomain('Fugiro') && hasRole('SUPER_MANAGER', role)
)

export const canCreateWithdraw = (role?: string) => (
  isManagedDomain('Fugiro')
    ? hasRole('SUPER_MANAGER', role)
    : hasRole(['SUPER_MANAGER', 'MANAGER'], role)
)
