import { gql } from '@apollo/client'

export type CreateSwapVars = {
  userId: string
  fromSymbol: string
  fromAmount: string
  notifyUser: boolean
  timestamp: string
  toAmount: string
  toSymbol: string
  useMaxAmount: boolean
}

export type CreateSwapData = {
  createSwap: string
}

export const CREATE_SWAP_MUTATION = gql`
  mutation CreateSwap(
    $userId: ID!
    $fromSymbol: String!
    $fromAmount: String!
    $notifyUser: Boolean!
    $timestamp: String!
    $toAmount: String!
    $toSymbol: String!
    $useMaxAmount: Boolean!
  ) {
    createSwap(
      input: {
        userId: $userId
        fromSymbol: $fromSymbol
        fromAmount: $fromAmount
        notifyUser: $notifyUser
        timestamp: $timestamp
        toAmount: $toAmount
        toSymbol: $toSymbol
        useMaxAmount: $useMaxAmount
      }
    )
  }
`
