import { gql } from '@apollo/client'

type FundOperationAttributes = {
  id: string
  amounts: number[]
  assets: string[]
  timestamp: number
}

export type FundDeposit = { __typename: 'FundDeposit' } & FundOperationAttributes

export const FUND_DEPOSIT_FIELDS = gql`
  fragment FundDepositFields on FundDeposit {
    id
    amounts
    assets
    timestamp
  }
`

export type FundSwap = { __typename: 'FundSwap' } & FundOperationAttributes

export const FUND_SWAP_FIELDS = gql`
  fragment FundSwapFields on FundSwap {
    id
    amounts
    assets
    timestamp
  }
`

export type FundWithdrawal = { __typename: 'FundWithdrawal' } & FundOperationAttributes

export const FUND_WITHDRAWAL_FIELDS = gql`
  fragment FundWithdrawalFields on FundWithdrawal {
    id
    amounts
    assets
    timestamp
  }
`

export type FundOperation =
  FundDeposit
  | FundSwap
  | FundWithdrawal

export type CreateFundDepositVars = {
  fundId: string
  assets: string[]
  amounts: string[]
  timestamp: string
}

export type CreateFundDepositData = {
  createFundDeposit: string
}

export const CREATE_FUND_DEPOSIT_MUTATION = gql`
  mutation CreateFundDeposit(
    $fundId: ID!
    $assets: [String!]!
    $amounts: [String!]!
    $timestamp: String!
  ) {
    createFundDeposit(
      input: {
        fundId: $fundId
        assets: $assets
        amounts: $amounts
        timestamp: $timestamp
      }
    )
  }
`

export type CreateFundSwapVars = {
  fundId: string
  assets: string[]
  amounts: string[]
  timestamp: string
}

export type CreateFundSwapData = {
  createFundSwap: string
}

export const CREATE_FUND_SWAP_MUTATION = gql`
  mutation CreateFundSwap(
    $fundId: ID!
    $assets: [String!]!
    $amounts: [String!]!
    $timestamp: String!
  ) {
    createFundSwap(
      input: {
        fundId: $fundId
        assets: $assets
        amounts: $amounts
        timestamp: $timestamp
      }
    )
  }
`

export type CreateFundWithdrawalVars = {
  fundId: string
  assets: string[]
  amounts: string[]
  timestamp: string
}

export type CreateFundWithdrawalData = {
  createFundWithdrawal: string
}

export const CREATE_FUND_WITHDRAWAL_MUTATION = gql`
  mutation CreateFundWithdrawal(
    $fundId: ID!
    $assets: [String!]!
    $amounts: [String!]!
    $timestamp: String!
  ) {
    createFundWithdrawal(
      input: {
        fundId: $fundId
        assets: $assets
        amounts: $amounts
        timestamp: $timestamp
      }
    )
  }
`
