import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid, TableCell, TableRow } from '@mui/material'
import { navigate } from '@reach/router'

import { SeoHeaders } from 'shared/components'
import { localizeISO8601 } from 'shared/services'

import DynamicConfigCreator from '../../components/dynamic_config_creator'
import PaginatedTable from '../../components/paginated_table'
import { DYNAMIC_CONFIGS_QUERY, VALUE_TYPE_LABELS } from '../../queries/dynamic_configs'

import type {
  DynamicConfig,
  DynamicConfigsData,
  DynamicConfigsVars,
} from '../../queries/dynamic_configs'
import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DynamicConfigs = (props: RouteComponentProps) => {
  const initialRowsPerPage = 25
  const rowsPerPageOptions = [25, 50, 100]

  const { data, loading, fetchMore } =
    useQuery<DynamicConfigsData, DynamicConfigsVars>(DYNAMIC_CONFIGS_QUERY, {
      notifyOnNetworkStatusChange: true,
      variables: {
        first: initialRowsPerPage,
      },
    })

  const rowMapper = (node: DynamicConfig, index: number) => (
    <TableRow
      hover
      key={index}
      onClick={() => navigate(`/app/dynamic-configs/${node.id}`)}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell>{node.id}</TableCell>
      <TableCell>{node.name}</TableCell>
      <TableCell>{VALUE_TYPE_LABELS[node.valueType]}</TableCell>
      <TableCell>{localizeISO8601(node.createdAt)}</TableCell>
      <TableCell>{localizeISO8601(node.updatedAt)}</TableCell>
    </TableRow>
  )

  const headRow = [
    <TableCell key='id'>ID</TableCell>,
    <TableCell key='name'>Nombre</TableCell>,
    <TableCell key='type'>Tipo</TableCell>,
    <TableCell key='createdAt'>Creada</TableCell>,
    <TableCell key='updatedAt'>Modificada</TableCell>,
  ]

  return (
    <React.Fragment>
      <SeoHeaders title='Plataforma' />
      <Grid
        container
        spacing={3}
        mb={12}
      >
        <Grid
          item
          xs={12}
        >
          <PaginatedTable<DynamicConfig>
            data={data?.dynamicConfigs}
            loading={loading}
            fetchMore={fetchMore}
            rowMapper={rowMapper}
            headRow={headRow}
            initialRowsPerPage={initialRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </Grid>
      </Grid>
      <DynamicConfigCreator />
    </React.Fragment>
  )
}

export default DynamicConfigs
