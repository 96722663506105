import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'

import { AppContainer, Loading } from 'shared/components'

import RiskRatingDisplay from './risk_rating_display'
import { RISK_RATINGS_QUERY } from '../../../queries/risk_ratings'

import type { RiskRatingsData, RiskRatingsVars } from '../../../queries/risk_ratings'
import type { User } from '../../../queries/users'

const EmptyRiskRatingsDisplay = () => (
  <AppContainer
    md={12}
    sx={{ p: 2, textAlign: 'center' }}
  >
    <Typography color='text.secondary'>No hay perfiles para mostrar</Typography>
  </AppContainer>
)

type RiskRatingsDisplayProps = {
  user: User
}

const RiskRatingsDisplay = ({
  user,
}: RiskRatingsDisplayProps) => {
  const { loading, data } =
    useQuery<RiskRatingsData, RiskRatingsVars>(RISK_RATINGS_QUERY, {
      variables: {
        userId: user.id,
      },
    })

  if (loading) {
    return (
      <AppContainer
        md={6}
        sx={{ p: 2, textAlign: 'center' }}
      >
        <Loading />
      </AppContainer>
    )
  }

  const riskRatings = data?.riskRatings || []

  return (riskRatings.length === 0) ? (
    <EmptyRiskRatingsDisplay />
  ) : (
    <React.Fragment>
      {riskRatings.map((riskRating, index) => (
        <RiskRatingDisplay
          key={index}
          riskRating={riskRating}
        />
      ))}
    </React.Fragment>
  )
}

export default RiskRatingsDisplay
