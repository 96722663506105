import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import {
  AppContainer,
  BackButton,
  ButtonsContainer,
  EarningsBalance,
  GraphUnitButtons,
  MainBalance,
  OperationsList,
  RangeButtons,
  SeoHeaders,
  TickerPricesGraph,
  VariationBalance,
} from 'shared/components'
import { DomainContext } from 'shared/contexts'
import { TICKER_PRICES_QUERY } from 'shared/queries'
import {
  asOperationsArray,
  getSymbol,
  getTimeframeData,
  getTotalAmount,
  getUnspentEarnedAmount,
  getUserAssetAmounts,
  getUserAssetPriceData,
  hourlyTimestamp,
  useHasApyWithSymbol,
  withSymbol,
} from 'shared/services'

import { ALL_USER_OPERATIONS_QUERY } from '../../queries/user_operations'
import AntiFomoText from '../anti_fomo_text'

import type {
  AllUserOperationsData,
  AllUserOperationsVars,
} from '../../queries/user_operations'
import type { RouteComponentProps } from '@reach/router'
import type { TickerPricesData, TickerPricesVars } from 'shared/queries'

type Range = TickerPricesVars['range']

const graphHeight = 280

type PortfolioDetailsProps = RouteComponentProps & {
  symbol?: string
}

const MainPortfolioDetails = (props: PortfolioDetailsProps) => {
  const { quoteSymbol, conversionCurrency } = React.useContext(DomainContext)
  const symbol = props.symbol || ''
  const endTimestamp = hourlyTimestamp()
  const [graphUnit, setGraphUnit] = React.useState<string>(conversionCurrency!)
  const [range, setRange] = React.useState<Range>('24h')

  const { loading: hasApyLoading, hasApy } = useHasApyWithSymbol(symbol)

  const { loading: pricesLoading, data: pricesData } =
    useQuery<TickerPricesData, TickerPricesVars>(TICKER_PRICES_QUERY, {
      variables: {
        symbol: getSymbol(symbol),
        range,
        endTimestamp,
      },
    })

  const { loading: operationsLoading, data: operationsData } =
    useQuery<AllUserOperationsData, AllUserOperationsVars>(ALL_USER_OPERATIONS_QUERY)

  const tickerPrices = pricesData ? pricesData.tickerPrices : []

  const assetOperations = withSymbol(asOperationsArray(operationsData?.allUserOperations), symbol)
  const hasNoOperations = (!operationsLoading && assetOperations.length === 0)

  const timeframeData = getTimeframeData(tickerPrices, symbol, quoteSymbol!)
  const assetUsdPrices = timeframeData.prices
  const assetUsdPrice = timeframeData.lastPrice

  const amounts = getUserAssetAmounts(symbol, assetOperations, assetUsdPrices)
  const totalAmount = getTotalAmount(symbol, assetOperations)
  const earnedAmount = getUnspentEarnedAmount(symbol, assetOperations)

  const { assetPrices, ath, atl, variation } =
    getUserAssetPriceData(amounts, assetUsdPrices, graphUnit === conversionCurrency!)

  return (
    <React.Fragment>
      <SeoHeaders title={`Reportería - ${symbol}`} />
      <Grid
        container
        spacing={3}
      >
        <MainBalance
          loading={pricesLoading || operationsLoading}
          currency={graphUnit}
          assetAmount={totalAmount}
          assetUsdPrice={assetUsdPrice}
        />
        {hasApy ? (
          <EarningsBalance
            loading={hasApyLoading || pricesLoading || operationsLoading}
            currency={graphUnit}
            assetAmount={earnedAmount}
            assetUsdPrice={assetUsdPrice}
          />
        ) : (
          <VariationBalance
            loading={hasApyLoading || pricesLoading || operationsLoading}
            currency={graphUnit}
            range={range}
            variation={variation}
          />
        )}
        <ButtonsContainer>
          <BackButton
            text='Portafolio'
            containerProps={{ xs: true }}
          />
          <GraphUnitButtons
            disabled={pricesLoading}
            keys={[conversionCurrency!, getSymbol(symbol)]}
            graphUnit={graphUnit}
            setGraphUnit={setGraphUnit}
          />
          <RangeButtons<Range>
            disabled={pricesLoading || hasNoOperations}
            keys={['24h', '7d', '30d', '1y', 'all']}
            range={range}
            setRange={setRange}
          />
        </ButtonsContainer>
        <AppContainer sx={{ height: graphHeight, py: 1 }}>
          {hasNoOperations ? (
            <AntiFomoText />
          ) : (
            <TickerPricesGraph
              currency={graphUnit}
              data={assetPrices}
              ath={ath}
              atl={atl}
            />
          )}
        </AppContainer>
        <OperationsList
          loading={pricesLoading || operationsLoading}
          operations={assetOperations}
        />
      </Grid>
    </React.Fragment>
  )
}

export default MainPortfolioDetails
