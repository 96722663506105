import * as React from 'react'

import {
  ListItem,
  ListItemText,
} from '@mui/material'

import { DocumentDisplay } from 'shared/components'
import { POI_DOCUMENT_TYPE_LABELS } from 'shared/queries'

import LegalIdentityProofDeleteForm from './legal_identity_proof_delete_form'
import LegalIdentityProofEditForm from './legal_identity_proof_edit_form'
import ListDisplay from './list_display'

import type { LegalIdentityProofView } from 'shared/queries'

type LegalIdentityProofDetailsDisplayProps = {
  legalIdentityProof: LegalIdentityProofView
}

const LegalIdentityProofDetailsDisplay = ({
  legalIdentityProof,
}: LegalIdentityProofDetailsDisplayProps) => (
  <React.Fragment>
    <ListItem divider>
      <ListItemText
        primary='Fecha de expiración'
        secondary={legalIdentityProof.expirationDate || '<Sin información>'}
      />
    </ListItem>
    <ListItem>
      <DocumentDisplay
        contentType={legalIdentityProof.contentType}
        documentType={legalIdentityProof.documentType}
        storageUrl={legalIdentityProof.storageUrl}
      />
    </ListItem>
  </React.Fragment>
)

type LegalIdentityProofDisplayProps = {
  userId: string
  isBusiness: boolean
  legalIdentityProof: LegalIdentityProofView
}

const LegalIdentityProofDisplay = ({
  userId,
  isBusiness,
  legalIdentityProof,
}: LegalIdentityProofDisplayProps) => (
  <ListDisplay
    title={POI_DOCUMENT_TYPE_LABELS[legalIdentityProof.documentType]}
    item={legalIdentityProof}
    deleteForm={(closeDialog) => (
      <LegalIdentityProofDeleteForm
        closeDialog={closeDialog}
        userId={userId}
        documentId={legalIdentityProof.id}
      />
    )}
    editForm={(closeDialog) => (
      <LegalIdentityProofEditForm
        closeDialog={closeDialog}
        userId={userId}
        isBusiness={isBusiness}
        legalIdentityProof={legalIdentityProof}
      />
    )}
  >
    {(item) => <LegalIdentityProofDetailsDisplay legalIdentityProof={item} />}
  </ListDisplay>
)

export default LegalIdentityProofDisplay
