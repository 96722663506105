import * as React from 'react'

import { useQuery } from '@apollo/client'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  alpha,
} from '@mui/material'

import { AppContainer, SeoHeaders, UserNotificationContent } from 'shared/components'
import {
  translateUserNotificationStatus,
  translateUserNotificationType,
} from 'shared/queries'
import { localizeISO8601 } from 'shared/services'

import {
  USER_NOTIFICATIONS_QUERY,
} from '../../queries/user_notifications'
import PaginatedTable from '../paginated_table'
import UserNotificationDialog from '../user_notification_dialog'

import type {
  UserNotification,
  UserNotificationsData,
  UserNotificationsVars,
} from '../../queries/user_notifications'
import type { SelectChangeEvent } from '@mui/material'
import type { RouteComponentProps } from '@reach/router'

type UserNotificationsProps = RouteComponentProps & {
  userId?: string
}

const UserNotifications = (props: UserNotificationsProps) => {
  const userId = props.userId || ''

  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [state, setState] = React.useState('')
  const [userNotification, setUserNotification] = React.useState<UserNotification>()

  const initialRowsPerPage = 25
  const rowsPerPageOptions = [25, 50, 100]

  const { data, loading, fetchMore } =
    useQuery<UserNotificationsData, UserNotificationsVars>(
      USER_NOTIFICATIONS_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {
          first: initialRowsPerPage,
          filter: userId,
          state,
        },
      })

  const openDialog = (notification: UserNotification) => {
    setUserNotification(notification)
    setDialogOpen(true)
  }

  const closeDialog = async () => {
    setDialogOpen(false)
  }

  const rowMapper = (node: UserNotification, index: number) => (
    <TableRow
      hover
      key={index}
      onClick={() => openDialog(node)}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell>{localizeISO8601(node.createdAt)}</TableCell>
      <TableCell>{translateUserNotificationType(node.notificationType)}</TableCell>
      <TableCell><UserNotificationContent notification={node} /></TableCell>
      <TableCell
        sx={(theme) => ({
          bgcolor: alpha(
            node.resolvedAt ? theme.palette.success.light : theme.palette.warning.light,
            0.2,
          ),
        })}
      >
        {translateUserNotificationStatus(node.resolvedAt)}
      </TableCell>
    </TableRow>
  )

  const headRow = [
    <TableCell key='createdAt'>Fecha de creación</TableCell>,
    <TableCell key='notificationType'>Tipo</TableCell>,
    <TableCell key='content'>Contenido</TableCell>,
    <TableCell key='resolvedAt'>Estado</TableCell>,
  ]

  return (
    <React.Fragment>
      <SeoHeaders title='Notificaciones' />
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <AppContainer sx={{ p: 2 }}>
          <FormControl fullWidth>
            <InputLabel id='state-picker-label'>Filtrar por estado</InputLabel>
            <Select
              labelId='state-picker-label'
              id='state-picker'
              value={state}
              label='Filtrar por estado'
              onChange={(event: SelectChangeEvent) => setState(event.target.value)}
            >
              <MenuItem value='ALL'>Todas</MenuItem>
              <MenuItem value='PENDING'>Pendientes</MenuItem>
              <MenuItem value='RESOLVED'>Resueltas</MenuItem>
            </Select>
          </FormControl>
        </AppContainer>
        <PaginatedTable<UserNotification>
          data={data?.userNotifications}
          loading={loading}
          fetchMore={fetchMore}
          rowMapper={rowMapper}
          headRow={headRow}
          initialRowsPerPage={initialRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </Grid>
      {userNotification && (
        <UserNotificationDialog
          closeDialog={closeDialog}
          dialogOpen={dialogOpen}
          userNotification={userNotification}
        />
      )}
    </React.Fragment>
  )
}

export default UserNotifications
