import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { Form, Formik } from 'formik'

import { ButtonContainer, ButtonsContainer, ErrorDisplay } from 'shared/components'
import {
  BankAccountFields,
  bankAccountInitialValues as initialValues,
  bankAccountValidationSchema as validationSchema,
} from 'shared/forms'
import { setFormError } from 'shared/services'

import {
  BANK_ACCOUNTS_QUERY,
  CREATE_BANK_ACCOUNT_MUTATION,
} from '../queries/bank_accounts'
import { translateGuitaError } from '../services/error_messages'

import type {
  CreateBankAccountData,
  CreateBankAccountVars,
} from '../queries/bank_accounts'
import type { FormikProps } from 'formik'
import type { BankAccountFormValues as FormValues } from 'shared/forms'

type InnerFormProps = FormikProps<FormValues> & {
  handleBack: () => void
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  handleBack,
}: InnerFormProps) => (
  <Form>
    <BankAccountFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={handleBack}
          variant='outlined'
        >
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Crear
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type BankAccountCreatorProps = {
  handleBack: () => void
  handleNext: () => void
  userId: string
}

export const BankAccountCreator = ({
  handleBack,
  handleNext,
  userId,
}: BankAccountCreatorProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [createBankAccount] =
    useMutation<CreateBankAccountData, CreateBankAccountVars>(CREATE_BANK_ACCOUNT_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [BANK_ACCOUNTS_QUERY],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await createBankAccount({
      variables: {
        userId,
        ...values,
      },
    })

    if (response.data?.createBankAccount === 'OK!') {
      handleNext()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <InnerForm
          handleBack={handleBack}
          {...props}
        />
      )}
    </Formik>
  )
}
