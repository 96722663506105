import * as React from 'react'

import { Button, Stack } from '@mui/material'

import { Image } from 'shared/components'

import { DetailedUser } from '../../../../queries/users'
import ListDisplay from '../list_display'

const BORDER_STYLE = '1px dotted grey'
const CANVAS_HEIGHT = 150
const CANVAS_WIDTH = 300

type SignatureDetailsDisplayProps = {
  user: DetailedUser
}

const SignatureDetailsDisplay = ({
  user,
}: SignatureDetailsDisplayProps) => {
  const imageUrl = user.electronicSignature?.imageUrl

  if (!imageUrl) {
    return
  }

  return (
    <Stack
      paddingTop={3}
      paddingBottom={2}
      spacing={3}
      alignItems='center'
    >
      <Image
        src={imageUrl}
        alt='Firma electrónica'
        duration={300}
        style={{
          border: BORDER_STYLE,
          height: CANVAS_HEIGHT,
          maxWidth: CANVAS_WIDTH,
        }}
      />
      <Button
        download={`${user.nickName.replace(' ', '_')}_signature.png`}
        href={imageUrl}
        variant='contained'
      >
        Descargar
      </Button>
    </Stack>
  )
}

type SignatureDisplayProps = {
  user: DetailedUser
}

const SignatureDisplay = ({
  user,
}: SignatureDisplayProps) => (
  <ListDisplay
    title='Firma electrónica'
    item={user.electronicSignature}
  >
    {() => <SignatureDetailsDisplay user={user} />}
  </ListDisplay>
)

export default SignatureDisplay
