import { gql } from '@apollo/client'

import { ConversionCurrency, QuoteSymbol } from 'shared/queries'

export type OnboardingType = 'BASIC' | 'ADVANCED'

export type Domain = {
  id: string
  name: string
  onboardingType: OnboardingType
  quoteSymbol: QuoteSymbol
  conversionCurrency: ConversionCurrency
  dashboardLink: string
}

const DOMAIN_FIELDS = gql`
  fragment DomainFields on Domain {
    id
    name
    onboardingType
    quoteSymbol
    conversionCurrency
    dashboardLink
  }
`

export type DomainVars = Record<string, never>

export type DomainData = {
  domain: Domain
}

export const DOMAIN_QUERY = gql`
  ${DOMAIN_FIELDS}
  query Domain {
    domain {
      ...DomainFields
    }
  }
`
