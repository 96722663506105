import * as React from 'react'

import { GridDivider } from 'shared/components'

import BusinessIdentityDisplay from './business_identity_display'
import FundsOriginDisplay from './funds_origin_display'
import PhoneNumberDisplay from './phone_number_display'
import SignatureDisplay from './signature_display'
import UltimateBeneficialOwnerCreator from './ultimate_beneficial_owner_creator'
import UltimateBeneficialOwnersDisplay from './ultimate_beneficial_owners_display'
import AdministrativeDocuments from '../administrative_documents'
import LegalAddressDisplay from '../legal_address_display'
import LegalIdentityProofsDisplay from '../legal_identity_proofs_display'
import LivenessProofsDisplay from '../liveness_proofs_display'
import RiskRatingsDisplay from '../risk_ratings_display'

import type { DetailedUser } from '../../../../queries/users'

type BusinessAccountDisplayProps = {
  user: DetailedUser
}

const BusinessAccountDisplay = ({
  user,
}: BusinessAccountDisplayProps) => (
  <React.Fragment>
    <GridDivider>Perfil de riesgo</GridDivider>
    <RiskRatingsDisplay user={user} />
    <GridDivider>Información principal</GridDivider>
    <BusinessIdentityDisplay
      userId={user.id}
      userEmail={user.email}
      businessIdentity={user.businessIdentity}
    />
    <PhoneNumberDisplay
      userId={user.id}
      phoneNumber={user.phoneNumber}
    />
    <LegalAddressDisplay
      userId={user.id}
      legalAddress={user.legalAddress}
    />
    <SignatureDisplay user={user} />
    <GridDivider>Documentos de verificación</GridDivider>
    <LivenessProofsDisplay livenessProofs={user.livenessProofs || []} />
    <LegalIdentityProofsDisplay
      userId={user.id}
      isBusiness={true}
      legalIdentityProofs={user.legalIdentityProofs || []}
    />
    <GridDivider>Personas naturales</GridDivider>
    <UltimateBeneficialOwnersDisplay
      userId={user.id}
      ultimateBeneficialOwners={user.ultimateBeneficialOwners}
    />
    <UltimateBeneficialOwnerCreator userId={user.id} />
    <GridDivider>Declaración de origen de fondos</GridDivider>
    <FundsOriginDisplay
      userId={user.id}
      fundsOrigin={user.fundsOrigin}
      isBusiness={true}
    />
    <GridDivider>Documentos administrativos</GridDivider>
    <AdministrativeDocuments userId={user.id} />
  </React.Fragment>
)

export default BusinessAccountDisplay
