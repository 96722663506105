import { useQuery } from '@apollo/client'

import { MARKET_ASSETS_QUERY } from 'shared/queries'
import { hasApyWithSymbol } from 'shared/services'

import { BASE_APYS_QUERY } from '../queries/base_apys'

import type { BaseApysData, BaseApysVars } from '../queries/base_apys'
import type { MarketAssetsData, MarketAssetsVars } from 'shared/queries'

export const useHasApyWithSymbol = (symbol: string, userId?: string) => {
  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: baseApysLoading, data: baseApysData } =
    useQuery<BaseApysData, BaseApysVars>(BASE_APYS_QUERY, {
      variables: {
        userId,
      },
    })

  const marketAssets = assetsData?.marketAssets || []
  const baseApys = baseApysData?.baseApys || []

  return {
    loading: assetsLoading || baseApysLoading,
    hasApy: hasApyWithSymbol(baseApys, marketAssets, symbol),
  }
}
