import * as React from 'react'

import { useMutation } from '@apollo/client'
import {
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, Select, TextField } from 'formik-mui'
import { DesktopDateTimePicker } from 'formik-mui-x-date-pickers'
import * as Yup from 'yup'

import {
  AssetBadge,
  ButtonContainer,
  ButtonsContainer,
  Currency,
  ErrorDisplay,
} from 'shared/components'
import {
  getSymbol,
  isValidAssetAmount,
  setFormError,
  toISO8601DateTime,
} from 'shared/services'

import { CREATE_SWAP_MUTATION } from '../queries/swaps'
import { USER_OPERATIONS_QUERY } from '../queries/user_operations'
import { translateGuitaError } from '../services/error_messages'

import type { CreateSwapData, CreateSwapVars } from '../queries/swaps'
import type { FormikProps } from 'formik'
import type { MarketAsset } from 'shared/queries'

type FormValues = {
  fromSymbol: string
  fromAmount: number
  notifyUser: boolean
  timestamp: Date | null
  toSymbol: string
  toAmount: number
  useMaxAmount: boolean
}

const initialValues: FormValues = ({
  fromSymbol: '',
  fromAmount: 0,
  notifyUser: true,
  timestamp: null,
  toSymbol: '',
  toAmount: 0,
  useMaxAmount: false,
})

type TotalAmounts = {
  [symbol: string]: number
}

const validationSchema = (totalAmounts: TotalAmounts): Yup.SchemaOf<FormValues> => (
  Yup.object().shape({
    fromSymbol: Yup.string()
      .required('Este campo es obligatorio')
      .nullable(),
    fromAmount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .test(
        'validFormat',
        'Introduce un número con máximo 6 decimales',
        (value, context) => context.parent.useMaxAmount || isValidAssetAmount(value),
      )
      .test(
        'maxAmount',
        'Debes ingresar un monto menor o igual al balance disponible',
        (value, context) => {
          if (!value) {
            return false
          }

          const fromSymbol = context.parent.fromSymbol

          if (!fromSymbol) {
            return false
          }

          const totalAmount = totalAmounts[fromSymbol]

          if (!totalAmount) {
            return false
          }

          return value <= totalAmount
        },
      ),
    notifyUser: Yup.boolean()
      .required('Este campo es obligatorio'),
    timestamp: Yup.date()
      .required('Este campo es obligatorio'),
    toSymbol: Yup.string()
      .required('Este campo es obligatorio')
      .test(
        'notEqualToFromSymbol',
        'El activo de destino no puede ser el mismo de origen',
        (value, context) => value !== context.parent.fromSymbol,
      ),
    toAmount: Yup.number()
      .required('Este campo es obligatorio')
      .test(
        'validFormat',
        'Introduce un número con máximo 6 decimales',
        (value) => isValidAssetAmount(value),
      ),
    useMaxAmount: Yup.boolean()
      .required('Este campo es obligatorio'),
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  cancelCreate: () => void
  marketAssets: MarketAsset[]
  totalAmounts: TotalAmounts
}

export const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  setFieldValue,
  setFieldTouched,
  values,
  cancelCreate,
  marketAssets,
  totalAmounts,
}: InnerFormProps) => {
  const { fromSymbol, toSymbol, useMaxAmount } = values

  const onFromSymbolChange = () => {
    setFieldValue('fromAmount', 0)
    setFieldTouched('fromAmount', false, false)
    setFieldValue('useMaxAmount', false)
    setFieldTouched('useMaxAmount', false, false)
    setFieldValue('toSymbol', '')
    setFieldTouched('toSymbol', false, false)
  }

  const onUseMaxAmountChange = () => {
    if (!fromSymbol) {
      return
    }

    const newUseMaxAmount = !useMaxAmount
    setFieldValue('useMaxAmount', newUseMaxAmount)
    setFieldValue('fromAmount', newUseMaxAmount ? totalAmounts[fromSymbol] : 0)
    setFieldTouched('fromAmount', false, false)
  }

  return (
    <Form>
      <Stack spacing={3}>
        <Divider>De</Divider>
        <Field
          required
          name='fromSymbol'
          type='text'
          label='Activo de origen'
          onChange={onFromSymbolChange}
          component={Select}
        >
          {marketAssets?.map((asset) => (
            <MenuItem
              key={asset.symbol}
              value={asset.symbol}
            >
              <AssetBadge
                symbol={asset.symbol}
                height={20}
                style={{ marginRight: 10 }}
              />
              {asset.name}
            </MenuItem>
          ))}
        </Field>
        <Field
          required
          name='fromAmount'
          type='number'
          label='Monto a convertir'
          component={TextField}
          disabled={!fromSymbol || useMaxAmount}
          inputProps={{
            autoComplete: 'off',
            inputMode: 'numeric',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>{getSymbol(fromSymbol)}</small>
              </InputAdornment>
            ),
            onClick: (event: React.MouseEvent<HTMLInputElement>) => {
              event.currentTarget.querySelector('input')?.select()
            },
          }}
          sx={{ flexGrow: 1 }}
        />
        <Field
          component={CheckboxWithLabel}
          disabled={!fromSymbol}
          onChange={onUseMaxAmountChange}
          type='checkbox'
          name='useMaxAmount'
          Label={{
            label: (
              <React.Fragment>
                Usar el máximo disponible (aprox.&nbsp;
                <Currency
                  currency={getSymbol(fromSymbol)}
                  value={totalAmounts[fromSymbol]}
                />)
              </React.Fragment>
            ),
          }}
        />
        <Divider>A</Divider>
        <Field
          required
          name='toSymbol'
          type='text'
          label='Activo de destino'
          component={Select}
        >
          {marketAssets?.map((asset) => (
            <MenuItem
              key={asset.symbol}
              value={asset.symbol}
            >
              <AssetBadge
                symbol={asset.symbol}
                height={20}
                style={{ marginRight: 10 }}
              />
              {asset.name}
            </MenuItem>
          ))}
        </Field>
        <Field
          required
          name='toAmount'
          type='number'
          label='Monto obtenido'
          component={TextField}
          disabled={!toSymbol}
          inputProps={{
            autoComplete: 'off',
            inputMode: 'numeric',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>{getSymbol(toSymbol)}</small>
              </InputAdornment>
            ),
            onClick: (event: React.MouseEvent<HTMLInputElement>) => {
              event.currentTarget.querySelector('input')?.select()
            },
          }}
          sx={{ flexGrow: 1 }}
        />
        <Field
          required
          name='timestamp'
          type='date'
          label='Fecha y hora (local)'
          component={DesktopDateTimePicker}
          views={['year', 'month', 'day']}
          inputFormat='yyyy/MM/dd HH:mm:ss'
          toolbarTitle='Fecha y hora (local)'
          textField={{ fullWidth: true, margin: 'normal' }}
          closeOnSelect
          disableFuture
        />
        <Field
          component={CheckboxWithLabel}
          type='checkbox'
          name='notifyUser'
          Label={{ label: 'Notificar por mail al usuario' }}
        />
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting}
            onClick={cancelCreate}
            variant='outlined'
            color='secondary'
          >
            Cancelar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            color='error'
          >
            Confirmar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type SwapCreatorProps = {
  cancelCreate: () => void
  closeDialog: () => void
  marketAssets: MarketAsset[]
  totalAmounts: TotalAmounts
  userId: string
}

const SwapCreator = ({
  cancelCreate,
  closeDialog,
  marketAssets,
  totalAmounts,
  userId,
}: SwapCreatorProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [createSwap] = useMutation<CreateSwapData, CreateSwapVars>(
    CREATE_SWAP_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_OPERATIONS_QUERY, variables: { userId } },
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await createSwap({
      variables: {
        userId,
        ...values,
        fromAmount: values.fromAmount.toString(),
        toAmount: values.toAmount.toString(),
        timestamp: toISO8601DateTime(values.timestamp),
      },
    })

    if (response.data?.createSwap) {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema(totalAmounts)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            cancelCreate={cancelCreate}
            marketAssets={marketAssets}
            totalAmounts={totalAmounts}
            {...props}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}

export default SwapCreator
