import { gql } from '@apollo/client'

export type CreateUltimateBeneficialOwnerVars = {
  city: string
  countryCode: string
  familyName: string
  givenName: string
  identificationNumber: string
  isChileanResident: boolean
  isPoliticallyExposed: boolean
  nationality: string
  participationPercentage?: number
  roles: string[]
  streetAddress: string
  userId: string
}

export type CreateUltimateBeneficialOwnerData = {
  createUltimateBeneficialOwner: string
}

export const CREATE_ULTIMATE_BENEFICIAL_OWNER_MUTATION = gql`
  mutation CreateUltimateBeneficialOwner(
    $city: String!
    $countryCode: String!
    $familyName: String!
    $givenName: String!
    $identificationNumber: String!
    $isChileanResident: Boolean!
    $isPoliticallyExposed: Boolean!
    $nationality: String!
    $participationPercentage: Float
    $roles: [String!]!
    $streetAddress: String!
    $userId: ID!
  ) {
    createUltimateBeneficialOwner(
      input: {
        city: $city
        countryCode: $countryCode
        familyName: $familyName
        givenName: $givenName
        identificationNumber: $identificationNumber
        isChileanResident: $isChileanResident
        isPoliticallyExposed: $isPoliticallyExposed
        nationality: $nationality
        participationPercentage: $participationPercentage
        roles: $roles
        streetAddress: $streetAddress
        userId: $userId
      }
    )
  }
`

export type DeleteUltimateBeneficialOwnerVars = {
  ultimateBeneficialOwnerId: string
  userId: string
}

export type DeleteUltimateBeneficialOwnerData = {
  deleteUltimateBeneficialOwner: string
}

export const DELETE_ULTIMATE_BENEFICIAL_OWNER_MUTATION = gql`
  mutation DeleteUltimateBeneficialOwner(
    $ultimateBeneficialOwnerId: ID!
    $userId: ID!
  ) {
    deleteUltimateBeneficialOwner(
      input: {
        ultimateBeneficialOwnerId: $ultimateBeneficialOwnerId
        userId: $userId
      }
    )
  }
`
