import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Edit } from '@mui/icons-material'
import { Button, IconButton, MenuItem, Stack } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik, FormikProps } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay } from 'shared/components'
import { setFormError } from 'shared/services'

import DynamicConfigValueField from './dynamic_config_value_field'
import {
  DYNAMIC_CONFIG_QUERY,
  UPDATE_USER_DYNAMIC_CONFIG_MUTATION,
  VALUE_TYPE_LABELS,
} from '../../../queries/dynamic_configs'
import { translateGuitaError } from '../../../services/error_messages'

import type {
  DynamicConfig,
  UpdateUserDynamicConfigData,
  UpdateUserDynamicConfigVars,
  UserDynamicConfig,
  ValueType,
} from '../../../queries/dynamic_configs'

type FormValues = {
  user: string
  name: string
  value: string
  valueType: ValueType
}

const initialValues = (dynamicConfig: DynamicConfig, userDynamicConfig: UserDynamicConfig): FormValues => ({
  user: `ID: ${userDynamicConfig.user.id} / ${userDynamicConfig.user.email}`,
  name: dynamicConfig.name,
  value: userDynamicConfig.value,
  valueType: dynamicConfig.valueType,
})

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  user: Yup.string()
    .required('Este campo es obligatorio'),
  name: Yup.string()
    .required('Este campo es obligatorio'),
  value: Yup.string()
    .required('Este campo es obligatorio'),
  valueType: Yup.mixed()
    .oneOf(Object.keys(VALUE_TYPE_LABELS))
    .required('Este campo es obligatorio'),
})

type UpdateUserDynamicConfigFormProps = FormikProps<FormValues> & {
  closeDialog: () => void
  dynamicConfig: DynamicConfig
  userDynamicConfig: UserDynamicConfig
}

const UpdateUserDynamicConfigForm = ({
  closeDialog,
  dynamicConfig,
  isSubmitting,
  isValid,
  status,
  submitForm,
}: UpdateUserDynamicConfigFormProps) => (
  <Form>
    <Stack spacing={3}>
      <Field
        required
        disabled
        name='user'
        type='string'
        label='Usuario'
        component={TextField}
        margin='normal'
        fullWidth
      />
      <Field
        required
        disabled
        name='name'
        type='string'
        label='Nombre'
        component={TextField}
        margin='normal'
        fullWidth
      />
      <Field
        required
        disabled
        name='valueType'
        type='text'
        label='Tipo'
        component={Select}
      >
        {Object.entries(VALUE_TYPE_LABELS).map(([valueType, valueLabel]) => (
          <MenuItem
            key={valueType}
            value={valueType}
          >
            {valueLabel}
          </MenuItem>
        ))}
      </Field>
      <DynamicConfigValueField valueType={dynamicConfig.valueType} />
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={closeDialog}
          variant='outlined'
          color='secondary'
        >
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          Actualizar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type DynamicConfigUpdaterProps = {
  dynamicConfig: DynamicConfig
  userDynamicConfig: UserDynamicConfig
}

export const UserDynamicConfigUpdater = ({
  dynamicConfig,
  userDynamicConfig,
}: DynamicConfigUpdaterProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [updateUserDynamicConfig] =
    useMutation<UpdateUserDynamicConfigData, UpdateUserDynamicConfigVars>(
      UPDATE_USER_DYNAMIC_CONFIG_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          { query: DYNAMIC_CONFIG_QUERY, variables: { configId: dynamicConfig.id } },
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateUserDynamicConfig({
      variables: {
        configId: dynamicConfig.id,
        userId: userDynamicConfig.user.id,
        value: values.value,
      },
    })

    if (response.data?.updateUserDynamicConfig) {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <IconButton
        aria-label='Editar'
        onClick={openDialog}
      >
        <Edit />
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Modificar configuración de usuario'
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <Formik
            innerRef={formRef}
            initialValues={initialValues(dynamicConfig, userDynamicConfig)}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <UpdateUserDynamicConfigForm
                closeDialog={closeDialog}
                dynamicConfig={dynamicConfig}
                userDynamicConfig={userDynamicConfig}
                {...props}
              />
            )}
          </Formik>
        </LocalizationProvider>
      </Dialog>
    </React.Fragment>
  )
}

export default UserDynamicConfigUpdater
