import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Add } from '@mui/icons-material'
import { Button, Fab, MenuItem, Stack } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik, FormikProps } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay } from 'shared/components'
import { setFormError } from 'shared/services'

import DynamicConfigValueField from './views/dynamic_config/dynamic_config_value_field'
import {
  CREATE_DYNAMIC_CONFIG_MUTATION,
  DYNAMIC_CONFIGS_QUERY,
  VALUE_TYPE_LABELS,
} from '../queries/dynamic_configs'
import { translateGuitaError } from '../services/error_messages'

import type {
  CreateDynamicConfigData,
  CreateDynamicConfigVars,
  ValueType,
} from '../queries/dynamic_configs'

type FormValues = {
  name: string
  value: string
  valueType: ValueType
}

const initialValues = (): FormValues => ({
  name: '',
  value: '',
  valueType: 'string',
})

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  name: Yup.string()
    .required('Este campo es obligatorio'),
  value: Yup.string()
    .required('Este campo es obligatorio'),
  valueType: Yup.mixed()
    .oneOf(Object.keys(VALUE_TYPE_LABELS))
    .required('Este campo es obligatorio'),
})

const CreateDynamicConfigForm = ({
  closeDialog,
  isSubmitting,
  isValid,
  status,
  values,
  submitForm,
}: FormikProps<FormValues> & { closeDialog: () => void }) => (
  <Form>
    <Stack spacing={3}>
      <Field
        required
        name='name'
        type='string'
        label='Nombre'
        component={TextField}
        margin='normal'
        fullWidth
      />
      <Field
        required
        name='valueType'
        type='text'
        label='Tipo'
        component={Select}
      >
        {Object.entries(VALUE_TYPE_LABELS).map(([valueType, valueLabel]) => (
          <MenuItem
            key={valueType}
            value={valueType}
          >
            {valueLabel}
          </MenuItem>
        ))}
      </Field>
      <DynamicConfigValueField valueType={values.valueType} />
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={closeDialog}
          variant='outlined'
          color='secondary'
        >
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          {isSubmitting ? 'Creando...' : 'Crear configuración'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

const DynamicConfigCreator = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [createDynamicConfig] =
    useMutation<CreateDynamicConfigData, CreateDynamicConfigVars>(CREATE_DYNAMIC_CONFIG_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        DYNAMIC_CONFIGS_QUERY,
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await createDynamicConfig({
      variables: values,
    })

    if (response.data?.createDynamicConfig) {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Crear configuración global'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Crear configuración global'
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <Formik
            innerRef={formRef}
            initialValues={initialValues()}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <CreateDynamicConfigForm
                closeDialog={closeDialog}
                {...props}
              />
            )}
          </Formik>
        </LocalizationProvider>
      </Dialog>
    </React.Fragment>
  )
}

export default DynamicConfigCreator
