import * as React from 'react'

import { AutoGraph } from '@mui/icons-material'
import {
  ListItem,
  ListItemText,
} from '@mui/material'

import {
  Currency,
  DatedList,
  DatedListItem,
} from 'shared/components'

import BulkPurchaseUpdater from './bulk_purchase_updater'

import type { SupplierBulkPurchase } from '../queries/bulk_purchases'

type BulkPurchaseDisplayProps = {
  bulkPurchase: SupplierBulkPurchase
  onClick: (bp: SupplierBulkPurchase) => void
}

const BulkPurchaseDisplay = ({
  bulkPurchase,
  onClick,
}: BulkPurchaseDisplayProps) => (
  <DatedListItem
    icon={<AutoGraph />}
    text={(
      <React.Fragment>
        Orden {bulkPurchase.id}
        <br />
        <small>Usuario&nbsp;{bulkPurchase.userId}</small>
      </React.Fragment>
    )}
    timestamp={bulkPurchase.timestamp}
    onClick={() => onClick(bulkPurchase)}
  >
    <ListItemText
      sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}
      primary={(
        <Currency
          currency='CLP'
          digits={0}
          value={bulkPurchase.inAmount}
        />
      )}
      secondary='Sin precio de compra'
    />

  </DatedListItem>
)

type BlancBulkPurchasesListProps = {
  bulkPurchases: SupplierBulkPurchase[]
}

const BlancBulkPurchasesList = ({
  bulkPurchases,
}: BlancBulkPurchasesListProps) => {
  const [editBulkPurchase, setEditBulkPurchase] = React.useState<SupplierBulkPurchase | null>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const handleClick = (bulkPurchase: SupplierBulkPurchase) => {
    setEditBulkPurchase(bulkPurchase)
    setDialogOpen(true)
  }

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <DatedList
        emptyListDisplay={(
          <ListItem>
            <ListItemText
              primary='No hay órdenes en blanco'
              primaryTypographyProps={{ color: 'text.secondary', textAlign: 'center' }}
            />
          </ListItem>
        )}
        items={bulkPurchases.map((bulkPurchase) => ({
          timestamp: bulkPurchase.timestamp * 1000,
          component: (
            <BulkPurchaseDisplay
              bulkPurchase={bulkPurchase}
              onClick={handleClick}
            />
          ),
        }))}
      />
      {editBulkPurchase && (
        <BulkPurchaseUpdater
          bulkPurchase={editBulkPurchase}
          dialogOpen={dialogOpen}
          closeDialog={closeDialog}
        />
      )}
    </React.Fragment>
  )
}

export default BlancBulkPurchasesList
