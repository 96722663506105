import * as React from 'react'

import { GridDivider } from 'shared/components'

import FundsOriginDisplay from './funds_origin_display'
import OccupationProfileDisplay from './occupation_profile_display'
import PhoneNumberDisplay from './phone_number_display'
import SignatureDisplay from './signature_display'
import SpouseIdentityDisplay from './spouse_identity_display'
import AdministrativeDocuments from '../administrative_documents'
import LegalAddressDisplay from '../legal_address_display'
import LegalIdentityDisplay from '../legal_identity_display'
import LegalIdentityProofsDisplay from '../legal_identity_proofs_display'
import LivenessProofsDisplay from '../liveness_proofs_display'
import RiskRatingsDisplay from '../risk_ratings_display'

import type { DetailedUser } from '../../../../queries/users'

type PersonAccountDisplayProps = {
  user: DetailedUser
}

const PersonAccountDisplay = ({
  user,
}: PersonAccountDisplayProps) => (
  <React.Fragment>
    <GridDivider>Perfil de riesgo</GridDivider>
    <RiskRatingsDisplay user={user} />
    <GridDivider>Información personal</GridDivider>
    <LegalIdentityDisplay
      userEmail={user.email}
      userId={user.id}
      legalIdentity={user.legalIdentity}
      onboardingType={user.onboardingType}
    />
    {(user.legalIdentity?.civilState === 'MARRIED') && (
      <SpouseIdentityDisplay
        userId={user.id}
        legalIdentity={user.legalIdentity}
      />
    )}
    <LegalAddressDisplay
      userId={user.id}
      legalAddress={user.legalAddress}
    />
    <PhoneNumberDisplay
      userId={user.id}
      phoneNumber={user.phoneNumber}
    />
    <SignatureDisplay user={user} />
    <GridDivider>Documentos de verificación</GridDivider>
    <LivenessProofsDisplay livenessProofs={user.livenessProofs || []} />
    <LegalIdentityProofsDisplay
      userId={user.id}
      isBusiness={false}
      legalIdentityProofs={user.legalIdentityProofs || []}
    />
    <GridDivider>Perfil</GridDivider>
    <OccupationProfileDisplay
      userId={user.id}
      occupationProfile={user.occupationProfile}
    />
    <GridDivider>Detalles del origen de fondos</GridDivider>
    <FundsOriginDisplay
      userId={user.id}
      fundsOrigin={user.fundsOrigin}
      isBusiness={false}
    />
    <GridDivider>Documentos administrativos</GridDivider>
    <AdministrativeDocuments userId={user.id} />
  </React.Fragment>
)

export default PersonAccountDisplay
