import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { PeopleAlt } from '@mui/icons-material'
import { Button, Fab, MenuItem, Stack } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Dialog,
  ErrorDisplay,
  Loading,
} from 'shared/components'
import { SelectField } from 'shared/forms'
import { setFormError } from 'shared/services'

import UserSelectField from './user_select_field'
import { MANAGED_DOMAINS_QUERY } from '../queries/managed_domains'
import { CLONE_USER_MUTATION } from '../queries/users'
import { translateGuitaError } from '../services/error_messages'

import type { ManagedDomainsData, ManagedDomainsVars } from '../queries/managed_domains'
import type { CloneUserData, CloneUserVars, User } from '../queries/users'
import type { FormikProps } from 'formik'

type FormValues = {
  domainName: string
  user: User | null
}

const initialValues: FormValues = ({
  domainName: '',
  user: null,
})

const validationSchema: Yup.SchemaOf<FormValues> = (
  Yup.object().shape({
    domainName: Yup.string()
      .required('Este campo es obligatorio'),
    user: Yup.object()
      .shape({
        id: Yup.string().required(),
        email: Yup.string().required(),
      })
      .required('Este campo es obligatorio'),
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  managedDomains: string[]
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  managedDomains,
}: InnerFormProps) => (
  <Form>
    <Stack spacing={3}>
      <UserSelectField
        label='Usuario'
        name='user'
      />
      <SelectField
        label='Plataforma'
        name='domainName'
      >
        {managedDomains.map((domain, index) => (
          <MenuItem
            key={index}
            value={domain}
          >
            {domain}
          </MenuItem>
        ))}
      </SelectField>
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={3}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          Confirmar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

const UserCloner = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const { loading, data } =
    useQuery<ManagedDomainsData, ManagedDomainsVars>(MANAGED_DOMAINS_QUERY)

  const managedDomains = data?.managedDomains || []

  const [cloneUser] =
    useMutation<CloneUserData, CloneUserVars>(
      CLONE_USER_MUTATION, {
        errorPolicy: 'all',
      })

  if (loading) {
    return <Loading />
  }

  const handleSubmit = async ({ domainName, user }: FormValues) => {
    if (!user?.id) {
      return
    }

    const response = await cloneUser({
      variables: {
        domainName,
        userId: user.id,
      },
    })

    if (response.data?.cloneUser === 'OK!') {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  return managedDomains.length > 1 && (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Duplicar usuario'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(12),
        })}
      >
        <PeopleAlt />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Duplicar usuario a otra plataforma'
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <InnerForm
              {...props}
              managedDomains={managedDomains}
            />
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  )
}

export default UserCloner
