import * as React from 'react'

import { ListItem, ListItemText } from '@mui/material'

import { civilStateStr } from 'shared/queries'
import { findCountryByCode } from 'shared/services'

import LegalIdentityEditForm from './legal_identity_edit_form'
import ListDisplay from './list_display'

import type { OnboardingType } from '../../../queries/domains'
import type { LegalIdentity } from 'shared/queries'

type LegalIdentityDetailsDisplayProps = {
  userEmail?: string
  legalIdentity: LegalIdentity
}

const LegalIdentityDetailsDisplay = ({
  userEmail,
  legalIdentity,
}: LegalIdentityDetailsDisplayProps) => (
  <React.Fragment>
    {(typeof userEmail !== 'undefined') && (
      <ListItem divider>
        <ListItemText
          primary='Correo electrónico'
          secondary={userEmail}
        />
      </ListItem>
    )}
    <ListItem divider>
      <ListItemText
        primary='Nombre(s)'
        secondary={legalIdentity.givenName}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Apellido(s)'
        secondary={legalIdentity.familyName}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Fecha de nacimiento'
        secondary={legalIdentity.birthdate}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Nacionalidad'
        secondary={(
          findCountryByCode(legalIdentity.nationality)?.name
          || legalIdentity.nationality
          || 'No disponible'
        )}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary={legalIdentity.nationality === 'CL' ? 'RUT' : 'Número de identificación'}
        secondary={legalIdentity.identificationNumber}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Estado civil'
        secondary={civilStateStr(legalIdentity.civilState)}
      />
    </ListItem>
    <ListItem>
      <ListItemText
        primary='Es Persona Expuesta Políticamente (PEP)'
        secondary={legalIdentity.isPoliticallyExposed ? 'Sí' : 'No'}
      />
    </ListItem>
  </React.Fragment>
)

type LegalIdentityDisplayProps = {
  userEmail?: string
  userId: string
  legalIdentity?: LegalIdentity
  onboardingType: OnboardingType
}

const LegalIdentityDisplay = ({
  userEmail,
  userId,
  legalIdentity,
  onboardingType,
}: LegalIdentityDisplayProps) => (
  <ListDisplay
    title='Información básica'
    item={legalIdentity}
    editForm={(closeDialog) => (
      <LegalIdentityEditForm
        closeDialog={closeDialog}
        userId={userId}
        legalIdentity={legalIdentity}
        onboardingType={onboardingType}
      />
    )}
  >
    {(item) => (
      <LegalIdentityDetailsDisplay
        userEmail={userEmail}
        legalIdentity={item}
      />
    )}
  </ListDisplay>
)

export default LegalIdentityDisplay
