import * as React from 'react'

import { Description } from '@mui/icons-material'
import { ListItemText } from '@mui/material'

import {
  DatedList,
  DatedListItem,
  EmptyDocumentsDisplay,
  UserDocumentLink,
} from 'shared/components'

import UserDocumentDeleter from './user_document_deleter'

import type {
  UserDocumentView,
} from '../queries/user_documents'

type UserDocumentDisplayProps = {
  children?: React.ReactNode
  document: UserDocumentView
}

const UserDocumentDisplay = ({
  children,
  document,
}: UserDocumentDisplayProps) => {
  const adminLabel = (['ADMINISTRATIVE', 'UBO'].includes(document.documentType))
    ? '(privado)'
    : '(público)'
  const providerText = (document.provider === 'ADMIN')
    ? `Creado por administrador ${adminLabel}`
    : 'Creado por usuario'

  return (
    <DatedListItem
      icon={<Description />}
      text={document.description}
      timestamp={providerText}
    >
      <ListItemText
        disableTypography
        sx={(theme) => ({ textAlign: 'right', minWidth: theme.spacing(5) })}
      >
        {children}
        <UserDocumentLink storageUrl={document.storageUrl} />
      </ListItemText>
    </DatedListItem>
  )
}

type UserDocumentsListProps = {
  userId: string
  loading: boolean
  documents: UserDocumentView[]
}

const UserDocumentsList = ({
  userId,
  loading,
  documents,
}: UserDocumentsListProps) => (
  <DatedList
    emptyListDisplay={<EmptyDocumentsDisplay />}
    loading={loading}
    items={documents.map((document) => ({
      timestamp: document.timestamp * 1000,
      component: (
        <UserDocumentDisplay document={document}>
          <UserDocumentDeleter
            userId={userId}
            userDocumentId={document.id}
          />
        </UserDocumentDisplay>
      ),
    }))}
  />
)

export default UserDocumentsList
