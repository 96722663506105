import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, InputAdornment, MenuItem, Stack } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik, FormikProps } from 'formik'
import { CheckboxWithLabel, Select, TextField } from 'formik-mui'
import { DesktopDateTimePicker } from 'formik-mui-x-date-pickers'
import * as Yup from 'yup'

import {
  AssetBadge,
  ButtonContainer,
  ButtonsContainer,
  ErrorDisplay,
} from 'shared/components'
import { getSymbol, setFormError, toISO8601DateTime } from 'shared/services'

import { CREATE_DEPOSIT_MUTATION } from '../queries/deposits'
import { USER_OPERATIONS_QUERY } from '../queries/user_operations'
import { translateGuitaError } from '../services/error_messages'
import { isManagedDomain } from '../services/managed_domain'

import type { CreateDepositData, CreateDepositVars } from '../queries/deposits'
import type { MarketAsset } from 'shared/queries'

const isAicTech = () => isManagedDomain('AicTech')

type FormValues = {
  marketAssetId: string
  blockchain: string
  changedAmount: number
  timestamp: Date | null
  address: string
  notifyUser: boolean
}

const initialValues = (): FormValues => ({
  marketAssetId: '',
  blockchain: isAicTech() ? 'Dummy' : '',
  changedAmount: 0,
  timestamp: null,
  address: isAicTech() ? 'AICapital' : '',
  notifyUser: true,
})

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  marketAssetId: Yup.string()
    .required('Este campo es obligatorio'),
  blockchain: Yup.string()
    .required('Este campo es obligatorio'),
  changedAmount: Yup.number()
    .positive('El monto debe ser mayor a cero')
    .required('Este campo es obligatorio'),
  timestamp: Yup.date()
    .required('Este campo es obligatorio'),
  address: Yup.string()
    .required('Este campo es obligatorio'),
  notifyUser: Yup.boolean()
    .required('Este campo es obligatorio'),
})

type InnerFormProps = FormikProps<FormValues> & {
  cancelCreate: () => void
  marketAssets: MarketAsset[]
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  values,
  cancelCreate,
  marketAssets,
}: InnerFormProps) => {
  const marketAsset = marketAssets.find(({ id }) => id === values.marketAssetId)
  const blockchains = marketAsset?.blockchains || []

  return (
    <Form>
      <Stack spacing={3}>
        <Field
          required
          name='marketAssetId'
          type='text'
          label='Activo'
          component={Select}
        >
          {marketAssets?.map((asset) => (
            <MenuItem
              key={asset.id}
              value={asset.id}
            >
              <AssetBadge
                symbol={asset.symbol}
                height={20}
                style={{ marginRight: 10 }}
              />
              {asset.name}
            </MenuItem>
          ))}
        </Field>
        {!isAicTech() && (
          <Field
            required
            name='blockchain'
            type='text'
            label='Blockchain'
            component={Select}
            disabled={!marketAsset}
          >
            {blockchains.map((blockchain) => (
              <MenuItem
                key={blockchain}
                value={blockchain}
              >
                {blockchain}
              </MenuItem>
            ))}
          </Field>
        )}
        <Field
          required
          name='changedAmount'
          type='number'
          label='Monto'
          component={TextField}
          disabled={!marketAsset}
          inputProps={{
            autoComplete: 'off',
            inputMode: 'numeric',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>{getSymbol(marketAsset?.symbol)}</small>
              </InputAdornment>
            ),
            onClick: (event: React.MouseEvent<HTMLInputElement>) => {
              event.currentTarget.querySelector('input')?.select()
            },
          }}
          sx={{
            flexGrow: 1,
            my: 2,
          }}
        />
        <Field
          required
          name='timestamp'
          type='date'
          label='Fecha y hora (local)'
          component={DesktopDateTimePicker}
          disabled={!marketAsset}
          views={['year', 'month', 'day']}
          inputFormat='yyyy/MM/dd HH:mm:ss'
          toolbarTitle='Fecha y hora (local)'
          textField={{ fullWidth: true, margin: 'normal' }}
          closeOnSelect
          disableFuture
        />
        {!isAicTech() && (
          <Field
            required
            inputProps={{ autoComplete: 'off' }}
            name='address'
            type='text'
            label='Dirección de depósito'
            component={TextField}
            disabled={!marketAsset}
            margin='normal'
            fullWidth
          />
        )}
        <Field
          required
          component={CheckboxWithLabel}
          disabled={!marketAsset}
          type='checkbox'
          name='notifyUser'
          Label={{ label: 'Notificar por mail al usuario' }}
        />
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={2}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting}
            onClick={cancelCreate}
            variant='outlined'
            color='secondary'
          >
            Cancelar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            color='error'
          >
            Confirmar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type DepositCreatorProps = {
  cancelCreate: () => void
  closeDialog: () => void
  marketAssets: MarketAsset[]
  userId: string
}

const DepositCreator = ({
  cancelCreate,
  closeDialog,
  marketAssets,
  userId,
}: DepositCreatorProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [createDeposit] =
    useMutation<CreateDepositData, CreateDepositVars>(CREATE_DEPOSIT_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_OPERATIONS_QUERY, variables: { userId } },
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await createDeposit({
      variables: {
        userId,
        ...values,
        changedAmount: values.changedAmount.toString(),
        timestamp: toISO8601DateTime(values.timestamp),
      },
    })

    if (response.data?.createDeposit) {
      closeDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            cancelCreate={cancelCreate}
            marketAssets={marketAssets}
            {...props}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}

export default DepositCreator
