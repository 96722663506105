import { gql } from '@apollo/client'

export type UpdateBusinessIdentityVars = {
  userId: string
  companyName: string
  identificationNumber: string
  siiActivityCode: string
  societyType: string
}

export type UpdateBusinessIdentityData = {
  updateBusinessIdentity: string
}

export const UPDATE_BUSINESS_IDENTITY_MUTATION = gql`
  mutation UpdateBusinessIdentity(
    $userId: ID!
    $companyName: String!
    $identificationNumber: String!
    $siiActivityCode: String!
    $societyType: String!
  ) {
    updateBusinessIdentity(input: {
      userId: $userId
      companyName: $companyName
      identificationNumber: $identificationNumber
      siiActivityCode: $siiActivityCode
      societyType: $societyType
    })
  }
`
