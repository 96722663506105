import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'

import { ButtonContainer, ButtonsContainer, ErrorDisplay } from 'shared/components'
import {
  PhoneNumberFields,
  getPhoneNumberInitialValues as getInitialValues,
  phoneNumberValidationSchema as validationSchema,
} from 'shared/forms'
import { setFormError } from 'shared/services'

import { UPDATE_PHONE_NUMBER_MUTATION } from '../../../../queries/phone_numbers'
import { USER_QUERY } from '../../../../queries/users'
import { translateGuitaError } from '../../../../services/error_messages'

import type {
  UpdatePhoneNumberData,
  UpdatePhoneNumberVars,
} from '../../../../queries/phone_numbers'
import type { PhoneNumberFormValues as FormValues } from 'shared/forms'
import type { PhoneNumber } from 'shared/queries'

type InnerFormProps = FormikProps<FormValues> & {
  isPresent: boolean
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  isPresent,
}: InnerFormProps) => (
  <Form>
    <PhoneNumberFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          {isPresent ? 'Modificar' : 'Crear'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type PhoneNumberEditFormProps = {
  closeDialog: () => void
  userId: string
  phoneNumber?: PhoneNumber
}

const PhoneNumberEditForm = ({
  closeDialog,
  userId,
  phoneNumber,
}: PhoneNumberEditFormProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [updatePhoneNumber] =
    useMutation<UpdatePhoneNumberData, UpdatePhoneNumberVars>(UPDATE_PHONE_NUMBER_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_QUERY, variables: { userId } },
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await updatePhoneNumber({
      variables: {
        userId,
        primary: values.primaryPhoneNumber,
        secondary: values.secondaryPhoneNumber,
      },
    })

    if (response.data?.updatePhoneNumber === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={getInitialValues(phoneNumber)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <InnerForm
          {...props}
          isPresent={!!phoneNumber}
        />
      )}
    </Formik>
  )
}

export default PhoneNumberEditForm
