import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid, Typography } from '@mui/material'

import { FundDisplay, GridDivider, Loading, SeoHeaders } from 'shared/components'

import { FundOperationCreator } from './fund_operation_creator'
import { FUND_QUERY } from '../../../queries/funds'
import { asFundOperationsArray } from '../../../services/funds'
import { FundDetailsTable } from '../../fund_details_table'
import { FundOperationsList } from '../../fund_operations_list'
import { FundUpdater } from '../../fund_updater'
import { Balance } from '../main_funds'

import type { FundData, FundVars } from '../../../queries/funds'
import type { RouteComponentProps } from '@reach/router'

type FundDetailsProps = RouteComponentProps & {
  id?: string
}

const MainFundsDetails = (props: FundDetailsProps) => {
  const { loading, data } = useQuery<FundData, FundVars>(FUND_QUERY, {
    variables: {
      fundId: props.id || '',
    },
  })

  if (loading) {
    return <Loading />
  }

  const fund = data?.fund

  return fund ? (
    <React.Fragment>
      <SeoHeaders title={`Fondos - ${fund.name}`} />
      <Grid
        container
        spacing={3}
        paddingBottom={12}
      >
        <Balance
          loading={false}
          descriptionText='Monto total'
          currency='USD'
          digits={2}
          value={fund.totalBalance}
        />
        <FundDisplay fund={fund}>
          <FundUpdater fund={fund} />
        </FundDisplay>
        <GridDivider>Situación actual</GridDivider>
        <FundDetailsTable fund={fund} />
        <GridDivider>Historial</GridDivider>
        <FundOperationsList operations={asFundOperationsArray(fund)} />
      </Grid>
      <FundOperationCreator fund={fund} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SeoHeaders title='Fondo no encontrado' />
      <Typography
        variant='h5'
        textAlign='center'
      >
        Fondo no encontrado
      </Typography>
    </React.Fragment>
  )
}

export default MainFundsDetails
