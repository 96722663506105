import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Button, List, ListItem, ListItemText, Skeleton } from '@mui/material'

import { Dialog, ErrorDisplay, UserNotificationContent } from 'shared/components'
import {
  translateUserNotificationStatus,
  translateUserNotificationType,
} from 'shared/queries'
import { localizeISO8601 } from 'shared/services'

import {
  RESOLVE_USER_NOTIFICATION_MUTATION,
  USER_NOTIFICATIONS_QUERY,
} from '../queries/user_notifications'
import { USER_QUERY } from '../queries/users'
import { translateGuitaError } from '../services/error_messages'

import type {
  ResolveUserNotificationData,
  ResolveUserNotificationVars,
  UserNotification,
} from '../queries/user_notifications'
import type { UserData, UserVars } from '../queries/users'

type UserNotificationDialogProps = {
  closeDialog: () => void
  dialogOpen: boolean
  userNotification: UserNotification
}

const UserNotificationDialog = ({
  closeDialog,
  dialogOpen,
  userNotification,
}: UserNotificationDialogProps) => {
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()
  const [updating, setUpdating] = React.useState(false)

  const isResolved = Boolean(userNotification.resolvedAt)

  const { loading, data } =
    useQuery<UserData, UserVars>(USER_QUERY, {
      variables: {
        userId: userNotification.userId,
      },
    })

  const [resolveUserNotification] =
    useMutation<ResolveUserNotificationData, ResolveUserNotificationVars>(
      RESOLVE_USER_NOTIFICATION_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [USER_NOTIFICATIONS_QUERY],
      })

  const handleClick = async () => {
    if (typeof userNotification === 'undefined' || isResolved) {
      closeDialog()
      return
    }

    setUpdating(true)
    const response = await resolveUserNotification({
      variables: {
        userId: userNotification.userId,
        userNotificationId: userNotification.id,
      },
    })

    if (response.data?.resolveUserNotification === 'OK!') {
      setUpdating(false)
      closeDialog()
    } else {
      setErrorMsg(translateGuitaError(response))
      setUpdating(false)
    }
  }

  React.useEffect(() => {
    setErrorMsg(null)
  }, [dialogOpen, setErrorMsg])

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => closeDialog()}
      title='Notificación de usuario'
      maxWidth='xs'
    >
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemText
            primary='Usuario'
            secondary={loading ? (
              <Skeleton animation='wave' />
            ) : (
              `ID: ${data?.user?.id} / ${data?.user?.nickName}`
            )}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary='Tipo'
            secondary={translateUserNotificationType(userNotification.notificationType)}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary='Contenido'
            secondary={<UserNotificationContent notification={userNotification} />}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary='Parámetros exactos'
            secondary={userNotification.content}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary='Fecha de creación'
            secondary={localizeISO8601(userNotification.createdAt)}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary={isResolved ? 'Fecha de resolución' : 'Estado de resolución'}
            secondary={
              (isResolved)
                ? localizeISO8601(userNotification.resolvedAt!)
                : translateUserNotificationStatus(userNotification.resolvedAt)
            }
          />
        </ListItem>
      </List>
      <ErrorDisplay
        errorMsg={errorMsg}
        mt={2}
      />
      <Button
        fullWidth
        color={isResolved ? 'primary' : 'error'}
        variant='contained'
        onClick={handleClick}
        disabled={updating}
      >
        {isResolved ? 'Cerrar' : 'Marcar como resuelta'}
      </Button>
    </Dialog>
  )
}

export default UserNotificationDialog
