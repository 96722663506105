import * as React from 'react'

import { List, ListItem, ListItemText } from '@mui/material'

import { AppContainer } from 'shared/components'
import { localizeISO8601 } from 'shared/services'

import DynamicConfigDeleter from './dynamic_config_deleter'
import DynamicConfigUpdater from './dynamic_config_updater'
import { VALUE_TYPE_LABELS } from '../../../queries/dynamic_configs'
import Loading from '../../loading'

import type { DynamicConfig } from '../../../queries/dynamic_configs'

type DynamicConfigDisplayProps = {
  dynamicConfig?: DynamicConfig
}

const DynamicConfigDisplay = ({
  dynamicConfig,
}: DynamicConfigDisplayProps) => {
  const loading = (typeof dynamicConfig === 'undefined')

  if (loading) {
    return <Loading md={6} />
  }

  return (
    <React.Fragment>
      <AppContainer
        md={6}
        sx={{ height: '100%' }}
      >
        <List sx={{ p: 1 }}>
          <ListItem
            divider
            secondaryAction={(
              <DynamicConfigDeleter dynamicConfig={dynamicConfig} />
            )}
          >
            <ListItemText
              primary='Nombre'
              secondary={dynamicConfig.name}
            />
          </ListItem>
          <ListItem divider>
            <ListItemText
              primary='Tipo'
              secondary={VALUE_TYPE_LABELS[dynamicConfig.valueType]}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='ID'
              secondary={dynamicConfig.id}
            />
          </ListItem>
        </List>
      </AppContainer>
      <AppContainer
        md={6}
        sx={{ height: '100%' }}
      >
        <List sx={{ p: 1 }}>
          <ListItem
            divider
            secondaryAction={(
              <DynamicConfigUpdater dynamicConfig={dynamicConfig} />
            )}
          >
            <ListItemText
              primary='Valor'
              secondary={dynamicConfig.valueType === 'date' ?
                localizeISO8601(dynamicConfig.value) : dynamicConfig.value}
            />
          </ListItem>
          <ListItem divider>
            <ListItemText
              primary='Fecha de creación'
              secondary={localizeISO8601(dynamicConfig.createdAt)}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Fecha de modificación'
              secondary={localizeISO8601(dynamicConfig.updatedAt)}
            />
          </ListItem>
        </List>
      </AppContainer>
    </React.Fragment>
  )
}

export default DynamicConfigDisplay
