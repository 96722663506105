import * as React from 'react'

import BusinessAccountDisplay from './business_account_display'
import PersonAccountDisplay from './person_account_display'

import type { DetailedUser } from '../../../../queries/users'

type AdvancedOnboardingDisplayProps = {
  user: DetailedUser
}

const AdvancedOnboardingDisplay = ({
  user,
}: AdvancedOnboardingDisplayProps) => (
  user.isBusiness ? (
    <BusinessAccountDisplay user={user} />
  ) : (
    <PersonAccountDisplay user={user} />
  )
)

export default AdvancedOnboardingDisplay
