import * as React from 'react'

import { ListItem, ListItemText } from '@mui/material'

import PhoneNumberEditForm from './phone_number_edit_form'
import ListDisplay from '../list_display'

import type { PhoneNumber } from 'shared/queries'

type PhoneNumberDetailsDisplayProps = {
  phoneNumber: PhoneNumber
}

const PhoneNumberDetailsDisplay = ({
  phoneNumber,
}: PhoneNumberDetailsDisplayProps) => (
  <React.Fragment>
    <ListItem divider>
      <ListItemText
        primary='Teléfono principal'
        secondary={phoneNumber.primary}
      />
    </ListItem>
    <ListItem>
      <ListItemText
        primary='Teléfono secundario'
        secondary={phoneNumber.secondary || 'No registrado'}
      />
    </ListItem>
  </React.Fragment>
)

type PhoneNumberDisplayProps = {
  userId: string
  phoneNumber?: PhoneNumber
}

const PhoneNumberDisplay = ({
  userId,
  phoneNumber,
}: PhoneNumberDisplayProps) => (
  <ListDisplay
    title='Teléfonos'
    item={phoneNumber}
    editForm={(closeDialog) => (
      <PhoneNumberEditForm
        closeDialog={closeDialog}
        userId={userId}
        phoneNumber={phoneNumber}
      />
    )}
  >
    {(item) => <PhoneNumberDetailsDisplay phoneNumber={item} />}
  </ListDisplay>
)

export default PhoneNumberDisplay
