import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Add, Download, SwapCalls, Upload } from '@mui/icons-material'
import { Button, Fab, Stack } from '@mui/material'

import { Dialog } from 'shared/components'
import { MARKET_ASSETS_QUERY } from 'shared/queries'
import { asOperationsArray, getTotalAmounts } from 'shared/services'

import DepositCreator from './deposit_creator'
import SwapCreator from './swap_creator'
import WithdrawalCreator from './withdrawal_creator'
import { USER_OPERATIONS_QUERY } from '../queries/user_operations'
import { canCreateDeposit, canCreateSwap, canCreateWithdraw } from '../services/admin_roles'

import type { UserOperationsData, UserOperationsVars } from '../queries/user_operations'
import type { MarketAssetsData, MarketAssetsVars } from 'shared/queries'

type StepKey = 'OPERATION_SELECT' | 'DEPOSIT' | 'SWAP' | 'WITHDRAWAL'

const STEP_LABELS: { [key in StepKey]: string } = {
  OPERATION_SELECT: 'Agregar nueva operación',
  DEPOSIT: 'Agregar depósito',
  SWAP: 'Agregar conversión',
  WITHDRAWAL: 'Agregar retiro',
}

type OperationCreatorProps = {
  userId: string
  adminRole?: string
}

const OperationCreator = ({
  userId,
  adminRole,
}: OperationCreatorProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState<StepKey>('OPERATION_SELECT')

  const { data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY, {
      variables: {
        userId,
      },
    })

  const cancelCreate = () => setActiveStep('OPERATION_SELECT')

  const openDialog = () => {
    cancelCreate()
    setDialogOpen(true)
  }

  const closeDialog = () => setDialogOpen(false)

  const marketAssets = assetsData?.marketAssets || []

  if (marketAssets.length === 0) {
    return
  }

  const symbols = marketAssets.map(({ symbol }) => symbol)
  const operations = asOperationsArray(operationsData?.userOperations)
  const totalAmounts = getTotalAmounts(symbols, operations)

  return (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Crear operación'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title={STEP_LABELS[activeStep]}
        maxWidth='xs'
      >
        {(() => {
          switch (activeStep) {
          case 'OPERATION_SELECT': return (
            <Stack spacing={3}>
              {canCreateDeposit(adminRole) && (
                <Button
                  onClick={() => setActiveStep('DEPOSIT')}
                  endIcon={<Download />}
                  variant='contained'
                  size='large'
                >
                  Depósito
                </Button>
              )}
              {canCreateSwap(adminRole) && (
                <Button
                  onClick={() => setActiveStep('SWAP')}
                  endIcon={<SwapCalls />}
                  variant='contained'
                  size='large'
                >
                  Conversión
                </Button>
              )}
              {canCreateWithdraw(adminRole) && (
                <Button
                  onClick={() => setActiveStep('WITHDRAWAL')}
                  endIcon={<Upload />}
                  variant='contained'
                  size='large'
                >
                  Retiro
                </Button>
              )}
            </Stack>
          )
          case 'DEPOSIT': return (
            <DepositCreator
              cancelCreate={cancelCreate}
              closeDialog={closeDialog}
              marketAssets={marketAssets}
              userId={userId}
            />
          )
          case 'SWAP': return (
            <SwapCreator
              cancelCreate={cancelCreate}
              closeDialog={closeDialog}
              marketAssets={marketAssets}
              totalAmounts={totalAmounts}
              userId={userId}
            />
          )
          case 'WITHDRAWAL' : return (
            <WithdrawalCreator
              cancelCreate={cancelCreate}
              closeDialog={closeDialog}
              marketAssets={marketAssets}
              totalAmounts={totalAmounts}
              userId={userId}
            />
          )
          }
        })()}
      </Dialog>
    </React.Fragment>
  )
}

export default OperationCreator
