import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Engineering, Logout, Security, Settings } from '@mui/icons-material'
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { navigate } from '@reach/router'

import ManagedDomainPicker from './managed_domain_picker'
import { ADMIN_LOGOUT_MUTATION } from '../queries/auth'
import { clearCredential, getCredential } from '../services/auth'
import { clearManagedDomain } from '../services/managed_domain'

import type { AdminLogoutData, AdminLogoutVars } from '../queries/auth'

const AppNavElements = () => {
  const [loggingOut, setLoggingOut] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const [adminLogout] = useMutation<AdminLogoutData, AdminLogoutVars>(ADMIN_LOGOUT_MUTATION, {
    errorPolicy: 'all',
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    setLoggingOut(true)
    await adminLogout()
    clearCredential()
    clearManagedDomain()
    window.location.href = '/auth/login/'
  }

  const goToPage = (page: string) => {
    navigate(`/app/${page}/`)
    handleClose()
  }

  return (
    <React.Fragment>
      <ManagedDomainPicker />
      <IconButton
        id='settings-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        aria-label='Configuración'
        onClick={handleClick}
        sx={{ p: 1, m: -1 }}
      >
        <Settings />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'settings-button' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              boxShadow: 12,
              overflow: 'visible',
              mt: 0.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 16,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(-135deg)',
                zIndex: 0,
              },
            },
          },
        }}
      >
        {loggingOut ? (
          <MenuItem>
            Cerrando sesión...
          </MenuItem>
        ) : (
          [
            (
              <MenuItem
                key={'uid'}
                disabled
              >
                <ListItemIcon>
                  <Engineering fontSize='small' />
                </ListItemIcon>
                {getCredential()?.uid}
              </MenuItem>
            ),
            (
              <MenuItem
                key='security'
                onClick={() => goToPage('security')}
              >
                <ListItemIcon>
                  <Security fontSize='small' />
                </ListItemIcon>
                Seguridad
              </MenuItem>
            ),
            (
              <MenuItem
                key='logout'
                onClick={handleLogout}
              >
                <ListItemIcon>
                  <Logout fontSize='small' />
                </ListItemIcon>
                Cerrar sesión
              </MenuItem>
            ),
          ]
        )}
      </Menu>
    </React.Fragment>
  )
}

export default AppNavElements
