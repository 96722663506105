import * as React from 'react'

import { ListItem, ListItemText } from '@mui/material'

import {
  RolesList,
  findCountryByCode,
  getFullAddress,
  hasOwnershipRole,
} from 'shared/services'

import UltimateBeneficialOwnerDeleteForm from './ultimate_beneficial_owner_delete_form'
import ListDisplay from '../list_display'

import type { UltimateBeneficialOwner } from 'shared/queries'

type UltimateBeneficialOwnerDisplayProps = {
  ultimateBeneficialOwner: UltimateBeneficialOwner
}

const UltimateBeneficialOwnerDisplay = ({
  ultimateBeneficialOwner,
}: UltimateBeneficialOwnerDisplayProps) => (
  <React.Fragment>
    <ListItem divider>
      <ListItemText
        primary='Nacionalidad'
        secondary={findCountryByCode(ultimateBeneficialOwner.nationality)?.name}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Residencia en Chile'
        secondary={ultimateBeneficialOwner.isChileanResident ? 'Sí' : 'No'}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Es Persona Expuesta Políticamente (PEP)'
        secondary={ultimateBeneficialOwner.isPoliticallyExposed ? 'Sí' : 'No'}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Número de identificación'
        secondary={ultimateBeneficialOwner.identificationNumber}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Dirección'
        secondary={getFullAddress(ultimateBeneficialOwner)}
      />
    </ListItem>
    <ListItem divider={hasOwnershipRole(ultimateBeneficialOwner.roles)}>
      <ListItemText
        primary='Relación con la empresa'
        secondary={<RolesList roles={ultimateBeneficialOwner.roles} />}
      />
    </ListItem>
    {hasOwnershipRole(ultimateBeneficialOwner.roles) && (
      <ListItem>
        <ListItemText
          primary='Porcentaje de participación en la empresa'
          secondary={`${ultimateBeneficialOwner.participationPercentage} %`}
        />
      </ListItem>
    )}
  </React.Fragment>
)

type UltimateBeneficialOwnersDisplayProps = {
  userId: string
  ultimateBeneficialOwners?: UltimateBeneficialOwner[]
}

const UltimateBeneficialOwnersDisplay = ({
  userId,
  ultimateBeneficialOwners,
}: UltimateBeneficialOwnersDisplayProps) => (
  ultimateBeneficialOwners?.map((ubo, index) =>
    (
      <ListDisplay
        key={index}
        title={`${ubo.givenName} ${ubo.familyName}`}
        item={ubo}
        deleteForm={(closeDialog) => (
          <UltimateBeneficialOwnerDeleteForm
            closeDialog={closeDialog}
            userId={userId}
            ultimateBeneficialOwnerId={ubo.id}
          />
        )}
      >
        {(item) => (
          <UltimateBeneficialOwnerDisplay ultimateBeneficialOwner={item} />
        )}
      </ListDisplay>
    ),
  ))

export default UltimateBeneficialOwnersDisplay
