import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Autorenew } from '@mui/icons-material'
import { Grid, Skeleton, Typography } from '@mui/material'

import { AppContainer, ButtonsContainer } from 'shared/components'

import {
  CHECK_RISK_RATING_MUTATION,
  RISK_RATINGS_QUERY,
  riskValueColor,
  riskValueLabel,
} from '../../queries/risk_ratings'
import { USER_QUERY } from '../../queries/users'
import { colorState, translateState } from '../../queries/verifications'
import UserNavigationButtons from '../user_navigation_buttons'

import type {
  CheckRiskRatingData,
  CheckRiskRatingVars,
  RiskValue,
} from '../../queries/risk_ratings'
import type { UserData, UserVars } from '../../queries/users'
import type { RouteComponentProps } from '@reach/router'

type UserStatusDisplayProps = {
  backgroundColor: string
  color: string
  loading: boolean
  children?: React.ReactNode
}

const UserStatusDisplay = ({
  backgroundColor,
  color,
  loading,
  children,
}: UserStatusDisplayProps) => (
  <AppContainer
    md={4}
    sx={{
      px: 3,
      py: 2,
      height: '100%',
      backgroundColor,
      color,
    }}
  >
    <Typography
      sx={{
        fontWeight: 600,
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
      }}
    >
      {loading ? (
        <Skeleton
          animation='wave'
          sx={{ bgcolor: 'grey.700' }}
        />
      ) : (
        children
      )}
    </Typography>
  </AppContainer>
)

type RiskRatingStatusDisplayProps = {
  globalRiskRating?: RiskValue
  loading: boolean
  userId: string
}

const RiskRatingStatusDisplay = ({
  globalRiskRating,
  loading,
  userId,
}: RiskRatingStatusDisplayProps) => {
  const [checkRiskRating, { loading: riskLoading }] =
    useMutation<CheckRiskRatingData, CheckRiskRatingVars>(CHECK_RISK_RATING_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_QUERY, variables: { userId } },
        { query: RISK_RATINGS_QUERY, variables: { userId } },
      ],
    })

  const handleRiskRating = async () => {
    await checkRiskRating({
      variables: { userId },
    })
  }

  return (
    <UserStatusDisplay
      backgroundColor={riskValueColor(globalRiskRating)}
      color='common.white'
      loading={loading || riskLoading}
    >
      Perfil de riesgo: {riskValueLabel(globalRiskRating)}
      <Autorenew
        cursor='pointer'
        onClick={handleRiskRating}
      />
    </UserStatusDisplay>
  )
}

type UserProps = RouteComponentProps & {
  '*'?: string
  userId?: string
  children?: React.ReactNode
}

const User = (props: UserProps) => {
  const userId = props.userId || ''

  const { loading, data } =
    useQuery<UserData, UserVars>(USER_QUERY, {
      variables: {
        userId,
      },
    })

  const user = data?.user
  const verificationState = user?.verification?.state
  const globalRiskRating = user?.globalRiskRating

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <UserStatusDisplay
          backgroundColor={user?.isDemoAccount ? 'primary.light' : 'primary.main'}
          color={user?.isDemoAccount ? 'common.black' : 'common.white'}
          loading={loading}
        >
          ID: {user?.id} / {user?.nickName}
          {user?.isDemoAccount && (
            <Typography
              variant='caption'
              component='span'
              fontWeight={500}
              sx={(theme) => ({
                py: 0.5,
                px: 1,
                ml: 1,
                color: 'common.white',
                bgcolor: 'common.black',
                borderRadius: theme.spacing(2),
              })}
            >
              DEMO
            </Typography>
          )}
        </UserStatusDisplay>
        <UserStatusDisplay
          backgroundColor={colorState(verificationState)}
          color='common.white'
          loading={loading}
        >
          {(user?.isConfirmed)
            ? `Verificación: ${translateState(verificationState)}`
            : 'Verificación: Email no confirmado'}
        </UserStatusDisplay>
        <RiskRatingStatusDisplay
          globalRiskRating={globalRiskRating}
          loading={loading}
          userId={userId}
        />
        <ButtonsContainer>
          <UserNavigationButtons
            path={props['*'] || ''}
            navigate={props.navigate}
            containerProps={{
              flexGrow: 1,
              justifyContent: 'center',
              overflow: 'auto',
            }}
          />
        </ButtonsContainer>
      </Grid>
      {props.children}
    </React.Fragment>
  )
}

export default User
