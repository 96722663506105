import * as React from 'react'

import { MenuItem  } from '@mui/material'
import { Field  } from 'formik'
import { Select, TextField } from 'formik-mui'
import { DesktopDateTimePicker } from 'formik-mui-x-date-pickers'

import { ValueType } from '../../../queries/dynamic_configs'

const DynamicConfigValueField = ({ valueType }: { valueType: ValueType }) => {
  switch (valueType) {
  case 'boolean': return (
    <Field
      required
      name='value'
      type='text'
      label='Valor'
      component={Select}
    >
      <MenuItem value='true'>Sí</MenuItem>
      <MenuItem value='false'>No</MenuItem>
    </Field>
  )
  case 'date': return (
    <Field
      required
      name='value'
      type='date'
      label='Valor'
      component={DesktopDateTimePicker}
      views={['year', 'month', 'day']}
      inputFormat='yyyy/MM/dd HH:mm'
      toolbarTitle='Fecha y hora (local)'
      closeOnSelect
    />
  )
  default: return (
    <Field
      required
      name='value'
      type='text'
      label='Valor'
      component={TextField}
      margin='normal'
      fullWidth
    />
  )
  }
}

export default DynamicConfigValueField
